'use strict';

import { css } from '../../node_modules/lit-element/lit-element.js';

export const RippleStyles = css`
  
  @keyframes rippleEffect {
    0% {
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(var(--scale));
    }
  }

  .ripple {
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    opacity: 0.20;
    animation: rippleEffect 0.5s ease-in-out;
  }

  button[ripple] {
    overflow: hidden;
    position: relative;
    font-size: 14px;
    width: 100%;
    outline: 0;
    cursor: pointer;
  }

  #overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    transition: opacity 300ms, visibility 300ms;
  }
`;

export const RippleEffect = e => {
  const targetPosition = e.currentTarget.getBoundingClientRect();
  const x = e.pageX - targetPosition.left,
        y = e.pageY - targetPosition.top,
        w = e.currentTarget.offsetWidth;
  
  const color = window.getComputedStyle(e.currentTarget).color;
  const ripple = document.createElement('span');
  
  ripple.style.backgroundColor = color;
  ripple.className = 'ripple';
  ripple.style.left = x + 'px';
  ripple.style.top  = y + 'px';
  ripple.style.setProperty('--scale', w);

  e.currentTarget.appendChild(ripple);

  setTimeout(function() {
    ripple.parentNode.removeChild(ripple);
  }, 500);
}
