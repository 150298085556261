'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';

class NewInterventionCalendar extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
      }

      header {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
      }

      #calendar {
        width: 100%;
      }

      h1 {
        display: inline-block;
        font-size: 1.4em;
        font-weight: 300;
        text-transform: uppercase;
      }

      #calendar .date {
        text-align: center;
        margin: 3px;
        padding: 2px;
        color: #3a6fdf;
        width: 20px;
        border-radius: 50%;
        float: left;
        flex-basis: 20%;
        line-height: initial;
      }

      .date-content {
        flex-basis: 80%;
        margin: 2px;
        padding: 2px;
        margin-left: 0;
        padding-left: 0;
        line-height: initial;
      }

      .previous {
        background-color: #f5f5f5 !important;
        color: #666;
      }

      .now > .date {
        background: #8e352e;
        color: white !important;
      }

      #calendar-wrap {
        width: 100%;       
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
      }

      .iron-selected {
        color: white !important;
        background-color: #2b78e4 !important;
      }

      .iron-selected > .date {
        color: white !important;
      }

      #weekdays, #days {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      #days > .calendar-item {
        min-height: 40px;
        box-shadow: inset 1px 1px 0 #ccc;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        transition: background-color 0.3s ease;
      }

      #days > .calendar-item:nth-child(6n+1){
        box-shadow: inset 0 1px 0 #ccc;
      }

      #weekdays > .calendar-item {
        /*box-shadow: inset 1px 1px 0 #888, 1px 1px 0 #888;*/
        height: 30px;
        text-align: center;
        background-color: #888;
        text-transform: uppercase;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }

      .calendar-item {
        margin: 0;
        padding: 0;
        border-radius: 0;
        min-width: unset;
      }

      .previous > .date {
        color: #999 !important;
      }

      .calendar-nav {
        color: #2b78e4;
        margin: 0;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0.7em 0.57em;
      }

      .hidden {
        display: none: !important;
      }

      .day-item {
        position: relative;
        cursor: pointer;
      };
    `;
  }

  render() {
    return html`
      <div id="calendar-wrap">
        <header>
          <div @click="${this._previousMonth}" class="calendar-nav">précédent</div>
          <h1>${this._getMonthName(this.month)}</h1>
          <div @click="${this._nextMonth}" class="calendar-nav">suivant</div>
        </header>
        <div id="calendar">
          
          <div id="weekdays">
              <div class="calendar-item">Dimanche</div>
              <div class="calendar-item">Lundi</div>
              <div class="calendar-item">Mardi</div>
              <div class="calendar-item">Mercredi</div>
              <div class="calendar-item">Jeudi</div>
              <div class="calendar-item">Vendredi</div>
              ${this.showWeekEnd ? html`<div class="calendar-item">Samedi</div>` : ''}
          </div>

          <div id="days">
            ${this.calendar.filter(this._isWorkDay.bind(this)).map(day => html`
              <div
                class="${this._getDayClass(day)} day-item"
                ?disabled="${day.previous}"
                date="${this._dayKey(day.date)}"
                @click="${this._selectDay}"
              >
                <div class="date">${this._day(day.date)}</div>
                <div class="date-content">${this._dateText(day)}</div>
              </div>
            `)}
          </div>
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      calendar: { type: Array },
      month: { type: Date },
      date: { type: Date },
      tech: { type: Object },
      showWeekEnd: { type: Boolean }
    }
  }

  constructor() {
    super();
    this.calendar = [];
    this.month = new Date();
    this.showWeekEnd = false;
  }

  _selectDay(e) {
    this.date = e.target.getAttribute('date') || e.composedPath()[1].getAttribute('date');
    this.dispatchEvent(new CustomEvent('date-changed', {bubbles: true, composed: true, detail: this.date}));
  }

  _getMonthName(m) {
    var d = new Date(m);

    var s = d.toLocaleString('fr-FR', { 
      month: 'long'
    });

    return s.charAt(0).toUpperCase() + s.slice(1) + ' ' + d.getFullYear();
  }

  _day(date) {
    return new Date(date).getDate();
  }

  _isDisabled(day) {
    return day.previous;
  }

  _getDayClass(day) {
    var c = 'calendar-item';
    if(day.now) c += ' now';
    if(day.previous) c += ' previous';
    if(this.date && new Date(this.date).toString().substr(0, 15) === new Date(day.date).toString().substr(0, 15)) {
      c += ' iron-selected';
    }
    return c;
  }

  _previousMonth() {
    this.dispatchEvent(new CustomEvent('previous-month', {bubbles: true, composed: true}));
  }

  _nextMonth() {
    this.dispatchEvent(new CustomEvent('next-month', {bubbles: true, composed: true}));
  }

  _dayKey(d) {
    return new Date(d).toISOString();
  }

  _dateText(day) {
    if(!this.tech || !day.interventions || !day.interventions.length) return '';
    let regions = [...new Set(day.interventions.map(i => i._client.address.region))];
    return `${day.interventions.length} RDV\n${regions.sort().join(', ')}`;
  }

  _isWorkDay(day) {
    return (this.showWeekEnd || ![6].includes(day.date.getDay()));
  }
}

customElements.define('new-intervention-calendar', NewInterventionCalendar);
