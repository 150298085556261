'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import '../../../node_modules/@polymer/paper-checkbox/paper-checkbox.js';
import '../../../node_modules/@polymer/paper-input/paper-input.js';
import './remove-equipment.js';
import './technician-observations.js';
import {
  renderDefaultHardware,
  renderIndoorUnit,
  renderOutGroup
} from './default-hardware.js';
import { SharedStyles } from '../../shared-styles.js';

class AirAirPart extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
          background-color: #f3f3f3;
          overflow-y: hidden;
        }

        :host > *:not(style) {
          display: block;
          margin: auto;
          width: 95%;
          margin-bottom: 30px;
        }

        .title {
          font-size: 45px;
          font-weight: 400;
          letter-spacing: -.018em;
          line-height: 48px;
        }

        #header-container {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-content: flex-start;
          align-items: stretch;
        }

        #temp {
          width: 33.33%;
          margin-top: 40px;
        }

        paper-input {
          display: inline-block;
        }
      `
    ]
  }

  renderAddEquipmentButton(type, name) {
    return html`
      <paper-button
        class="add-equipment"
        hardwareType="${type}"
        @click="${this.fireAddEquipment}"
      >+ ${name}</paper-button>
    `;
  }

  renderCheckbox(name, prop) {
    return html`
      <paper-checkbox ?checked="${this.hardware.checks[prop]}" .prop="${prop}" @change="${this._updateCheckValue}">
        ${name}
      </paper-checkbox><br>
    `
  }

  renderChecksAirAir() {
    return html`
      <div class="paper-card">
        <div class="card-header">Vérifications et maintenance</div>
        <div class="content">
          ${this.renderCheckbox('Connexions électriques', 'electirConn', this._electirConnChanged)}
          ${this.renderCheckbox('Contrôle d\'étanchéité du circuit frigorifique', 'tightnessContr', this._tightnessContrChanged)}
          ${this.renderCheckbox('Vérification des pompes de relevage', 'liftPump', this._liftPumpChanged)}
          ${this.renderCheckbox('Nettoyage des filtres', 'cleanFilters', this._cleanFiltersChanged)}
          ${this.renderCheckbox('Remplacement des filtres et des piles (optionnel)', 'filterReplace', this._filterReplaceChanged)}
          <span>Recharge de gaz (optionnel)</span>
          
          <paper-input
            label="Type"
            auto-validate
            type="text"
            .value="${this.hardware.checks.gazReloadType}"
            @value-changed="${this._gazReloadTypeChanged}"
          ></paper-input>

          <paper-input
            label="Quantité"
            auto-validate
            type="number"
            .value="${this.hardware.checks.gazReloadQuantity}"
            @value-changed="${this._gazReloadQuantityChanged}"
          >
            <div suffix slot="suffix">Kgs</div>
          </paper-input>
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div id="header-container">
        <div class="title">Air/Air</div>
        <remove-equipment @remove="${this.fireRemovePart}"></remove-equipment>
      </div>

      ${(this.hardware.outGroups || []).map((_, index) =>
        renderOutGroup(this, index)
      )}

      ${this.renderAddEquipmentButton('out-group', 'Groupe extérieur')}
      
      ${(this.hardware.indUnits || []).map((_, index) =>
        renderIndoorUnit(this, index)
      )}

      ${this.renderAddEquipmentButton('ind-unit', 'Unité intérieure')}
      
      ${(this.hardware.hotWaterTanks || []).map((_, index) =>
        renderDefaultHardware(this, 'hotWaterTanks', index, 'Ballon ECS')
      )}
      ${this.renderAddEquipmentButton('hot-water-tank', 'Ballon ECS')}

      ${this.renderChecksAirAir()}

      <technician-observations
        showNotes
        .notes="${this.hardware.observations.notes}"
        .photos="${this.hardware.observations.photos}"
        @notes-changed="${this._notesChanged}"
        @photos-changed="${this._photosChanged}"
      ></technician-observations>
    `;
  }

  static get properties() {
    return {
      hardware: { type: Object }
    }
  }

  constructor() {
    super();
    this.hardware = {};
  }

  connectedCallback() {
    super.connectedCallback();
    this.hardware = {
      outGroups: [this.getEmpty('outGroups')],
      indUnits: [this.getEmpty()],
      hotWaterTanks: [this.getEmpty()],
      checks: {},
      observations: {
        photos: [],
        notes: null
      }
    };
  }

  updated(props) {
    console.log(props);

    if(props.has('hardware')) {
      console.log(this.hardware);
      this.dispatchEvent(new CustomEvent('hardware-changed', {
        bubbles: true, composed: true, detail: this.hardware
      }))
    }
  }

  fireRemove(e) {
    const type = e.currentTarget.getAttribute('hardware');
    const index = e.currentTarget.getAttribute('index');
    this.hardware[type].splice(index, 1);
    this.hardware = {...this.hardware};
  }

  fireRemovePart() {
    this.dispatchEvent(new CustomEvent('remove-part', {
      bubbles: true, composed: true
    }));
  }

  fireAddEquipment(e) {
    var name = this.resolveName(e.currentTarget.getAttribute('hardwareType'));
    Object.defineProperty(this.hardware, name, { 
      value: this.hardware[name].concat([this.getEmpty(name)]), configurable: true
    });
    this.hardware = {...this.hardware};
  }

  resolveName(name) {
    name = name.toLowerCase();
    switch(name){
      case 'out-group':
        return 'outGroups';

      case 'ind-unit':
        return 'indUnits';

      case 'indoor-unit':
        return 'indUnits';

      case 'hot-water-tank':
        return 'hotWaterTanks';
    }
  }

  getEmpty(name) {
    switch(name){
      case 'outGroups':
        return {
          type: 'monophase',
          measures: {},
          coldModeGaz: '',
          hotModeGaz: '',
          brand: '',
          model: '',
          serialNumber: ''
        };

      default:
        return {};
    }
  }

  _updateInputValue(e) {
    const prop = e.currentTarget.prop || e.currentTarget.getAttribute('prop');
    const closest = e.currentTarget.closest('.content');
    const part = closest.hardware;
    const index = parseInt(closest.index);
    const subProperty = e.currentTarget.subProperty || e.currentTarget.getAttribute('subProperty');

    if(subProperty) {
      this.hardware[part][index][subProperty][prop] = e.detail.value;
    } else {
      this.hardware[part][index][prop] = e.detail.value;
    }

    this.hardware = {...this.hardware};
  }

  _updateCheckValue(e) {
    this.hardware.checks[e.currentTarget.prop] = e.currentTarget.checked;
    this.hardware = {...this.hardware};
  }

  _notesChanged(e) {
    this.hardware.observations.notes = e.detail;
    this.hardware = {...this.hardware}
  }
  _photosChanged(e) {
    this.hardware.observations.photos = e.detail;
    this.hardware = {...this.hardware}
  }
  _gazReloadTypeChanged(e) {
    this.hardware.checks[e.currentTarget.prop] = e.currentTarget.value;
    this.hardware = {...this.hardware};
  }
  _gazReloadQuantityChanged(e) {
    this.hardware.checks[e.currentTarget.prop] = parseFloat(e.currentTarget.value);
    this.hardware = {...this.hardware};
  }
}

customElements.define('air-air-part', AirAirPart);
