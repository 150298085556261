'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import '../../../node_modules/@polymer/paper-input/paper-textarea.js';
import './client-observations.js';
import './signature-pads.js';
import './price-detail.js';
import './sav-price.js';
import { SharedStyles } from '../../shared-styles.js';

class SavFormBottom extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
          width: 100%;
          overflow-y: scroll;
          background-color: #f3f3f3;
        }

        #container {
          width: 95%;
          margin: auto;
          padding-top: 30px;
        }

        #container > * {
          display: block;
          margin-bottom: 30px;
        }

        paper-button {
          width: 100%;
          text-transform: uppercase;
          background-color: #3A6FDF;
          color: white;
          border-radius: 0;
          font-weight: 700;
          font-size: 26px;
          padding: 20px 0;
          margin: 0;
          margin-bottom: 30px;
          display: flex;
          align-items: center;
        }

        paper-textarea {
          --paper-input-container-label-floating: {
            font-size: 26px;
          }
        }

        #works {
          padding-bottom: 0;
        }
      `
    ];
  }

  render() {
    return html`
      <div id="container">
        
        <div class="paper-card" id="works">
          <div class="card-header">Observations du technicien</div>
          <div class="content">
            <paper-textarea
              id="notes"
              label="Notes"
              always-float-label
              rows="5"
              .value="${this.observations.techNotes}"
              @value-changed="${this._techNotesChanged}"
            ></paper-textarea>
          </div>
        </div>

        ${this.priceDetail ? html`
          <price-detail
            .airAir="${this.airAir}"
            .airWater="${this.airWater}"
            .waterTank="${this.waterTank}"
            .price="${this.price}"
            @price-changed="${this._priceChanged}"
          ></price-detail>
        ` : ''}

        ${this.showPrice ? html`
          <sav-price
            ?disabled="${!this.customPrice}"
            .clientType="${this.clientType}"
            .price="${this.price}"
            @price-changed="${this._priceChanged}"
          ></sav-price>
        ` : ''}

        <client-observations
          .grades="${this.observations.grades}"
          .notes="${this.observations.clientNotes}"
          @grades-changed="${this._gradesChanged}"
          @notes-changed="${this._notesChanged}"
        ></client-observations>


        <signature-pads
          .client="${this.signatures.client}"
          .technician="${this.signatures.technician}"
          @client-signature-changed="${this._clientSignatureChanged}"
          @tech-signature-changed="${this._technicianSignatureChanged}"
        ></signature-pads>

        <ul style="color:red">
          ${this.errors.map(item => html` <li>${item}</li>`)}
        </ul>

        <div id="finish">
          <paper-button raised @click="${this.fireFinish}" ?disabled="${!!this.errors.length}">
            Finir l'intervention
          </paper-button>
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      airAir: { type: Array },
      airWater: { type: Array },
      waterTank: { type: Array },
      customPrice: { type: Boolean },
      showPrice: { type: Boolean },
      priceDetail: { type: Boolean },
      observations: { type: Object },
      signatures: { type: Object },
      price: { type: Number },
      errors: { type: Array },
      disabled: { type: Boolean }
    }
  }

  constructor() {
    super();
    this.airAir = [];
    this.airWater = [];
    this.waterTank = [];
    this.observations = {};
    this.signatures = {};
  }

  fireFinish() {
    this.dispatchEvent(new CustomEvent('finished-intervention', {bubbles: false, composed: false}));
  }

  _gradesChanged(e) {
    this.observations = {...this.observations, grades: e.detail};
    this.dispatchEvent(new CustomEvent('observations-changed', {
      bubbles: true, composed: true, detail: this.observations
    }))
  }
  _notesChanged(e) {
    this.observations = {...this.observations, notes: e.detail};
    this.dispatchEvent(new CustomEvent('observations-changed', {
      bubbles: true, composed: true, detail: this.observations
    }))
  }
  _techNotesChanged(e) {
    this.observations = {...this.observations, techNotes: e.detail.value};
    this.dispatchEvent(new CustomEvent('observations-changed', {
      bubbles: true, composed: true, detail: this.observations
    }))
  }
  _priceChanged(e) { 
    this.price = e.detail;
    this.dispatchEvent(new CustomEvent('price-changed', {
      bubbles: true, composed: true, detail: this.price
    }))
  }
  _clientSignatureChanged(e) {
    this.signatures = {...this.signatures, client: e.detail}
    this.dispatchEvent(new CustomEvent('signatures-changed', {
      bubbles: true, composed: true, detail: this.signatures
    }))
  }
  _technicianSignatureChanged(e) {
    this.signatures = {...this.signatures, technician: e.detail}
    this.dispatchEvent(new CustomEvent('signatures-changed', {
      bubbles: true, composed: true, detail: this.signatures
    }))
  }
}

customElements.define('sav-form-bottom', SavFormBottom);
