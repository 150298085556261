'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import {SharedStyles } from '../../shared-styles.js';
import './signature-pad-element.js';

class SignaturePads extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
        }
        
        .card-subtitle {
          display: block;
          font-weight: bold;
          font-size: 1.5rem;
        }

        signature-pad-element {
          display: block;
        }

        .content {
          padding: 1.5rem;
          display: flex;
          flex-direction: row;
          flex-flow: row wrap;
          justify-content: space-between;
        }

        .signature {
          width: 45%;
        }

        @media only screen 
        and (min-device-width : 320px) 
        and (max-device-width : 1000px) {
          .signature {
            flex-basis: 100%;
            font-size: 1.2rem;
          }

          .signature:not(:last-child) {
            padding-bottom: 3rem;
          }

          .line-break {
            width: 100%;
          }
        }

        /* Smartphones (portrait and landscape) ----------- */
        @media only screen 
        and (min-device-width : 1000px) {
          .signature {
            flex-basis: 49%;
            padding-bottom: 3rem;
            font-size: 1.2rem;
          }

          .line-break {
            display: none;
          }
        }
      `
    ];
  }
  
  render() {
    return html`
      <div class="paper-card">
        <div class="card-header">Signatures</div>
        <div class="content">
          <div class="signature">
            <span class="card-subtitle">Technicien</span>
            <signature-pad-element
              .signature="${this.technician}"
              @signature-changed="${this._techSignatureChanged}"
            ></signature-pad-element>
          </div>

          <div class="line-break"></div>

          <div class="signature">
            <span class="card-subtitle">Client</span>
            <signature-pad-element
              .signature="${this.client}"
              @signature-changed="${this._clientSignatureChanged}"
            ></signature-pad-element>
          </div>
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      client: { type: String },
      technician: { type: String }
    }
  }

  constructor() {
    super();
  }

  _techSignatureChanged(e) {
    this.technician = e.detail;
    this.dispatchEvent(new CustomEvent('tech-signature-changed', {
      bubbles: true, composed: true, detail: this.technician
    }))
  }
  _clientSignatureChanged(e) {
    this.client = e.detail;
    this.dispatchEvent(new CustomEvent('client-signature-changed', {
      bubbles: true, composed: true, detail: this.client
    }))
  }
}

customElements.define('signature-pads', SignaturePads);
