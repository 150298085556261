'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import './grade-stars.js';
import { SharedStyles } from '../../shared-styles.js';
import { formatTime, interventionTypeName } from '../../utils/utils.js';

class UserTable extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
        }

        .name {
          padding: 3px 20px;
          margin: 3px;
          border-radius: 4px;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
        }

        paper-button {
          margin: 0;
          padding: 0;
          color: #2b78e4;
        }

        small-label {
          margin-left: 5px;
        }

        #container {
          display: grid;
          grid-auto-rows: auto;
          border: 1px solid #ccc;
          border-radius: 5px;
        }

        header, article {
          display: grid;
          grid-template-columns: repeat( auto-fit, minmax(10px, 1fr) );
          padding: 9px;
        }

        header {
          color: #666;
          text-transform: uppercase;
          font-weight: 300;
          font-size: 14px;
        }

        article {
          border-top: 1px solid #ccc;
          position: relative;
        }

        article:nth-of-type(even) {
          background-color: #fafafa;
        }

        .new-line {
          display: none;
        }
    
        @media only screen and (min-device-width : 600px) {
          paper-button {
            border: 1px solid #2b78e4;
          }
        }

        @media only screen and (max-device-width : 600px) {
          header {
            display: none;
          }

          #container {
            border: 0;
            grid-gap: 20px;
          }

          article {
            display: block;
            padding: 0;
            border: 1px solid #ccc;
            border-radius: 5px;
          }

          article > div {
            padding-left: 10px;
          }

          article > div:first-of-type {
            padding-top: 10px;
          }

          .new-line {
            display: block;
          }

          .client-name {
            font-size: 1.5em;
            font-weight: bold;
          }

          small-label {
            margin: 0;
            line-height: inherit;
          }

          paper-button {
            margin-top: 10px;
            border-top: 1px solid #ccc;
            width: 100%;
            padding: 10px;
          }

          #interventions {
            display: grid;
            grid-gap: 20px;
          }
        }
      `
    ]
  }

  render() {
    return html`
      <div id="container">

        <header>
          <span>Client</span>
          <span>Date</span>
          <span>Type</span>
          <span>Adresse</span>
          <span>
            ${this.user.type === 'tech' ? html`Ajouté par` : ''}
            ${this.user.type === 'sales' ? html`Technicien` : ''}
          </span>

          ${this.interventions[0].finished ? html`
            <span>Montant HT</span>
            ${this.user.type === 'tech' ? html`<span>Note</span>` : ''}
          ` : ''}

          <span>Intervention</span>
        </header>

        <div id="interventions">
          ${this.interventions.map(item => html`
            <article>
              <div class="client-name">
                ${item._client.name}<br>
                <span class="small-label">${this._clientType(item._client.type)}</span>
              </div>

              <div>${this._formatDate(item.date)}</div>

              <div><span class="small-label">${interventionTypeName(item.type)}</span></div>

              <div>
                ${item._client.address.street}<br>
                ${item._client.address.postalCode} ${item._client.address.city}
              </div>

              <div>
                <span class="name" class="${this._userClass(this.users, item)}">
                  ${this._getUserName(this.users, item)}
                </span>
              </div>

              ${item.finished ? html`
                <div>
                  ${item.price ? html`${item.price} €` : ''}
                </div>

                ${this.user.type === 'tech' ? html`
                  <div><grade-stars grade="${this._getMark(item.observations)}"></grade-stars></div>
                ` : ''}
              ` : ''}
              
              <paper-button @click="${this._fireSomething}" intervention-id="${item._id}">
                ${item.finished ? 'rapport' : 'modifier'}
              </paper-button>
        
            </article>
          `)}
        </div>

      </div>
    `
  }

  static get properties() {
    return {
      interventions: { type: Array },
      user: { type: Object },
      users: { type: Array },
      _isSales: { type: Boolean },
      _isTech: { type: Boolean }
    }
  }

  constructor() {
    super();
  }

  _getUserName(users, intervention) {
    for (var i = 0; i < users.length; i++)
      if(users[i]._id === intervention[this._isSales ? '_tech' : '_sales']) 
        return users[i].name;
  }

  _userClass(users, intervention) {
    for (var i = 0; i < users.length; i++)
      if(users[i]._id === intervention[this._isSales ? '_tech' : '_sales']) 
        return 'name ' + users[i].color;
  }

  _formatDate(date) {
    if(!date) return;

    date = new Date(date);

    const day = date.toLocaleString('fr-FR', { 
      weekday: 'long', 
      day: 'numeric', 
    });

    const hour = formatTime(date);

    return day.charAt(0).toUpperCase() + day.slice(1) + ' à ' + hour;
  }

  _getMark(obs) {
    if(!obs || !obs.grades) return;

    var total = 0;
    var filled = 0;
    
    for(var mark in obs.grades){
      if(typeof obs.grades[mark] !== 'number') continue;

      total += obs.grades[mark];
      filled++;
    }

    return total / filled;
  }

  _fireSomething(e) {
    const item = this.interventions.filter(i => i._id === e.target.getAttribute('intervention-id'))[0];

    if(item.finished) {
      this.dispatchEvent(new CustomEvent('show-report', {bubbles: true, composed: true, 
        detail: { id: item._id }
      }));
    } else {
      this.dispatchEvent(new CustomEvent('edit-intervention', {bubbles: true, composed: true, 
        detail: item
      }));
    }
  }

  _clientType(type) {
    switch(type){
      case 'individual':
        return 'Particulier';
      case 'company':
        return 'Société';
      case 'sci':
        return 'SCI';
    }
  }
}

customElements.define('user-table', UserTable);
