'use strict';

import { html } from '../../node_modules/@polymer/polymer/lib/utils/html-tag.js';

const $_vaadinComboBoxStylesDocumentContainer = html`
<dom-module id="material-combo-box" theme-for="vaadin-combo-box">
  <template>
    <style include="material-field-button">
      :host {
        display: inline-flex;
        outline: none;
        -webkit-tap-highlight-color: transparent;
      }
    </style>
  </template>
</dom-module>

<dom-module id="material-item" theme-for="vaadin-item">
  <template>
    <style>
      :host {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        overflow: hidden;
        font-size: 1rem;
        line-height: 24px;
        padding: 4px 0;
      }

      /* It's the list-box's responsibility to add the focus style */
      :host([focused]) {
        outline: none;
      }

      /* Selected item has an icon */

      :host::before {
        display: none;
        content: "";
        font-size: 24px;
        line-height: 1;
        font-weight: 400;
        width: 24px;
        text-align: center;
        margin-right: 10px;
        color: #737373;
        flex: none;
      }

      :host([selected])::before {
        content: "\\2023";
      }

      /* Disabled item */

      :host([disabled]) {
        color: #9b9b9b;
        cursor: default;
        pointer-events: none;
      }
    </style>
  </template>
</dom-module>

<dom-module id="material-vaadin-overlay" theme-for="vaadin-overlay">
  <template>
    <style include="material-overlay">
      /* stylelint-disable no-empty-source */
    </style>
  </template>
</dom-module>

<dom-module id="material-combo-box-overlay" theme-for="vaadin-combo-box-overlay">
  <template>
    <style include="material-menu-overlay">
      :host {
        /* TODO using a legacy mixin (unsupported) */
        --iron-list-items-container: {
          border-width: 8px 0;
          border-style: solid;
          border-color: transparent;
        };
      }

      [part="overlay"] {
        position: relative;
        overflow: visible;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      [part="content"] {
        padding: 0;
      }
    </style>
  </template>
</dom-module>

<dom-module id="material-overlay">
  <template>
    <style>
      :host {
        top: 16px;
        right: 16px;
        bottom: calc(1px * var(--vaadin-overlay-viewport-bottom) + 16px);
        left: 16px;
      }

      [part="overlay"] {
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
        color: #212121;
        font-size: 1rem;
        font-weight: 400;
      }

      [part="content"] {
        padding: 8px 0;
      }

      [part="backdrop"] {
        opacity: 0.2;
        animation: 0.2s vaadin-overlay-backdrop-enter;
        will-change: opacity;
      }

      @keyframes vaadin-overlay-backdrop-enter {
        0% {
          opacity: 0;
        }
      }
    </style>
  </template>
</dom-module>

<dom-module id="material-combo-box-item" theme-for="vaadin-combo-box-item">
  <template>
    <style include="material-item">
      :host {
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        padding: 4px 10px;
        min-height: 36px;
        font-size: 0.875rem;
        --_material-item-selected-icon-display: block;
      }

      /* ShadyCSS workaround */
      :host::before {
        display: block;
      }

      :host(:hover) {
        background-color: #f5f5f5;
      }

      :host([focused]) {
        background-color: rgba(0, 0, 0, 0.12);
      }

      @media (pointer: coarse) {
        :host(:hover),
        :host([focused]) {
          background-color: transparent;
        }
      }
    </style>
  </template>
</dom-module>
`;

document.head.appendChild($_vaadinComboBoxStylesDocumentContainer.content);
