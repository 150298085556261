'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import './vaadin-combo-box-styles.js';
import '../../node_modules/@vaadin/vaadin-combo-box/src/vaadin-combo-box-light.js';
import '../../node_modules/@polymer/paper-input/paper-input.js';
import '../../node_modules/@polymer/paper-button/paper-button.js';
import { ALCIcons } from './alc-icons.js';
import { SharedStyles } from '../shared-styles.js';

class ClientInfo extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
        }

        paper-input {
          display: block;
        }

        vaadin-combo-box-light {
          --vaadin-combo-box-overlay-max-height: 150px;
        }

        #update {
          color: #4D79E2;
        }

        #container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        vaadin-combo-box-light {
          flex-grow: 4;
        }

        #container > paper-button {
          margin-left: 15px;
          background-color: #3A6FDF;
          color: white;
          margin-right: 0;
          /* same height as the combo box */
          height: 30px;
        }

        #current-client {
          margin-top: 1em;
          margin-bottom: 1em;
        }

        paper-button#update {
          display: block;
          width: 100%;
          border-top: 1px solid #eee;
          margin: 0;
        }

        #info {
          padding: 0.7em 0.57em;
        }

        h3 {
          margin: 0;
        }

        .phone {
          color: #666;
        }

        h2 {
          padding-top: 24px !important;
          margin-top: 0 !important;
        }

        #current-client {
          width: 100%;
        }
      `
    ];
  }
  
  render() {
    return html`
      <div id="container">
        <vaadin-combo-box-light
          label="Recherche client"
          item-label-path="name"
          item-value-path="_id"
          .items="${this.clients}"
          .value="${this.clientId}"
          @value-changed="${this._updateClient}"
          @vaadin-dropdown-closed="${this._preventDefault}"
        >
          <paper-input label="Nom" class="input" no-label-float></paper-input>
        </vaadin-combo-box-light>

        <paper-button @click="${this._newClient}">
          ${ALCIcons['add']}
        </paper-button>
      </div>
      
      ${this.clientId && this.client ? html`
        <div class="paper-card" id="current-client">
          <div id="info">
            <h3>${this.client.name}</h3>
            <span class="small-label">${this._clientType(this.client.type)}</span><br>
            <span class="phone">${this.client.phone}</span>&nbsp;&nbsp;
            <span class="phone">${this.client.phone2}</span>&nbsp;&nbsp;
            <span class="phone">${this.client.phone3}</span><br>
            ${this.client.mail ? html`${this.client.mail} <br>` : ''}
            ${this.client.address ? html`
              ${this.client.address.street}<br>
              ${this.client.address.postalCode} ${this.client.address.city}
            ` : ''}
          </div>
          <paper-button id="update" .client="${this.clientId}" @click="${this._modifyClient}">
            modifier
          </paper-button>
        </div>
      ` : ''}
    `;
  }

  static get properties() {
    return {
      clientId: { type: String },
      client: { type: Object },
      clients: { type: Array },
    }
  }

  constructor() {
    super();
    this.client = {
      name: null,
      phone: null,
      address: {
        street: null,
        postalCode: null,
        city: null,
        lat: null,
        long: null
      }
    };
    this.clients = [];
  }

  updated(props) {
    if(props.has('clients') && this.clientId) {
      this.client = this.clients.find(client => client._id === this.clientId) || {};
      this.clientId = this.client._id;
    }
  }

  _preventDefault(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  updateClient(id) {
    if(!this.clients || !id) return;

    this.clientId = id;
    const client = this.clients.find(client => client._id === id);

    if(client) {
      this.client = client;
    }

    this.dispatchEvent(new CustomEvent('client-changed', {bubbles: true, composed: true, 
      detail: {client: this.client, clientId: this.clientId}
    }));
  }

  _updateClient(e) {
    if(!this.clients || !e.detail || !e.detail.value) return;
    
    this.clientId = e.detail.value.toString();
    this.client = this.clients.find(c => this.clientId === c._id);

    this.dispatchEvent(new CustomEvent('client-changed', {bubbles: true, composed: true, 
      detail: {client: this.client, clientId: this.clientId}
    }));
  }

  _newClient() {
    this.dispatchEvent(new CustomEvent('show-client-edit', {bubbles: true, composed: true}));
  }

  _modifyClient(e) {
    this.dispatchEvent(new CustomEvent('show-client-edit', {bubbles: true, composed: true, 
      detail: {client: this.client, clientId: this.clientId}
    }));
  }

  _clientType(type) {
    switch(type){
      case 'individual':
        return 'Particulier';
      case 'company':
        return 'Société';
      case 'sci':
        return 'SCI';
    }
  }
}

customElements.define('client-info', ClientInfo);
