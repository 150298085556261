'use strict';

export default function(intervention) {
  let errors = [];
  if(!intervention._client)
    errors.push('Erreur interne: identifiant client absent');

  if(!intervention._tech)
    errors.push('Erreur interne: identifiant technicien absent');

  if(!intervention.date)
    errors.push('Erreur interne: date absente');

  if(!intervention.signatures || !intervention.signatures.client)
    errors.push('Le client n\'a pas signé l\'intervention');

  if(!intervention.signatures || !intervention.signatures.technician)
    errors.push('Le technicien n\'a pas signé l\'intervention');

  if(intervention.airAir && Array.isArray(intervention.airAir))
    for(let airAir of intervention.airAir)
      errors = errors.concat(validateAirAir(airAir));
  
  if(intervention.airWater && Array.isArray(intervention.airWater))
    for(let airWater of intervention.airWater)
      errors = errors.concat(validateAirWater(airWater));
  
  if(intervention.waterTank && Array.isArray(intervention.waterTank))
    for(let waterTank of intervention.waterTank)
      errors = errors.concat(validateWaterTank(waterTank));

  return errors;
}

const validateWaterTank = waterTank => {
  let errors = [];

  if(waterTank.checks && !waterTank.checks.electirConn)
    errors.push('La vérification de connexions électriques n\'a pas été effectuée');

  if(waterTank.checks && !waterTank.checks.tightnessContr)
    errors.push('Le contrôle d\'étanchéité du circuit frigorique n\'a pas été effectué');

  if(waterTank.outGroups && Array.isArray(waterTank.outGroups))
    for(let outGroup of waterTank.outGroups)
      errors = errors.concat(validateOutGroup(outGroup));

  if(waterTank.tanks && Array.isArray(waterTank.tanks))
    for(let tank of waterTank.tanks)
      errors = errors.concat(validateTank(tank));

  return errors;
}

const validateAirWater = airWater => {
  let errors = [];

  if(airWater.checks && !airWater.checks.electirConn)
    errors.push('Le vérification des connexions électriques n\'a pas été effectué')
  if(airWater.checks && !airWater.checks.tightnessContr)
    errors.push('Le contrôle d\'étanchéité du circuit frigorique n\'a pas été effectué')
  if(airWater.checks && !airWater.checks.circCheck)
    errors.push('Le vérification du circulateur n\'a pas été effectuée')

  
  if(airWater.hydrolicModules && Array.isArray(airWater.hydrolicModules))
    for(let hydrolicModule of airWater.hydrolicModules)
      errors = errors.concat(validateHydrolicModule(hydrolicModule));
  
  if(airWater.outGroups && Array.isArray(airWater.outGroups))
    for(let outGroup of airWater.outGroups)
      errors = errors.concat(validateOutGroup(outGroup));

  if(airWater.hotWaterTanks && Array.isArray(airWater.hotWaterTanks))
    for(let hotWaterTank of airWater.hotWaterTanks)
      errors = errors.concat(validateDefaultPart(hotWaterTank, 'le ballon ECS'));

  return errors;
}

const validateAirAir = airAir => {
  let errors = [];

  if(airAir.checks && !airAir.checks.electirConn)
    errors.push('La vérification des connexions électriques n\'a pas été effectuée');
  if(airAir.checks && !airAir.checks.tightnessContr)
    errors.push('Le contrôle d\'étanchéité du circuit frigorique n\'a pas été effectué');
  if(airAir.checks && !airAir.checks.liftPump)
    errors.push('Le vérification des pompes de relevage n\'a pas été effectuée');
  if(airAir.checks && !airAir.checks.cleanFilters)
    errors.push('Le nettoyage des filtres n\'a pas été effectué');

  if(airAir.outGroups && Array.isArray(airAir.outGroups))
    for(let outGroup of airAir.outGroups)
      errors = errors.concat(validateOutGroup(outGroup));

  if(airAir.indUnits && Array.isArray(airAir.indUnits))
    for(let indUnit of airAir.indUnits)
      errors = errors.concat(validateIndUnit(indUnit));

  if(airAir.hotWaterTanks && Array.isArray(airAir.hotWaterTanks))
    for(let hotWaterTank of airAir.hotWaterTanks)
      errors = errors.concat(validateDefaultPart(hotWaterTank, 'le ballon ECS'));

  return errors;
}

const validateTank = tank => {
  let errors = [];

  errors = errors.concat(validateDefaultPart(tank, 'la cuve'));

  if(!tank.ecsTemp)
    errors.push('La température ECS n\'est pas remplie sur la cuve');

  return errors;
}

const validateOutGroup = outGroup => {
  let errors = [];

  if(!outGroup.type)
    errors.push('Erreur interne: type de groupe externe absent');

  if(!outGroup.brand)
    errors.push('La marque n\'est pas remplie sur le groupe extérieur')

  if(!outGroup.model)
    errors.push('La modèle n\'est pas remplie sur le groupe extérieur')

  if(!outGroup.serialNumber)
    errors.push('La numéro de série n\'est pas remplie sur le groupe extérieur')

  if(outGroup.type === 'monophase' && !outGroup.measures.ln)
    errors.push('La mesure L-N est absente sur le groupe extérieur')

  if(outGroup.type === 'triphase' && (!outGroup.measures.l1l2 ||
    !outGroup.measures.l1l3 ||
    !outGroup.measures.l2l3 ||
    !outGroup.measures.l1n ||
    !outGroup.measures.l2n ||
    !outGroup.measures.l3n))
    errors.push('Les mesures du groupe extérieur triphase ne sont pas remplies');

  return errors;
}

const validateIndUnit = indUnit => {
  let errors = [];

  if(!indUnit.brand)
    errors.push('La marque n\'est pas remplie sur l\'unité intérieure')

  if(!indUnit.model)
    errors.push('La modèle n\'est pas remplie sur l\'unité intérieure')

  if(!indUnit.serialNumber)
    errors.push('La numéro de série n\'est pas remplie sur l\'unité intérieure')

  if(!indUnit.outTemp)
    errors.push('La température en sortie n\'est pas remplie sur l\'unité intérieure')

  return errors;
}

const validateHydrolicModule = hydrolicModule => {
  let errors = [];

  errors = errors.concat(validateDefaultPart(hydrolicModule, 'le module hydrolique'));

  if(!hydrolicModule.tempStart)
    errors.push('La température de départ n\'est pas remplie sur le module hydrolique');

  if(!hydrolicModule.tempReturn)
    errors.push('La température de retour n\'est pas remplie sur le module hydrolique');

  return errors;
}

const validateDefaultPart = (part, name) => {
  let errors = [];

  if(!part.brand)
    errors.push(`La marque n'est pas remplie sur ${name}`)

  if(!part.model)
    errors.push(`La modèle n'est pas remplie sur ${name}`)

  if(!part.serialNumber)
    errors.push(`La numéro de série n'est pas remplie sur ${name}`)

  return errors;
}
