'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import './intervention-report-ref.js';

class InterventionReportOutGroup extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
      }

      .hardware {
        border: 1px solid #ccc;
        border-radius: 5px;
      }
      
      .part {
        padding: 13px;
      }

      .part:not(:last-child){
        border-bottom: 1px solid #ccc;
      }

      

      .voltages {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-top: 0;
      }

      .measures {
        padding-top: 0;
      }

      .voltage-part {
        flex-grow: 1;
      }

      .voltage {
        color: #444;
        font-weight: 300;
        margin-right: 10px;
      }

      .small-title {
        padding: 13px;
        padding-bottom: 5px;
        display: inline-block;
        text-transform: uppercase;
        font-weight: 500;
      }

      .third {
        width: 33%;
        display: inline-block;
      }

      .padded {
        /*margin-left: 10px;*/
      }

      .refs {
        padding: 0 !important;
      }

      .hardware {
        margin-bottom: 10px;
      }

      .mono {
        display: block;
      }

      h3 {
        font-size: 20px;
      }
    `;
  }

  render() {
    return html`
      ${this.outGroups.length ? html`<h3>Groupes extérieurs</h3>` : ''}
      ${this.outGroups.map(item => html`
        <div class="hardware">
          <div class="refs part">
            <intervention-report-ref .hardware="${item}"></intervention-report-ref>
          </div>

          <b class="small-title">Tension entre les bornes</b>

          ${item.type === 'triphase' ? html`
            <div class="voltages part">
              <div class="voltage-part">
                <span class="voltage">L1 ↔︎ L2</span> ${item.measures.l1l2}v
              </div>
              <div class="voltage-part">
                <span class="voltage">L1 ↔︎ L3</span> ${item.measures.l1l3}v
              </div>
              <div class="voltage-part">
                <span class="voltage">L2 ↔︎ L3</span> ${item.measures.l2l3}v
              </div>
              <div class="voltage-part">
                <span class="voltage">L1 ↔︎ N</span> ${item.measures.l1n}v
              </div>
              <div class="voltage-part">
                <span class="voltage">L2 ↔︎ N</span> ${item.measures.l2n}v
              </div>
              <div class="voltage-part">
                <span class="voltage">L3 ↔︎ N</span> ${item.measures.l3n}v
              </div>
            </div>
          ` : ''}

          ${item.type === 'mono' ? html`
            <div class="voltages part mono">
              <div>
                <span class="voltage">L ↔︎ N</span> ${item.measures.ln}v
              </div>
            </div>
          ` : ''}

          <b class="small-title">Températures</b>
          <div class="measures part">
            <div class="third">
              <span class="voltage">Mode chaud</span> ${item.hotModeGaz} bars
            </div>

            ${item.coldModeGaz ? html`
              <div class="third">
                <span class="voltage">Mode froid</span> ${item.coldModeGaz} bars
              </div>
            ` : ''}
          </div>
        </div>
      `)}
    `;
  }

  static get properties() {
    return {
      outGroups: { type: Array }
    }
  }

  constructor() {
    super();
    this.outGroups = [];
  }
}

customElements.define('intervention-report-out-group', InterventionReportOutGroup);
