'use strict';

import { css } from '../node_modules/lit-element/lit-element.js';

export const SharedStyles = css`
  .small-label {
    display: inline;
    position: relative;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    background-color: #595959;
    padding: 1px 0.4em;
    margin-right: 2px;
    text-transform: uppercase;
  }

  .name-label {
    padding: 3px 20px;
    margin: 3px;
    border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    color: white;
    font-weight: 500;
  }

  .card {
    margin: 24px;
    padding: 16px;
    color: #757575;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }

  .circle {
    display: inline-block;

    width: 64px;
    height: 64px;

    text-align: center;

    color: #555;
    border-radius: 50%;
    background: #ddd;

    font-size: 30px;
    line-height: 64px;
  }

  h1 {
    margin: 16px 0;
    color: #212121;
    font-size: 22px;
  }

  @media only screen 
  and (min-device-width : 600px) {
    .show-mobile {
      display: none !important;
    }
  }


  @media only screen 
  and (max-device-width : 600px) {
    .hide-mobile {
      display: none !important;
    }
  }

  .add-equipment {
    background-color: #3A6FDF;
    color: white;
    font-weight: 700;
    font-size: 20px;
    display: block;
    text-align: center;
    border-radius: 5px;
  }

  .card-header {
    font-weight: bold;
    font-size: 1.5rem;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #ddd;
  }

  .paper-card .header {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr auto;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #ddd;
  }

  .paper-card .header .card-header {
    padding: 0;
    border-bottom: 0;
  }

  .paper-card .header simple-remove {
    text-align: right;
    align-self: flex-end;
  }

  .paper-card {
    position: relative;
    display: inline-block;
    background-color: white;
    /*box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);*/
    width: 100%;

    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .paper-card .content {
    padding: 1.5rem;
  }


  @media only screen 
  and (min-device-width : 320px) 
  and (max-device-width : 400px) {
    .content {
      padding: 1rem !important;
    }

    .card-header {
      padding: 1rem;
    }
  }

  .card-subtitle {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .shadow-2dp {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                0 1px 5px 0 rgba(0, 0, 0, 0.12),
                0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }

  .name {
    --paper-amber-200: #ffe082;
    --paper-amber-800: #ff8f00;
    --paper-blue-200: #90caf9;
    --paper-blue-800: #1565c0;
    --paper-blue-grey-200: #b0bec5;
    --paper-blue-grey-800: #37474f;
    --paper-brown-200: #bcaaa4;
    --paper-brown-800: #4e342e;
    --paper-cyan-200: #80deea;
    --paper-cyan-800: #00838f;
    --paper-deep-orange-200: #ffab91;
    --paper-deep-orange-800: #d84315;
    --paper-deep-purple-200: #b39ddb;
    --paper-deep-purple-800: #4527a0;
    --paper-green-200: #a5d6a7;
    --paper-green-800: #2e7d32;
    --paper-indigo-200: #9fa8da;
    --paper-indigo-800: #283593;
    --paper-light-blue-200: #81d4fa;
    --paper-light-blue-800: #0277bd;
    --paper-light-green-200: #c5e1a5;
    --paper-light-green-800: #558b2f;
    --paper-lime-200: #e6ee9c;
    --paper-lime-800: #9e9d24;
    --paper-orange-200: #ffcc80;
    --paper-orange-800: #ef6c00;
    --paper-pink-200: #f48fb1;
    --paper-pink-800: #ad1457;
    --paper-purple-200: #ce93d8;
    --paper-purple-800: #6a1b9a;
    --paper-red-200: #ef9a9a;
    --paper-red-800: #c62828;
    --paper-teal-200: #80cbc4;
    --paper-teal-800: #00695c;
    --paper-yellow-200: #fff59d;
    --paper-yellow-800: #f9a825;
  }

  /* admin colors */
  .black-900 { color: black !important; border: 1px solid black !important; background-color: white; }
  .black-900-bd { color: black !important; border: 1px solid black !important; }

  /* if there are not enough colors to covers all the techs */
  .undefined-800 { background-color: #333; color: white; }
  .undefined-800-bd { color: #333 !important; border-color: #333 !important }

  .red-800 { background-color: var(--paper-red-800); color: white; }
  .purple-800 { background-color: var(--paper-purple-800); color: white; }
  .indigo-800 { background-color: var(--paper-indigo-800); color: white; }
  .light-blue-800 { background-color: var(--paper-light-blue-800); color: white; }
  .teal-800 { background-color: var(--paper-teal-800); color: white; }
  .light-green-800 { background-color: var(--paper-light-green-800); color: white; }
  .yellow-800 { background-color: var(--paper-yellow-800); color: white; }
  .orange-800 { background-color: var(--paper-orange-800); color: white; }
  .brown-800 { background-color: var(--paper-brown-800); color: white; }
  .blue-grey-800 { background-color: var(--paper-blue-grey-800); color: white; }
  .pink-800 { background-color: var(--paper-pink-800); color: white; }
  .deep-purple-800 { background-color: var(--paper-deep-purple-800); color: white; }
  .blue-800 { background-color: var(--paper-blue-800); color: white; }
  .cyan-800 { background-color: var(--paper-cyan-800); color: white; }
  .green-800 { background-color: var(--paper-green-800); color: white; }
  .lime-800 { background-color: var(--paper-lime-800); color: white; }
  .amber-800 { background-color: var(--paper-amber-800); color: white; }
  .deep-orange-800 { background-color: var(--paper-deep-orange-800); color: white; }
  .red-200 { background-color: var(--paper-red-200); color: black; }
  .purple-200 { background-color: var(--paper-purple-200); color: black; }
  .indigo-200 { background-color: var(--paper-indigo-200); color: black; }
  .light-blue-200 { background-color: var(--paper-light-blue-200); color: black; }
  .teal-200 { background-color: var(--paper-teal-200); color: black; }
  .light-green-200 { background-color: var(--paper-light-green-200); color: black; }
  .yellow-200 { background-color: var(--paper-yellow-200); color: black; }
  .orange-200 { background-color: var(--paper-orange-200); color: black; }
  .brown-200 { background-color: var(--paper-brown-200); color: black; }
  .blue-grey-200 { background-color: var(--paper-blue-grey-200); color: black; }
  .pink-200 { background-color: var(--paper-pink-200); color: black; }
  .deep-purple-200 { background-color: var(--paper-deep-purple-200); color: black; }
  .blue-200 { background-color: var(--paper-blue-200); color: black; }
  .cyan-200 { background-color: var(--paper-cyan-200); color: black; }
  .green-200 { background-color: var(--paper-green-200); color: black; }
  .lime-200 { background-color: var(--paper-lime-200); color: black; }
  .amber-200 { background-color: var(--paper-amber-200); color: black; }
  .deep-orange-200 { background-color: var(--paper-deep-orange-200); color: black; }

  .red-800-bd { color: var(--paper-red-800) !important; border-color: var(--paper-red-800) !important}
  .purple-800-bd { color: var(--paper-purple-800) !important; border-color: var(--paper-purple-800) !important}
  .indigo-800-bd { color: var(--paper-indigo-800) !important; border-color: var(--paper-indigo-800) !important}
  .light-blue-800-bd { color: var(--paper-light-blue-800) !important; border-color: var(--paper-light-blue-800) !important}
  .teal-800-bd { color: var(--paper-teal-800) !important; border-color: var(--paper-teal-800) !important}
  .light-green-800-bd { color: var(--paper-light-green-800) !important; border-color: var(--paper-light-green-800) !important}
  .yellow-800-bd { color: var(--paper-yellow-800) !important; border-color: var(--paper-yellow-800) !important}
  .orange-800-bd { color: var(--paper-orange-800) !important; border-color: var(--paper-orange-800) !important}
  .brown-800-bd { color: var(--paper-brown-800) !important; border-color: var(--paper-brown-800) !important}
  .blue-grey-800-bd { color: var(--paper-blue-grey-800) !important; border-color: var(--paper-blue-grey-800) !important}
  .pink-800-bd { color: var(--paper-pink-800) !important; border-color: var(--paper-pink-800) !important}
  .deep-purple-800-bd { color: var(--paper-deep-purple-800) !important; border-color: var(--paper-deep-purple-800) !important}
  .blue-800-bd { color: var(--paper-blue-800) !important; border-color: var(--paper-blue-800) !important}
  .cyan-800-bd { color: var(--paper-cyan-800) !important; border-color: var(--paper-cyan-800) !important}
  .green-800-bd { color: var(--paper-green-800) !important; border-color: var(--paper-green-800) !important}
  .lime-800-bd { color: var(--paper-lime-800) !important; border-color: var(--paper-lime-800) !important}
  .amber-800-bd { color: var(--paper-amber-800) !important; border-color: var(--paper-amber-800) !important}
  .deep-orange-800-bd { color: var(--paper-deep-orange-800) !important; border-color: var(--paper-deep-orange-800) !important}
  .red-200-bd { color: var(--paper-red-200) !important; border-color: var(--paper-red-200) !important}
  .purple-200-bd { color: var(--paper-purple-200) !important; border-color: var(--paper-purple-200) !important}
  .indigo-200-bd { color: var(--paper-indigo-200) !important; border-color: var(--paper-indigo-200) !important}
  .light-blue-200-bd { color: var(--paper-light-blue-200) !important; border-color: var(--paper-light-blue-200) !important}
  .teal-200-bd { color: var(--paper-teal-200) !important; border-color: var(--paper-teal-200) !important}
  .light-green-200-bd { color: var(--paper-light-green-200) !important; border-color: var(--paper-light-green-200) !important}
  .yellow-200-bd { color: var(--paper-yellow-200) !important; border-color: var(--paper-yellow-200) !important}
  .orange-200-bd { color: var(--paper-orange-200) !important; border-color: var(--paper-orange-200) !important}
  .brown-200-bd { color: var(--paper-brown-200) !important; border-color: var(--paper-brown-200) !important}
  .blue-grey-200-bd { color: var(--paper-blue-grey-200) !important; border-color: var(--paper-blue-grey-200) !important}
  .pink-200-bd { color: var(--paper-pink-200) !important; border-color: var(--paper-pink-200) !important}
  .deep-purple-200-bd { color: var(--paper-deep-purple-200) !important; border-color: var(--paper-deep-purple-200) !important}
  .blue-200-bd { color: var(--paper-blue-200) !important; border-color: var(--paper-blue-200) !important}
  .cyan-200-bd { color: var(--paper-cyan-200) !important; border-color: var(--paper-cyan-200) !important}
  .green-200-bd { color: var(--paper-green-200) !important; border-color: var(--paper-green-200) !important}
  .lime-200-bd { color: var(--paper-lime-200) !important; border-color: var(--paper-lime-200) !important}
  .amber-200-bd { color: var(--paper-amber-200) !important; border-color: var(--paper-amber-200) !important}
  .deep-orange-200-bd { color: var(--paper-deep-orange-200) !important; border-color: var(--paper-deep-orange-200) !important}
`;

export const FabStyles = css`
  .fab {
    width: 56px;
    height: 56px;
    position: absolute !important;
    bottom: 20px;
    right: 30px;
    border-radius: 50%;
    background-color: #e9437a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0;
    cursor: pointer;
    border: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 
      0 1px 5px 0 rgba(0, 0, 0, 0.12), 
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }
`;

export const MaterialStyles = css`
  .material-textfield {
      --pure-material-safari-helper1: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
      position: relative;
      display: inline-block;
      padding-top: 6px;
      font-size: 16px;
      line-height: 1.5;
      overflow: hidden;
  }

  /* Input, Textarea */
  .material-textfield > input,
  .material-textfield > textarea {
      box-sizing: border-box;
      margin: 0;
      border: solid 1px; /* Safari */
      border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
      border-top-color: transparent;
      border-radius: 4px;
      padding: 15px 13px 15px;
      width: 100%;
      height: inherit;
      color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
      background-color: transparent;
      box-shadow: none; /* Firefox */
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      caret-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
      transition: border 0.2s, box-shadow 0.2s;
  }
  
  .material-textfield.narrow > input,
  .material-textfield.narrow > textarea {
      padding: 12px 13px 12px;
  }

  .material-textfield.narrow > input:not(:focus):placeholder-shown + span,
  .material-textfield.narrow > textarea:not(:focus):placeholder-shown + span {
      font-size: inherit;
      line-height: 68px;
  }

  /* Span */
  .material-textfield > input + span,
  .material-textfield > textarea + span {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
      width: 100%;
      max-height: 100%;
      color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
      font-size: 75%;
      line-height: 15px;
      cursor: text;
      transition: color 0.2s, font-size 0.2s, line-height 0.2s;
  }

  /* Corners */
  .material-textfield > input + span::before,
  .material-textfield > input + span::after,
  .material-textfield > textarea + span::before,
  .material-textfield > textarea + span::after {
      content: "";
      display: block;
      box-sizing: border-box;
      margin-top: 6px;
      border-top: solid 1px;
      border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
      min-width: 10px;
      height: 8px;
      pointer-events: none;
      box-shadow: inset 0 1px transparent;
      transition: border-color 0.2s, box-shadow 0.2s;
  }

  .material-textfield > input + span::before,
  .material-textfield > textarea + span::before {
      margin-right: 4px;
      border-left: solid 1px transparent;
      border-radius: 4px 0;
  }

  .material-textfield > input + span::after,
  .material-textfield > textarea + span::after {
      flex-grow: 1;
      margin-left: 4px;
      border-right: solid 1px transparent;
      border-radius: 0 4px;
  }

  /* Hover */
  .material-textfield:hover > input,
  .material-textfield:hover > textarea {
      border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
      border-top-color: transparent;
  }

  .material-textfield:hover > input + span::before,
  .material-textfield:hover > textarea + span::before,
  .material-textfield:hover > input + span::after,
  .material-textfield:hover > textarea + span::after {
      border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  }

  .material-textfield:hover > input:not(:focus):placeholder-shown,
  .material-textfield:hover > textarea:not(:focus):placeholder-shown {
      border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  }

  /* Placeholder-shown */
  .material-textfield > input:not(:focus):placeholder-shown,
  .material-textfield > textarea:not(:focus):placeholder-shown {
      border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  }

  .material-textfield > input:not(:focus):placeholder-shown + span,
  .material-textfield > textarea:not(:focus):placeholder-shown + span {
      font-size: inherit;
      line-height: 68px;
  }

  .material-textfield > input:not(:focus):placeholder-shown + span::before,
  .material-textfield > textarea:not(:focus):placeholder-shown + span::before,
  .material-textfield > input:not(:focus):placeholder-shown + span::after,
  .material-textfield > textarea:not(:focus):placeholder-shown + span::after {
      border-top-color: transparent;
  }

  /* Focus */
  .material-textfield > input:focus,
  .material-textfield > textarea:focus {
      border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
      border-top-color: transparent;
      box-shadow: inset 1px 0 var(--pure-material-safari-helper1), inset -1px 0 var(--pure-material-safari-helper1), inset 0 -1px var(--pure-material-safari-helper1);
      outline: none;
  }

  .material-textfield > input:focus + span,
  .material-textfield > textarea:focus + span {
      color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  }

  .material-textfield > input:focus + span::before,
  .material-textfield > input:focus + span::after,
  .material-textfield > textarea:focus + span::before,
  .material-textfield > textarea:focus + span::after {
      border-top-color: var(--pure-material-safari-helper1) !important;
      box-shadow: inset 0 1px var(--pure-material-safari-helper1);
  }

  /* Disabled */
  .material-textfield > input:disabled,
  .material-textfield > input:disabled + span,
  .material-textfield > textarea:disabled,
  .material-textfield > textarea:disabled + span {
      border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38) !important;
      border-top-color: transparent !important;
      color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
      pointer-events: none;
  }

  .material-textfield > input:disabled + span::before,
  .material-textfield > input:disabled + span::after,
  .material-textfield > textarea:disabled + span::before,
  .material-textfield > textarea:disabled + span::after {
      border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38) !important;
  }

  .material-textfield > input:disabled:placeholder-shown,
  .material-textfield > input:disabled:placeholder-shown + span,
  .material-textfield > textarea:disabled:placeholder-shown,
  .material-textfield > textarea:disabled:placeholder-shown + span {
      border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38) !important;
  }

  .material-textfield > input:disabled:placeholder-shown + span::before,
  .material-textfield > input:disabled:placeholder-shown + span::after,
  .material-textfield > textarea:disabled:placeholder-shown + span::before,
  .material-textfield > textarea:disabled:placeholder-shown + span::after {
      border-top-color: transparent !important;
  }

  /* Faster transition in Safari for less noticable fractional font-size issue */
  @media not all and (min-resolution:.001dpcm) {
      @supports (-webkit-appearance:none) {
          .material-textfield > input,
          .material-textfield > input + span,
          .material-textfield > textarea,
          .material-textfield > textarea + span,
          .material-textfield > input + span::before,
          .material-textfield > input + span::after,
          .material-textfield > textarea + span::before,
          .material-textfield > textarea + span::after {
              transition-duration: 0.1s;
          }
      }
  }
`;

export const MaterialTabs = css`
  .tabs {
    width: 100%;
    position: relative;
    display: flex;
    background-color: var(--tab-background-color, #2e5393);
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
    font-size: 17px;
    font-weight: 500;
  }

  .tabs .tab {
    display: none;
    -webkit-tap-highlight-color: transparent;
  }
  .tabs .tab:checked + label {
    color: var(--tab-active-color, white);
  }
  .tabs .tab:checked {
    font-weight: 700;
  }
  
  .tabs .tab:nth-of-type(1):checked ~ .tab-indicator {
    left: calc((100% / var(--number-of-tabs)) * 0);
  }
  .tabs .tab:nth-of-type(2):checked ~ .tab-indicator {
    left: calc((100% / var(--number-of-tabs)) * 1);
  }
  .tabs .tab:nth-of-type(3):checked ~ .tab-indicator {
    left: calc((100% / var(--number-of-tabs)) * 2);
  }
  .tabs .tab:nth-of-type(4):checked ~ .tab-indicator {
    left: calc((100% / var(--number-of-tabs)) * 3);
  }
  .tabs .tab:nth-of-type(5):checked ~ .tab-indicator {
    left: calc((100% / var(--number-of-tabs)) * 4);
  }
  .tabs .tab:first-of-type:checked ~ .tab-indicator {
    left: 0;
  }
  
  /* two items */
  .tabs .tab:nth-last-of-type(2) ~ .tab-indicator {
    --number-of-tabs: 2;
  }
  /* three items */
  .tabs .tab:nth-last-of-type(3) ~ .tab-indicator {
    --number-of-tabs: 3;
  }
  /* four items */
  .tabs .tab:nth-last-of-type(4) ~ .tab-indicator {
    --number-of-tabs: 4;
  }
  .tabs .tab:nth-last-of-type(5) ~ .tab-indicator {
    --number-of-tabs: 5;
  }

  .tabs label {
    position: relative;
    cursor: pointer;
    color: var(--tab-inactive-color, rgba(255, 255, 255, 0.7));
    background-color: var(--tab-background-color, #2e5393);
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    transition: color 0.2s ease;
    width: 100%;
    text-transform: uppercase;
    -webkit-tap-highlight-color: transparent;
  }

  .tab-indicator {
    background: var(--tab-selector-color, white);
    width: calc(100% / var(--number-of-tabs));
    height:  var(--tab-selector-height, 2px);
    position: absolute;
    left: 0;
    top: calc(100% - 2px);
    transition: left .2s ease;
  }
`;

export const LightboxStyles = css`
  .lightbox {
    display: none;
  }

  .lightbox-label {
    display: none;
  }

  .lightbox + label,
  .lightbox + label + img + label {
    cursor: pointer;
  }

  .lightbox + label + img {
    display: none;
  }

  .lightbox:checked + label + img {
    display: block;
  }

  .lightbox:checked + label,
  .lightbox:checked + label + img + label {
    background: rgba(0,0,0,0.8);
    outline: none;
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lightbox:checked + label > img {
    background: white;
    width: auto !important;
    height: auto !important;
    max-width: 90%;
    max-height: 90%;
    padding: 0;
    margin: 0;
  }
`