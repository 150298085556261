'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import { styleMap } from '../../../node_modules/lit-html/directives/style-map.js';
import { repeat } from '../../../node_modules/lit-html/directives/repeat.js';
import { SharedStyles } from '../../shared-styles.js';

class ClientsTable extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          cursor: pointer;
          border: 1px solid #ccc;
          border-radius: 5px;
          height: 100%;
          max-height: 100%;
          display: grid;
          font-size: 15px;
        }

        #scroller {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
        }

        #container {
          overflow: auto;
          position: relative;
          padding: 0;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          height: 100%;
        }

        .row {
          position: absolute;
          display: flex;
          border-bottom: 1px solid #ccc;
          width: 100%;
          margin: 0;
          padding: 0;
          padding-left: 10px;
          box-sizing: border-box;
          align-items: center;
        }

        .row:hover {
          background-color: #f5f5f5;
        }

        .sort-indicator {
          font-size: 10px;
          padding-left: 10px;
        }

        @media only screen 
        and (max-device-width : 600px) {
          .name-cell {
            flex-basis: 100%;
          }

          :host {
            grid-template-rows: 100%;
          }

          #header {
            display: none;
          }
        }

        @media only screen 
        and (min-device-width : 600px) {
          .cell {
            flex-basis: 20%;
          }

          .selected, .selected:hover {
            background-color: #E3EDFF;
          }

          :host {
            grid-template-rows: 48px calc(100% - 48px);
          }

          #header {
            display: flex;
            border-bottom: 1px solid #ccc;
            align-items: center;
            padding-left: 10px;
            color: #666;
            box-sizing: border-box;
            font-size: 13px;
          }
        }

        .name {
          border-radius: 3px;
          font-weight: 500;
          padding: 5px;
          color: #333;
        }
      `
    ];
  }

  renderHeaderCell(key, name) {
    return html`
      <div class="cell" sort-key="${key}" @click="${this._sortChanged}">${name}
        <span class="sort-indicator">
          ${this.sortType === key ? '▲' : ''}
          ${this.sortType === `-${key}` ? '▼' : ''}
        </span>
      </div>
    `
  }

  rowStyles(idx) {
    return styleMap({
      top: `${(this.firstItem + idx) * this.itemHeight}px`,
      height: `${this.itemHeight}px`
    });
  }

  render() {
    return html`
      <div id="header" style="padding-right: ${this.scrollBarWidth}px">
        ${this.renderHeaderCell('name', 'Nom')}
        ${this.renderHeaderCell('sales', 'Commercial')}
        ${this.renderHeaderCell('postalCode', 'Localité')}
        ${this.renderHeaderCell('interventionsDone', 'RDV effectués')}
        ${this.renderHeaderCell('interventionsWaiting', 'RDV en attente')}
      </div>
      <div id="container" @scroll="${this._onScroll}">
        <div id="scroller"></div> 
        ${repeat(this.rendered, item => item._id, (item, idx) => html`
          <div
            class="row ${item._id === this.clientId ? 'selected' : ''}"
            style="${this.rowStyles(idx)}"
            .client-id="${item._id}"
            @click="${this._selectClient}"
          >
            <div class="cell name-cell">${item.name}</div>
            ${this.mobile ? html`` : html`
              <div class="cell">
                <span class="name ${item._sales ? this._getSalesColor(item._sales._id) : ''}">
                  ${item._sales ? item._sales.name : ''}
                </span>
              </div>
              <div class="cell">${item.address.postalCode} ${item.address.city}</div>
              <div class="cell">${this._getDone(item)}</div>
              <div class="cell">${this._getNotDone(item)}</div>
            `}
          </div>
        `)}
      <div>
    `
  }

  static get properties() {
    return {
      clients: { type: Array },
      rendered: { type: Array },
      items: { type: Array },
      itemHeight: { type: Number },
      width: { type: Number },
      height: { type: Number },
      sortType: { type: String },
      clientId: { type: String }
    }
  }

  constructor() {
    super();
    this.clients = [];
    this.rendered = [];
    this.items = [];
    this.itemHeight = 48;
    this.firstItem = 0;
    this.sortType = 'name';
    this._clients = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this._init();
  }

  _init() {
    if(!this.shadowRoot.getElementById('scroller') || !this.shadowRoot.getElementById('container'))
      return setTimeout(() => this._init(), 100);

    this.scroller = this.shadowRoot.getElementById('scroller');
    this.container = this.shadowRoot.getElementById('container');
    window.addEventListener('resize', this._refreshVirtualList.bind(this), { passive: true });
  }

  updated(props) {
    if(props.has('clients') || props.has('sortType')) {
      this._sort();

      // avoids blank table
      if(this.scroller && this.container){
        this.container.scrollTop = 0;
        this.scroller.scrollTop = 0;
      }
      
      this._refreshVirtualList();
    }
  }

  _onScroll(e) {
    const scrollTop = e.target.scrollTop;
    const maxBuffer = this.screenItemsLen * this.itemHeight;
    let first = parseInt(scrollTop / this.itemHeight) - this.screenItemsLen;
    first = first < 0 ? 0 : first;
    
    if (!this.lastRepaintY || Math.abs(scrollTop - this.lastRepaintY) > maxBuffer) {
      this.firstItem = first;
      this.rendered = this._clients.slice(first, (first + this.cachedItemsLen));
      this.lastRepaintY = scrollTop;
    }
  }

  _refreshVirtualList() {
    if(!this.scroller || !this.container || !Array.isArray(this._clients) || !this.container.clientHeight)
      return setTimeout(() => this._refreshVirtualList(), 100);

    const height = this.container.clientHeight;
    this.mobile = window.outerWidth <= 600 ? true : false;
    this.scroller.style.height = (this.itemHeight * this._clients.length) + 'px';

    this.screenItemsLen = Math.ceil(height / this.itemHeight);
    // Cache 4 times the number of items that fit in the container viewport
    this.cachedItemsLen = this.screenItemsLen * 4;
    this.firstItem = 0;
    this.rendered = this._clients.slice(0, this.cachedItemsLen / 2);
    this._setScrollbarWidth();
  }

  _sortChanged(e) {
    if(this.sortType === e.currentTarget.getAttribute('sort-key'))
      this.sortType = `-${this.sortType}`;
    else
      this.sortType = e.currentTarget.getAttribute('sort-key');
  }

  _getDone(client) {
    return (client.interventions || []).reduce((acc, val) => {return acc + (val.finished ? 1 : 0) }, 0);
  }

  _getNotDone(client) {
    return (client.interventions || []).reduce((acc, val) => {return acc + (val.finished ? 0 : 1) }, 0);
  }

  _sort() {
    const reverse = this.sortType.startsWith('-') ? true : false;
    const sort = this.sortType.replace('-', '');

    let sortFn;
    switch(sort) {
      case 'name':
        sortFn = (a, b) => a.name.localeCompare(b.name)
        break;
      case 'sales':
        sortFn = (a, b) => (
          a._sales && a._sales.name ? a._sales.name : ''
        ).localeCompare(
          b._sales && b._sales.name ? b._sales.name : ''
        )
        break;
      case 'postalCode':
        sortFn = (a, b) => a.address.postalCode - b.address.postalCode
        break;
      case 'interventionsDone':
        sortFn = (a, b) => this._getDone(a) - this._getDone(b)
        break;
      case 'interventionsWaiting':
        sortFn = (a, b) => this._getNotDone(a) - this._getNotDone(b)
        break;
    }
    
    this._clients = reverse ? [...this.clients].sort(sortFn).reverse() : [...this.clients].sort(sortFn);
  }

  _setScrollbarWidth() {
    this.scrollBarWidth = this.container.offsetWidth - this.container.clientWidth;
  }

  _selectClient(e) {
    this.clientId = e.currentTarget['client-id'];
    this.dispatchEvent(new CustomEvent('client-selected', {
      bubbles: true, composed: true, detail: this.clientId
    }))
  }

  _getSalesColor(id) {
    if(!id) return;
    return (this.sales.find(s => s._id === id) || {}).color;
  }

}


customElements.define('clients-table', ClientsTable);


