'use strict';

export default function(intervention) {
  let errors = [];
  if(!intervention._client)
    errors.push('Erreur interne: identifiant client absent');

  if(!intervention._tech)
    errors.push('Erreur interne: identifiant technicien absent');

  if(!intervention.date)
    errors.push('Erreur interne: date absente');

  if(!intervention.signatures || !intervention.signatures.client)
    errors.push('Le client n\'a pas signé l\'intervention');

  if(!intervention.signatures || !intervention.signatures.technician)
    errors.push('Le technicien n\'a pas signé l\'intervention');

  return errors;
}
