'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';

class InterventionReportSav extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
      }

      table{
        width: 100%;
        border-spacing: initial;
        border: 1px solid #ccc;
        border-radius: 5px;
        color: #333;
      }

      th{
        text-align: left;
        border: 0;
        padding: 9px;
        text-transform: uppercase;
        color: #666;
        font-weight: 300;
        font-size: 14px;
        border-left: 0;
        border-right: 0;
      }

      td{
        text-align: left;
        border-top: 1px solid #ddd;
        padding: 9px;
        border-left: 0;
        border-right: 0;
      }

      tbody > tr:nth-child(even) {
        background-color: #fafafa;
      }

      #photos {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 10px;
        column-gap: 10px;
      }

      img {
        border: 1px solid #ccc;
        border-radius: 5px;
        max-width: 300px;
        padding: 5px;
        box-sizing: border-box;
        width: 100%;
        height: 300px;
        object-fit: cover;
      }

      @media only screen and (max-device-width : 600px) {
        #photos {
          grid-gap: 15px;
          grid-template-columns: initial;
        }

        img {
          margin: 0;
          width: 100%;
          max-width: initial;
        }
      }
    `;
  }

// TODO closes by itself on click

  render() {
    return html`
      ${this.intervention.hardware && this.intervention.hardware.length ? html`
        <h3>Matériel</h3>
        <table>
          <thead>
            <tr>
              <th>Marque</th>
              <th>Modèle</th>
              <th>Numéro de série</th>
              <th>Pièce à commander</th>
              <th>Code hotline</th>
            </tr>
          </thead>
          <tbody>
            ${this.intervention.hardware.map(item => html`
              <tr>
                <td>${item.brand}</td>
                <td>${item.model}</td>
                <td>${item.serialNumber}</td>
                <td>${item.piecesToOrder}</td>
                <td>${item.hotlineCode}</td>
              </tr>
            `)}
          </tbody>
        </table>
      ` : ''}

      ${this.intervention.workDone ? html`
        <h3>Travaux effectués</h3>
        ${this.intervention.workDone}
      ` : ''}

      ${this.intervention.photos && this.intervention.photos.length ? html`
        <h3>Photos</h3>
        <div id="photos">
          ${this.intervention.photos.map(item => html`
            <img @click="${this.showPhoto}" src="${this._photoPath(item)}" decoding="async"></img>
          `)}
        </div>
      ` : ''}
    `;
  }

  static get properties() {
    return {
      intervention: { type: Object }
    }
  }

  constructor() {
    super();
    this.intervention = {};
  }

  // for some reason bundling breaks the template
  // so we must use a function instead of doing it in place
  _photoPath(item) {
    return `${window.IMAGE_HOST}${item}`;
  }

  showPhoto(e) {
    this.dispatchEvent(new CustomEvent('show-photo', {bubbles: true, composed: true, 
      detail: e.target.getAttribute('src')
    }));
  }
}

customElements.define('intervention-report-sav', InterventionReportSav);
