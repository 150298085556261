'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import { formatTime } from '../../utils/utils.js';

class InterventionInfo extends LitElement {
  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }

      #container {
        padding: 2em;
        font-size: 5.5vmin;
      }

      #name {
        font-size: 8vmin;
        font-weight: 900;
        margin-bottom: 10px;
      }

      #phone {
        color: #4285f4;
      }

      .address {
        color: #666;
      }
    `;
  }

  render() {
    return html`
      <div id="container">
        
        <div id="name">${this.name}</div>
        <div id="phone">${this.phone}</div>
        <div id="phone">${this.phone2}</div>
        <div id="phone">${this.phone3}</div>
        <div class="address">${this.address.street}, <br></div>
        <div class="address">${this.address.postalCode} ${this.address.city}</div><br>

        <div>Rendez-vous à: ${formatTime(this.time)}</div><br>
        <div>${this.notes}</div><br>
        ${this.reclamation ? html`
          <div>${this.reclamation}</div><br>
        ` : ''}
      </div>
    `;
  }

  static get properties() {
    return {
      name: { type: String },
      notes: { type: String },
      address: { type: Object },
      phone: { type: String },
      phone2: { type: String },
      phone3: { type: String },
      time: { type: String },
      reclamation: { type: String }
    }
  }

  constructor() {
    super();
    this.address = {};
  }
}

customElements.define('intervention-info', InterventionInfo);
