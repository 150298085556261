'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-ripple/paper-ripple.js';
import { SharedStyles } from '../../shared-styles.js';
import { formatTime, interventionTypeName } from '../../utils/utils.js';
import * as Intervention from '../../api/api-interventions.js';

class InterventionCalendarDetail extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
          height: 100%;
        }

        header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: white;
          border-radius: 5px;
        }

        h1 {
          display: inline-block;
          font-size: 1.4em;
          font-weight: 300;
          text-transform: uppercase;
        }

        .paper-card {
          display: block;
          margin: auto;
          margin-bottom: 20px;
          width: 100%;
          padding: 10px 15px;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 1em;
          position: relative;
        }

        .card-content {
          padding: 15px;
        }

        header {
          width: 100%;
          text-align: center;
        }

        header > h1 {
          width: 100%;
        }

        .hidden {
          display: none;
        }

        #detail {
          overflow-y: auto;
          height: calc(100% - 66px);
        }

        .name-header {
          padding: 15px;
          font-size: 20px;
          font-weight: 300;
          margin-bottom: 20px;
          border-radius: 5px;
          text-align: center;
          padding: 10px;
        }

        .name-header:not(:first-child), .name-header:not(:first-child + 1){
          margin-top: 25px;
        }

        #empty {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          font-size: 26px;
          font-weight: 300;
          color: #ccc;
          text-align: center;
        }

        .card-actions > a {
          color: #4285f4;
          text-transform: uppercase;
          flex-grow: 1;
          text-align: center;
          padding: 10px;
          cursor: pointer;
          text-decoration: none;
          position: relative;
        }


        .tech {
          color: white;
        }

        .sales {
          color: #333;
        }

        .card-actions {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding: 0 !important;
          border-top: 1px solid #ccc;
        }

        .delete {
          color: #e53935 !important;
        }

        .hour {
          font-size: 20px;
          font-weight: 300;
        }

        .paper-card[disabled]{
          color: #666;
        }

        .client-name, .hour {
          font-size: 1.3em;
        }


        header, #detail {
          padding: 0 20px;
          box-sizing: border-box;
        }

        header {
          text-align: center;
          font-size: 1em;
        }

        h1 {
          font-weight: 500 !important;
        }

        .paper-card {
          padding: 0;
        }

        .name-header {
          border-radius: 5px;
          text-align: center;
          padding: 10px;
        }

        .intervention-notes {
          background-color: #eee;
          border-radius: 5px;
          padding: 10px;
          margin-top: 10px;
        }

        @media only screen 
        and (max-device-width : 600px) {
          .hide-mobile { display: none; }

          .user-content:last-of-type > .paper-card:last-of-type {
            margin-bottom: 0;
          }
        }

        @media only screen 
        and (min-device-width : 600px) {
          .show-mobile { display: none; }
        }

      `
    ]
  }
  
  render() {
    return html`
      <header>
        <h1>
          <span class="hide-mobile">${this._getFullDate(this.date)}</span>
          <span class="show-mobile mobile-day">${this._getDay(this.date)}</span>
          <span class="show-mobile mobile-date">${this._getDate(this.date)}</span>
        </h1>
      </header>
      
      <div id="detail">

        ${!this.interventions.length ? html`
          <div id="empty">
            <span>Aucune intervention</span>
          </div>
        ` : ''}

        ${this._splitTechs(this.interventions).map(tech => html`
          <div class="${this._getColor(tech, this.techs, 'name-header tech')}">
            ${this._techName(this.techs, tech._id)}
          </div>
          
          <div class="user-content">

            ${tech.interventions.sort(this._computeSort).map(intervention => html`
              
              <div class="paper-card">
                <div class="card-content">
                  <div class="hour">${formatTime(intervention.date)}</div>
                  ${intervention._client.name ? html`
                    <b class="client-name">${intervention._client.name}</b> <br>
                  ` : ''}

                  <b>${interventionTypeName(intervention.type)} </b><br>
                  ${intervention._client.phone ? html`
                    ${intervention._client.phone} 
                    ${intervention._client.phone2 ? html`
                      - ${intervention._client.phone2}
                    ` : ''}
                    ${intervention._client.phone3 ? html`
                      - ${intervention._client.phone3}
                    ` : ''}
                    
                    <br>
                  ` : ''}

                  ${intervention._client.address.street ? html`
                    ${intervention._client.address.street}&nbsp;<br>
                  ` : ''}

                  ${intervention._client.address.postalCode} ${intervention._client.address.city}

                  ${intervention.notes ? html`
                    <div class="intervention-notes">${intervention.notes}</div>
                  ` : html``}

                </div>

                ${!(intervention.otherSales || intervention.finished) ? html`
                  <div class="card-actions">
                    <a
                      class="action-button"
                      intervention-id="${intervention._id}"
                      @click="${this._editIntervention}"
                    ><paper-ripple></paper-ripple>modifier</a>
                 </div>
                ` : ''}
                
                ${(!intervention.otherSales && intervention.finished) ? html`
                  <div class="card-actions">
                    <a
                      class="action-button"
                      intervention-id="${intervention._id}"
                      type="${intervention.type}"
                      @click="${this._viewReport}"
                    ><paper-ripple></paper-ripple>rapport</a>
                  </div>
                ` : ''}
              </div>
            `)}
          </div>
        `)}
      </div>
    `;
  }

  static get properties() {
    return {
      techs: { type: Array },
      sales: { type: Array },
      date: { type: Date },
      interventions: { type: Array }
    }
  }

  constructor() {
    super();
    this.techs = [];
    this.sales = [];
    this.date = new Date();
    this.interventions = [];
  }

  _getFullDate(date) {
    if(!date) return;

    date = new Date(date);

    return date.toLocaleString('fr-FR', { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  }

  _getDate(date) {
    if(!date) return;
    return new Date(date).toLocaleString('fr-FR', { day: 'numeric' });
  }

  _getDay(date) {
    if(!date) return;
    return new Date(date).toLocaleString('fr-FR', { weekday: 'long' });
  }

  _computeSort(a, b) {
    return a.date > b.date ? 1 : -1;
  }

  _splitTechs(interventions) {
    if(!interventions) return [];
    let techsIndex = [];
    let techs = [];

    for (let i of interventions) {
      if(!techsIndex.includes(i._tech)) {
        techsIndex.push(i._tech);
        techs.push({ _id: i._tech, interventions: [] });
      }

      techs[techsIndex.indexOf(i._tech)].interventions.push(i);
    }

    return techs;
  }

  _getColor(user, users, classes) {
    for (var i = 0; i < users.length; i++) {
      if(users[i]._id === user._id) 
        return classes + ' ' + users[i].color;
    }

    return 'hidden';
  }

  _techName(techs, id) {
    for (var i = 0; i < techs.length; i++) 
      if(techs[i]._id === id)
        return techs[i].name;
  }

  _removeIntervention(e) {
    var target = e.target;
    var id = target.getAttribute('intervention-id');
    Intervention.remove(id);
  }

  _editIntervention(e) {
    var target = e.target;
    var id = target.getAttribute('intervention-id');
    this.dispatchEvent(new CustomEvent('edit-intervention', {bubbles: true, composed: true, 
      detail: id
    }));
  }

  _showButtons(intervention) {
    return !(intervention.otherSales || intervention.finished);
  }

  _showReport(intervention) {
    return (!intervention.otherSales && intervention.finished);
  }

  _viewReport(e) {
    var target = e.target;
    this.dispatchEvent(new CustomEvent('show-report', {bubbles: true, composed: true, 
      detail: { id: target.getAttribute('intervention-id') }
    }));
  }
}

customElements.define('interventions-calendar-detail', InterventionCalendarDetail);
