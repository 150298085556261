'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import './user-table.js';
import * as Intervention from '../../api/api-interventions.js';
import * as Utils from '../../utils/utils.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';
import { SharedStyles } from '../../shared-styles.js';
import { webSocket } from '../../utils/utils.js';

class UserDetail extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: grid;
          grid-template-rows: 75px 75px auto;
          border: 1px solid #ccc;
          border-radius: 5px;
          background-color: white;
          height: 100%;
          max-height: 100%;
          box-sizing: border-box;
        }

        @media only screen 
        and (max-device-width : 600px) {
          :host {
            border: 0;
            grid-template-rows: 60px 60px auto;
          }
        }

        header {
          border-bottom: 1px solid #ccc;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
        }

        h3 {
          font-weight: 300;
          font-size: 30px;
          margin: 0.5em;
          color: white;
        }

        #date {
          display: grid;
          border-bottom: 1px solid #ccc;
          grid-template-columns: auto 1fr auto;
          padding: 5px 20px;
        }

        @media only screen 
        and (max-device-width : 600px) {
          #date {
            padding: 0;
          }

          paper-button {
            min-width: 4em;
          }
        }

        .calendar-nav {
          color: #2b78e4;
          margin: 0;
        }

        #month {
          font-size: 24px;
          text-align: center;
          text-transform: uppercase;
          align-self: center;
          padding: 10px 0;
        }

        #content {
          padding: 20px;
          display: grid;
          grid-gap: 30px;
          overflow-y: scroll;
        }

        .subtitle {
          font-size: 25px;
          margin-bottom: 15px;
        }

        user-table {
          margin-top: 20px;
        }

        #separator {
          margin-left: 20px;
          margin-right: 20px;
        }

        #empty {
          height: 100%;
          min-height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          font-size: 26px;
          font-weight: 300;
          color: #ccc;
          text-align: center;
        }

        .strike {
          text-decoration: line-through;
        }

        h2 {
          margin: 0;
        }
      `
    ]
  }

  render() {
    return html`
      <slot></slot>

      <div id="date">
        <paper-button @click="${this._previousMonth}" class="calendar-nav" aria-label="previous">
          <span class="hide-mobile">précédent</span>
          <span class="show-mobile">${ALCIcons['previous']}</span>
        </paper-button>
        <div id="month">${this._getMonth(this.month)}</div>
        <paper-button @click="${this._nextMonth}" class="calendar-nav" aria-label="next">
          <span class="hide-mobile">suivant</span>
          <span class="show-mobile">${ALCIcons['next']}</span>
        </paper-button>
      </div>
      
      <div id="content">
        ${this.user.type === 'admin' ? '' : html`

          ${this.interventions.length ? '' : html`
            <div id="empty">
              <span>Aucune intervention</span>
            </div>
          `}

          ${this._getIncome(this.interventions) ? html`
            <div id="income">
              <h2>Chiffre d'affaires</h2>
              ${this._getIncome(this.interventions)}€ HT
            </div>
          ` : ''}

          ${this.pastInterventions.length ? html`
            <div id="past">
              <div class="subtitle">Rendez-vous passés</div>
              <user-table
                .interventions="${this.pastInterventions}"
                .users="${this.users}"
                .user="${this.user}"
              ></user-table>
            </div>
          ` : ''}

          ${this.futureInterventions.length ? html`
            <div id="future">
              <div class="subtitle">Rendez-vous futurs</div>
              <user-table
                .interventions="${this.futureInterventions}"
                .users="${this.users}"
                .user="${this.user}"
              ></user-table>
            </div>
          ` : ''}
        `}

      </div>
    `;
  }

  static get properties() {
    return {
      user: { type: Object },
      pastInterventions: { type: Array },
      futureInterventions: { type: Array },
      month: { type: Date },
      interventions: { type: Array }
    }
  }

  constructor() {
    super();
    this.month = new Date(new Date().setDate(5));
    this.interventions = [];
    this.pastInterventions = [];
    this.futureInterventions = [];
    webSocket({ url: window.API_WEB_SOCKET, onMessage: this._socketMessage.bind(this) });
  }

  updated(props) {
    if(props.has('month') || props.has('user'))
      return this._updateCalendar();
    if(props.has('interventions'))
      return this._interventionsChanged();
  }

  async _updateCalendar() {
    const month = this.month;
    const user = this.user;
    if (!month || !user || !user._id) return;

    const { from, to } = Utils.dateRange(month, 'month', false);
    const tech = (user && user.type === 'tech') ? user._id : undefined;
    const sales = (user && user.type === 'sales') ? user._id : undefined;
    this.interventions = await Intervention.get(null, { from, to, tech, sales });
  }

  _socketMessage({ data }) {
    if (data === 'refresh-interventions')
      this._updateCalendar();
  }

  _interventionsChanged() {
    if (!this.interventions)
      return;

    var past = [];
    var future = [];

    for (var i = 0; i < this.interventions.length; i++) {
      if (this.interventions[i].finished)
        past.push(this.interventions[i]);
      else
        future.push(this.interventions[i]);
    }

    this.pastInterventions = past;
    this.futureInterventions = future;
  }

  _getMonth(m) {
    var d = new Date(m);

    var s = d.toLocaleString('fr-FR', {
      month: 'long'
    });

    return s.charAt(0).toUpperCase() + s.slice(1) + ' ' + d.getFullYear();
  }

  _previousMonth() {
    var d = new Date(this.month);
    this.month = new Date(d.setMonth(d.getMonth() - 1));
  }

  _nextMonth() {
    var d = new Date(this.month);
    this.month = new Date(d.setMonth(d.getMonth() + 1));
  }

  _getIncome(interventions) {
    var income = 0;

    for (var i = 0; i < interventions.length; i++)
      if (interventions[i].finished)
        income += interventions[i].price || 0;

    return income;
  }
}

customElements.define('user-detail', UserDetail);
