'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-ripple/paper-ripple.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import { SharedStyles, FabStyles } from '../../shared-styles.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';
import '../elements/user-detail.js';

class UsersView extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      FabStyles,
      css`
        :host {
          display: block;
          width: 100%;
          margin: auto;
          height: 100% !important;
          max-height: 100%;
          min-height: 100%;
        }

        @media only screen 
        and (max-device-width : 600px) {
          #selector {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #f5f5f5;
            height: 100%;
            max-height: 100%;
            min-height: 100%;
            overflow-y: scroll;
            z-index: 10;
          }
              
          #selector {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s linear 0.1s, opacity 0.1s linear;
          }
          
          #selector.active {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
          }
        }

        #selector {
          height: 100%;
          overflow-y: scroll;
        }

        .user-button {
          background-color: white;
          color: #333;
          margin-bottom: 10px;
          border: 1px solid #ccc;
          margin-left: 0;
        }

        .white {
          color: white !important;
        }

        .strike {
          text-decoration: line-through;
        }

        #container {
          display: flex;
          height: 100%;
          min-height: 100%;
          max-height: 100%;
          position: relative;
        }

        label {
          border-bottom: 1px solid #ccc;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
        }

        h3 {
          font-weight: 300;
          font-size: 30px;
          margin: 0.5em;
          color: white;
        }

        label {
          position: relative;
          display: flex;
          width: 100%;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          box-sizing: border-box;
        }
        
        select {
          font-size: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-appearance: none;
          appearance: none;
          border: none;
          height: 75px;
          width: 100%;
          background-color: transparent;
          box-sizing: content-box;
          position: relative;
          z-index: 1; 
        }

        #user-name {
          position: absolute;
          display: content;
        }

        #user {
          flex-grow: 1;
        }

        user-detail {
          border: 1px solid #ccc;
        }
      `
    ];
  }

  render() {
    return html`
      <div id="container">
        ${this.isAdmin ? html`
          <div id="selector">

            <h1>Techniciens</h1>
            
            ${this.users
              .filter(user => user.type === 'tech')
              .filter(user => user.active === true)
              .map(user => html`
                <paper-button 
                  user-id="${user._id}"
                  class="
                    user-button
                    ${this.user && this.user._id === user._id ? `${user.color}-bd` : ''}
                    ${user.active ? '' : 'strike'}" 
                  @click="${this.selectUser}"
                >${user.name}</paper-button>
                <br class="hide-mobile">
            `)}
      
            <h1>Commerciaux</h1>

            ${this.users
              .filter(user => user.type === 'sales')
              .filter(user => user.active === true)
              .map(user => html`
              <paper-button 
                user-id="${user._id}"
                class="
                  user-button
                  ${this.user && this.user._id === user._id ? `${user.color}-bd` : ''}
                  ${user.active ? '' : 'strike'}" 
                @click="${this.selectUser}"
              >${user.name}</paper-button>
              <br class="hide-mobile">
            `)}

            <h1>Administrateurs</h1>

            ${this.users
              .filter(user => user.type === 'admin')
              .filter(user => user.active === true)
              .map(user => html`
              <paper-button 
                user-id="${user._id}"
                class="
                  user-button
                  ${this.user && this.user._id === user._id ? `${user.color}-bd` : ''}
                  ${user.active ? '' : 'strike'}" 
                @click="${this.selectUser}"
              >${user.name}</paper-button>
              <br class="hide-mobile">
            `)}

          </div>
        ` : '' }

        <div id="user">
          <user-detail
            id="detail"
            .user="${this.user}"
            .users="${this.users}"
            @edit-intervention="${this._editIntervention}"
            @read-intervention="${this._readIntervention}"
          >
            ${this.user ? html`
              <label class="${this.user.color}" @click="${this.openSelector}">
                <paper-ripple class="show-mobile"></paper-ripple>
                <div id="user-name">
                  ${this.user.name}&nbsp;
                  <span class="show-mobile">${ALCIcons['keyboard-arrow-down']}</span>
                </div>
              </label>
            ` : '' }
          </user-detail>
        </div>
      </div>

      <button class="fab" @click="${this._openNewUser}" aria-label="new">
        ${ALCIcons['add']}
        <paper-ripple></paper-ripple>
      </button>
    `
  }

  static get properties() {
    return {
      isAdmin: { type: Boolean },
      users: { type: Array },
      user: { type: Object }
    }
  }

  constructor() {
    super();
    this.isAdmin = window.API_USER && window.API_USER.type === 'admin';
    this.users = [];
    this.user = {};
  }

  updated(props) {
    if(props.has('users'))
      return this._usersRefreshed();
  }

  openSelector() {
    if(window.outerWidth <= 600) {
      this.shadowRoot.getElementById('selector').classList.add('active')
    }
  }

  selectUser(e) {
    if(window.outerWidth <= 600) {
      this.shadowRoot.getElementById('selector').classList.remove('active')
    }

    if(!this.users) return;

    for (var i = 0; i < this.users.length; i++)
      if(this.users[i]._id === e.target.getAttribute('user-id'))
        this.user = this.users[i];
  }

  hideSelector() {
    if(window.outerWidth <= 600) {
      this.shadowRoot.getElementById('selector').classList.remove('active')
    } 
  }

  _usersRefreshed() {
    if(this.user._id || !this.users || !this.users.length)
      return;

    // sales can only see themselves
    if(!this.isAdmin) return this.user._id = window.API_USER._id;

    // most interesting info for admins is the first tech for now
    for(var user of this.users)
      if(user.active && user.type === 'tech') {
        this.user = user;
        break;
      }
  }

  _userSelected(id) {
    if(!this.users || !id) return;

    for (var i = 0; i < this.users.length; i++)
      if(this.users[i]._id === id)
        this.set('user', this.users[i]);
  }

  _openNewUser() {
    this.dispatchEvent(new CustomEvent('show-user-edit', {bubbles: true, composed: true}));
  }

  _editIntervention(e) {
    this.dispatchEvent(new CustomEvent('show-new-intervention', {bubbles: true, composed: true, 
      detail: {intervention: e.detail}
    }));
  }

  _readIntervention(e) {
    var target = e.target;
    this.dispatchEvent(new CustomEvent('show-report', {bubbles: true, composed: true, 
      detail: { id: target.getAttribute('intervention-id') }
    }));
  }
}

customElements.define('users-view', UsersView);
