'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import '../../node_modules/@polymer/paper-button/paper-button.js';
import './views/calendar-view.js';
import './views/intervention-view.js';
import './views/sav-view.js';
import { SharedStyles, LightboxStyles } from '../shared-styles.js';
import { ALCIcons } from '../shared-elements/alc-icons.js';

class TechApp extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      LightboxStyles,
      css`
        :host {
          display: block;
          width: 100vw;
          height: 100%; 
        }

        #container {
          height: calc(100% - 64px);
        }

        #container > * {
          height: 100%;
          min-height: 100%;
          -webkit-overflow-scrolling: touch;
        }

        .paper-font-display3 {
          text-transform: uppercase;
          font-size: 3vmin;
          font-weight: bold;
        }

        #header {
          background-color: #3A6FDF;
          color: white;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0 20px;
          height: 64px;
        }

        #header svg {
          padding-left: 10px;
        }

        .title {
          text-transform: uppercase;
          font-size: 5.5vmin;
          font-weight: bold;
          align-self: center;
        }
      `
    ];
  }

  render() {
    return html`
      <input id="lightbox" class="lightbox" type="checkbox" @click="${this._hidePhoto}">
      <label for="lightbox" class="lightbox-label">
        <img id="preview">
      </label>

      <div id="header">
        <span class="title" @click="${this.returnCalendar}">
          ALR Energy
        </span>
        
        ${this.currentIntervention ? html`
          <paper-button id="reload" @click="${this._cancel}">
            agenda
            ${ALCIcons['view-agenda']}
          </paper-button>
        ` : html`
          <paper-button id="logout" @click="${this.logout}">
            Déconnexion
            ${ALCIcons['exit']}
          </paper-button>
        `}
      </div>    

      <div id="container">
        ${!this.currentIntervention ? html`
          <calendar-view
            id="calendar"
            @intervention-selected="${this._interventionSelected}"
          ></calendar-view>
        ` : ''}

        ${this.interventionTemplate === 'contract' ? html`
          <intervention-view
            .intervention="${this.currentIntervention}"
            @finished-intervention="${this._finishIntervention}"
            @show-photo="${this._showPhoto}"
          ></intervention-view>
        ` : ''}

        ${this.interventionTemplate === 'sav' ? html`
          <sav-view
            .intervention="${this.currentIntervention}"
            @finished-intervention="${this._finishIntervention}"
            @show-photo="${this._showPhoto}"
          ></sav-view>
        ` : ''}
      </div>
    `;
  }

  static get properties() {
    return {
      active: { type: Boolean },
      token: { type: String },
      currentIntervention: { type: Object },
      interventionTemplate: { type: String }
    }
  }

  constructor() {
    super();
    window.history.pushState({}, null, '/');
    this.currentIntervention = null;
  }

  returnCalendar() {
    this.currentIntervention = null;
  }

  logout() {
    this.dispatchEvent(new CustomEvent('logout', {bubbles: true, composed: true}));
  }

  _cancel()  {
    if (window.confirm('Annuler l\'intervention en cours ?'))
      window.location.reload();
  }

  reset()  {
    this.currentIntervention = null;
    this.interventionTemplate = null;
  }

  _finishIntervention(e) {
    this.reset();
    const calendar = this.shadowRoot.getElementById('calendar')
    if(calendar) calendar.updateIntervention(e.detail);
  }

  _hidePhoto() {
    this.shadowRoot.getElementById('preview').src = null;
  }

  _showPhoto(e) {
    this.shadowRoot.getElementById('preview').src = e.detail;
    this.shadowRoot.getElementById('lightbox').checked = true;
  }

  _interventionSelected(e) {
    this.currentIntervention = e.detail;
    this.interventionTemplate = e.detail.template;
  }
}

customElements.define('tech-app', TechApp);
