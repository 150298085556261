'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';

class InterventionReportRef extends LitElement {
  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 !important;
      }

      .ref {
        border-left: 1px solid #ccc;
        padding: 6px 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        color: #aaa;
        text-transform: uppercase;
        text-align: left;
        display: inline-block;
      }

      .ref-part:first-of-type > .ref {
          border-left: 0;
      }

      .ref-value {
        padding: 6px 0;
        font-size: 16px;
        line-height: 1;
        display: inline-block;
        text-transform: uppercase;
      }

      .ref-part {
        width: 33%;
        display: flex;
        padding: 7px 0;
      }

      .part {
        padding: 13px;
      }
    `;
  }

  render() {
    return html`
      <div class="ref-part">
        <div class="ref">Marque</div> 
        <div class="ref-value">${this.hardware.brand}</div>
      </div>
      <div class="ref-part">
        <div class="ref">Modèle</div> 
        <div class="ref-value">${this.hardware.model}</div>
      </div>
      <div class="ref-part">
        <div class="ref">N° de série</div> 
        <div class="ref-value">${this.hardware.serialNumber}</div>
      </div>
    `;
  }

  static get properties() {
    return {
      hardware: { type: Object }
    }
  }

  constructor() {
    super();
    this.hardware = {};
  }
}

customElements.define('intervention-report-ref', InterventionReportRef);
