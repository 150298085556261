'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-input/paper-input.js';
import '../../../node_modules/@polymer/paper-input/paper-textarea.js';
import '../../../node_modules/@polymer/paper-checkbox/paper-checkbox.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import '../../shared-elements/contract-initial-hardware.js';
import '../../shared-elements/user-input.js';
import '../../shared-elements/client-info.js';
import '../../shared-elements/new-intervention-calendar.js';
import '../../shared-elements/new-intervention-time.js';
import '../../shared-elements/intervention-map.js';
import '../../shared-elements/intervention-programmed.js';
import { SharedStyles } from '../../shared-styles.js';
import { RippleStyles, RippleEffect } from '../../utils/ripple-effect.js';
import { DialogStyles, overlayStyleMap, dialogStyleMap } from '../../utils/dialog.js';
import * as Intervention from '../../api/api-interventions.js';
import * as Utils from '../../utils/utils.js';

class NewIntervention extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      DialogStyles,
      RippleStyles,
      css`
        :host {
          display: flex;
          justify-content: center;
        }

        .date-button {
          border-radius: 2px;
          color: #666;
          border: 1px solid #999;
          margin: 0.29em 0;
        }

        .date-button.iron-selected {
          border: 1px solid #2b78e4;
          color: #2b78e4;
        }

        #type {
          display: flex;
          flex-direction: row;
        }

        #type > paper-button {
          flex-grow: 1;
          flex-basis: 0;
          border: 1px solid #999;
          border-left: 0;
          margin: 0;
          color: #666;
          border-radius: 0;
        }

        #type > paper-button:first-child {
          border-left: 1px solid #999;
          border-radius: 3px 0 0 3px;
        }

        #type > paper-button:last-child {
          border-radius: 0 3px 3px 0;
        }

        #type > paper-button.iron-selected {
          color: #2b78e4;
        }

        #buttons {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 1em;
          padding-top: 20px;
          margin: 1.5em 0;
          box-sizing: border-box;
        }

        #buttons > button {
          margin: 0;
          padding: 10px;
          border-radius: 5px;
          text-transform: uppercase;
          text-align: center;
        }

        #cancel {
          border: 1px solid #ccc;
          background-color: white;
        }

        #submit {
          background-color: #3A6FDF;
          color: white;
          border: 0;
        }

        #submit[disabled] {
          background-color: #91a8da;
        }

        .spaced {
          margin: 1em 0 !important;
        }

        #error-message {
          color: #FE0006;
        }

        #calendar-container, #map-dates {
          display: grid;
          grid-template-columns: 7fr 3fr;
          grid-gap: 1em;
        }

        #update-title {
          /* accounts for the button extra height */
          padding: 19px 24px !important;
        }

        @media only screen 
        and (max-device-width : 600px) {
          #calendar-container {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
          }
        }

        intervention-map, intervention-programmed {
          height: 300px;
        }

        intervention-programmed {
          border-radius: 5px;
          border: 1px solid #ccc;
          box-sizing: border-box;
          overflow-y: auto;
        }

        #delete {
          color: #c62828;
          background-color: white;
          font-size: 15px;
          margin: 0 !important;
          padding: 5px;
          float: right;
          font-weight: 500;
        }

        paper-checkbox:not(:last-of-type) {
          margin-right: 50px;
        }

        #client-title {
          margin-top: 1.5em;
        }
      `
    ];
  }

  render() {
    return html`
      <div
        id="overlay"
        style="${overlayStyleMap(true)}"
        @click="${this.close}"
      ></div>

      <div
        id="dialog"
        slide-from-bottom
        style="${dialogStyleMap(true)}"
      >
        <h2 id="title">
          ${this._isNew ? html`
            Nouvelle intervention
          ` : html`
            Modifier intervention
            <paper-button id="delete" @click="${this._delete}">
              supprimer
            </paper-button>
          `}
        </h2>

        <div id="content">
          <h2 id="client-title">Client</h2>
          <client-info
            id="client"
            clientId="${this._client}"
            .client="${this.client}"
            .clients="${this.clients}"
            @client-changed="${this._clientChanged}"
          ></client-info>

          <h2 class="spaced">Technicien</h2>
          <user-input
            userId="${this._tech}"
            .users="${this.techs}"
            @user-changed="${this._techChanged}"
          ></user-input>

          <div id="calendar-container">
            <div id="date">
              <h2 class="spaced">Date</h2>
              
              <new-intervention-calendar
                .date="${this.day}"
                .month="${this._month}"
                .calendar="${this.calendar}"
                .tech="${this._tech}"
                @previous-month="${this._previousMonth}"
                @next-month="${this._nextMonth}"
                @date-changed="${this._dateChanged}"
              ></new-intervention-calendar>
            </div>
            
            <div id="time">
              <h2 class="spaced">Heure</h2>
              
              <new-intervention-time
                .hours="${this._hours}"
                .minutes="${this._minutes}"
                @time-changed="${this._timeChanged}"
              ></new-intervention-time>
            </div>
          </div>

          <div id="map-dates">
            
            <div id="map-container">
              <h2 class="spaced">Carte</h2>
              <intervention-map 
                id="map" 
                interventionId="${(this.intervention || {})._id}"
                .lat="${this.client && this.client.address ? this.client.address.lat : ''}" 
                .long="${this.client && this.client.address ? this.client.address.long : ''}"
                .interventions="${this._dateInterventions.coordinates}" 
              ></intervention-map>
            </div>

            <div id="programmed-container">
              <h2 class="spaced">Interventions</h2>
              <intervention-programmed
                .interventions="${this._dateInterventions.detail}"
              ></intervention-programmed>
            </div>
          </div>

          <h2 class="spaced">Type d'intervention</h2>
          <div id="type">
            ${Object.keys(Utils.interventionTypes).map(type => html`
              <paper-button
                type="${type}"
                class="${this.type === type ? 'iron-selected' : ''}"
                @click="${this._selectType}"
              >${Utils.interventionTypeName(type)}</paper-button>
            `)}
          </div>

          ${this.type === 'contract' ? html`
            <h2 class="spaced">Matériel</h2>
            <contract-initial-hardware
              .initialHardware="${this._initialHardware}"
              @initial-hardware-changed="${this._initialHardwareChanged}"
            ></contract-initial-hardware>
            <i>Les prix sont fixés automatiquement pour les contrats d'intervention</i>
          
          ` : html`
          
            <paper-input label="Prix" type="number" value="${this.price}"
              min="0" @value-changed="${this._priceChanged}"
            >
              <div suffix slot="suffix">€</div>
            </paper-input>

            <div id="checkboxes">
              <paper-checkbox
                ?disabled="${this.allowPriceEdit}"
                ?checked="${this.hidePrice}"
                @change="${this._hidePriceChanged}"
              >Prix invisible au technicien</paper-checkbox>
              <paper-checkbox
                ?disabled="${this.hidePrice}"
                ?checked="${this.allowPriceEdit}"
                @change="${this._allowPriceEditChanged}"
              >Prix fixable pendant l'intervention</paper-checkbox>
            </div>
          `}

          <paper-textarea
            label="Notes"
            .value="${this.notes}"
            rows="3"
            @value-changed="${this._notesChanged}"
          ></paper-textarea>
          
          <div id="error-message">${this._error}</div>

          <div id="buttons">
            <button id="cancel" @click="${this.close}" ripple @mousedown="${RippleEffect}">
              annuler
            </button>
            <button id="submit" @click="${this._submit}" ripple @mousedown="${RippleEffect}">
              ${this._isNew ? 'créer' : 'modifier'}
            </button>
          </div>
        </div>
      </div>
    `;
  }

// todo fix hours and stuck sales
  static get properties() {
    return {
      _isNew: { type: Boolean },
      _client: { type: String },
      day: { type: Date },
      hours: { type: Number },
      minutes: { type: Number },
      _hours: { type: Number },
      _minutes: { type: Number },
      notes: { type: String },
      price: { type: Number },
      hidePrice: { type: Boolean },
      allowPriceEdit: { type: Boolean },
      techs: { type: Array },
      address: { type: Object },
      type: { type: String },
      _initialHardware: { type: Array },
      initialHardware: { type: Array },
      calendar: { type: Array },
      _month: { type: Date },
      interventions: { type: Array },
      date: { type: Date },
      _dateInterventions: { type: Object },
      clients: { type: Array },
      intervention: { type: Object },
      _error: { type: String },
      _tech: { type: String }
    }
  }

  constructor() {
    super();
    this.type = 'contract';
    this.techs = [];
    this.address = {};
    this._month = new Date(new Date().setDate(5));
    this.interventions = [];
    this.clients = [];
    this.intervention = {};
    this._dateInterventions = {};
    this.calendar = [];
    this._hours = 0;
    this._minutes = 0;
  }

  updated(props) {
    if(props.has('intervention'))
      this._init();
    if(props.has('type'))
      this._typeChanged();
    if(props.has('calendar') || props.has('day'))
      this._dateInterventions = this._computeDateInterventions(this.calendar, this.day);
    if(props.has('day') || props.has('hours') || props.has('minutes'))
        this._computeDate(this.day, this.hours, this.minutes);
    if(props.has('_client') || props.has('clients'))
        this._updateClientPin(this._client, this.clients);
    if(props.has('_month') || props.has('_tech'))
        this._updateCalendar(this._month, this._tech);
  }


  connectedCallback() {
    super.connectedCallback();
    this._reset();
  }

  async _updateCalendar(month = new Date(), tech = null) {
    const { from, to } = Utils.dateRange(month, 'month', true);

    if(!tech) 
      return this.calendar = Utils.interventionsToCalendar([], from, to, true, tech);

    let res = await Intervention.get(null, { from, to, tech });
    this.calendar = Utils.interventionsToCalendar(res, from, to, true, tech);
  }

  // fills in fields for intervention updates
  _init() {
    if(!this.intervention || !Object.keys(this.intervention).length)
      return this._reset();
    
    this._isNew = false;
    
    var date = new Date(this.intervention.date);
    this._month = new Date(date);
    this.date = this.intervention.date;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    this.day = new Date(date.setHours(0,0,0,0)).toString();

    // Temporary workaround to solve a race condition
    setTimeout(() => {
    }, 500);
    
    
    this.client = this.intervention._client;
    this._client = this.intervention._client._id;
    this.address = this.intervention._client.address;
    this._tech = this.intervention._tech;
    this.type = this.intervention.type;
    this.price = this.intervention.price;
    this.hidePrice = this.intervention.hidePrice;
    this.allowPriceEdit = this.intervention.allowPriceEdit;
    this.notes = this.intervention.notes;
    this._computeDate(this.day, this.hours, this.minutes);
    this._initialHardware = this.intervention.initialHardware;
    this._hours = hours;
    this._minutes = minutes;
  }

  _reset() {
    this._isNew = true;
    this._client = '';
    this.day = null;
    this.hours = 0;
    this.minutes = 0;
    this.price = null;
    this.hidePrice = false;
    this.allowPriceEdit = false;
    this.notes = null;
    this.address = null;
    this.type = null;
    this.initialHardware = null;
    this._month = new Date();
    this.date = null;
    this._tech = '';
    this._error = undefined;
  }

  _techChanged(e) {
    this._tech = e.detail;
  }

  _initialHardwareChanged(e) {
    this.initialHardware = e.detail;
  }

  _updateClientPin(id, clients) {
    if(!id || !clients) {
      this.shadowRoot.getElementById('map').lat = undefined;
      this.shadowRoot.getElementById('map').long = undefined;
      return;
    }

    for (var i = 0; i < clients.length; i++) {
      if(clients[i]._id === id){
        this.shadowRoot.getElementById('map').lat = clients[i].address.lat;
        this.shadowRoot.getElementById('map').long = clients[i].address.long;
      }
    }
  }

  _toISODate(d) {
    return d.toISOString().substr(0, 10);
  }

  _typeChanged() {
    if(typeof this.type === undefined) return;
    this.initialHardware = [];
  }

  _displayError(e) {
    if(!e) return;
    this._error = e.detail || e;
  }

  _computeIsContract(type) {
    return type === 'contract';
  }

  _computeDate(day, hours = 0, minutes = 0) {
    if(!day) return;

    this.date = new Date(new Date(day).setHours(hours, minutes, 0, 0));
  }

  async _submit() {
    this._computeDate(this.day, this.hours, this.minutes);

    var intervention = {
      _tech: this._tech,
      _client: this._client,
      date: this.date,
      notes: this.notes,
      initialHardware: this.type === 'contract' ? this.initialHardware : undefined,
      type: this.type,
      price: this.price,
      hidePrice: this.hidePrice,
      allowPriceEdit: this.allowPriceEdit
    };

    if(this._isNew) {
      Intervention.create(intervention)
      .then(() => { this._editSuccess() })
      .catch(e => { this._displayError(e) })
    } else {
      Intervention.update(this.intervention._id, intervention)
      .then(() => { this._editSuccess() })
      .catch(e => { this._displayError(e) })
    }
  }

  _computeDateInterventions() {
    if(!this._tech) return {};
    var coordinates = [];
    var detail;

    for (var i = 0; i < this.calendar.length; i++) {
      if(new Date(this.calendar[i].date).toDateString() === new Date(this.date).toDateString()){
        detail = this.calendar[i].interventions;

        coordinates = this.calendar[i].interventions.map(intervention => {
          return {
            _id: intervention._id,
            lat: intervention._client.address.lat, 
            long: intervention._client.address.long
          };
        });
      }
    }

    return {
      coordinates: coordinates,
      detail: detail
    };
  }

  // todo debounce
  close(e) {
    if(!e) return;
    this.shadowRoot.getElementById('content').scrollTop = 0;
    this.date = null;
    this.opened = false;
    this._reset();
    this.dispatchEvent(new CustomEvent('close-intervention-edit', {bubbles: true, composed: true}));
  }

  updateClient(id) {
    if(id && this.shadowRoot && this.shadowRoot.getElementById('client'))
      this.shadowRoot.getElementById('client').updateClient(id);
  }

  _editSuccess() {
    this.dispatchEvent(new CustomEvent('edit-success', {bubbles: true, composed: true}));
  }

  _previousMonth() {
    var d = new Date(this._month);
    this._month = new Date(d.setMonth(d.getMonth() - 1));
  }

  _nextMonth() {
    var d = new Date(this._month);
    this._month = new Date(d.setMonth(d.getMonth() + 1));
  }

  _dateChanged(e) {
    this.day = new Date(e.detail);
  }

  async _delete() {
    Intervention.remove(this.intervention._id)
    .then(() => { this._editSuccess() })
    .catch(e => { this._displayError(e) })
  }

  _clientChanged(e) {
    this.client = e.detail.client;
    this._client = e.detail.clientId;
  }

  _timeChanged(e) {
    this.hours = e.detail.hours;
    this.minutes = e.detail.minutes;
  }

  _selectType(e) { this.type = e.currentTarget.getAttribute('type'); }
  _priceChanged(e) { this.price = e.detail.value; }
  _hidePriceChanged(e) { this.hidePrice = e.currentTarget.checked }
  _allowPriceEditChanged(e) { this.allowPriceEdit = e.currentTarget.checked }
  _notesChanged(e) { this.notes = e.detail.value }
}

customElements.define('new-intervention', NewIntervention);
