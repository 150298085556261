'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import '../../node_modules/@polymer/paper-input/paper-input.js';
import { SharedStyles } from '../shared-styles.js';

class NewInterventionTime extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
        }

        #wrapper {
          border: 1px solid #ccc;
          border-radius: 5px;
        }

        #hours {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        #minutes > div {
          width: 100%;
          border-left: 0;
        }

        #container, header {
          display: grid;
          grid-template-columns: 2fr 1fr;
          text-align: center;
        }

        #hours > div, #minutes > div {
          margin: 0;
          border-radius: 0;
          border-bottom: 1px solid #eee;
          border-right: 1px solid #eee;
          flex-grow: 1;
          color: #333;
          text-align: left;
          margin: 0;
          padding: 0;
          text-align: center;
          cursor: pointer;
        }

        .iron-selected {
          color: white !important;
          background-color: #2b78e4 !important;
        }

        header {
          font-weight: 500;
          border-bottom: 1px solid #ccc;
          height: 23px;
        }

        #mn {
          border-left: 1px solid #ccc;
        }
      `
    ];
  }

  renderHours() {
    return [...new Array(12)].map((hour, index) => html`
      <div 
        key="${index}"
        class="${this.toDoubleDigit(this.hours) === this.toDoubleDigit(index) ? 'iron-selected' : ''}"
        @click="${this._hoursChanged}"
      >
        ${this.toDoubleDigit(index)}
      </div>

      <div
        key="${index + 12}"
        class="${this.toDoubleDigit(this.hours) === this.toDoubleDigit(index + 12) ? 'iron-selected' : ''}"
        @click="${this._hoursChanged}"
      >
        ${this.toDoubleDigit(index + 12)}
      </div>
    `);
  }

  renderMinutes() {
    return [...new Array(12)].map((minute, index) => html`
      <div
        key="${index * 5}"
        class="${this.toDoubleDigit(this.minutes) === this.toDoubleDigit(index * 5) ? 'iron-selected' : ''}"
        @click="${this._minutesChanged}"
      >
        ${this.toDoubleDigit(index * 5)}
      </div>
    `);
  }

  render() {
    return html`
      <div id="wrapper" class="hide-mobile">
        
        <header>
          <div id="hr">HEURES</div> 
          <div id="mn">MIN</div>
        </header>

        <div id="container">
          <div id="hours">${this.renderHours()}</div>
          <div id="minutes">${this.renderMinutes()}</div>
        </div>
      </div>

      <paper-input
        class="show-mobile"
        label="Heure"
        type="time"
        step="300"
        .value="${this._time}"
        @value-changed="${this._nativeChanged}"
      ></paper-input>
    `;
  }

  static get properties() {
    return {
      hours: { type: Number },
      minutes: { type: Number },
      _time: { type: String },
    }
  }

  constructor() {
    super();
    this.hours = 0;
    this.minutes = 0;
    this._time = '00:00';
  }

  updated(props) {
    if(props.has('hours') || props.has('minutes')) {
      this._updateNativeTime();
      this._notify();
    }
  }

  _hoursChanged(e) { this.hours = e.target.getAttribute('key'); }
  _minutesChanged(e) { this.minutes = e.target.getAttribute('key'); }

  _notify() {
    this.dispatchEvent(new CustomEvent('time-changed', {
      composed: true, bubbles: true, detail: { minutes: parseInt(this.minutes), hours: parseInt(this.hours) }
    })); 
  }

  toDoubleDigit(number) {
    return number.toString().padStart(2, '0');
  }

  _updateNativeTime() {
    this._time = `${this.toDoubleDigit(this.hours)}:${this.toDoubleDigit(this.minutes)}`;
  }

  _nativeChanged(e) {
    if(!e.detail.value) return;

    const [hours, minutes] = e.detail.value.split(':');

    if(minutes % 5) {
      let roundedMinutes = (parseInt(minutes / 5) * 5 + 5).toString();
      this._time = `${hours}:${roundedMinutes.length === 2 ? roundedMinutes : `0${roundedMinutes}`}`;
      return this._nativeChanged({detail: {value: this._time}});
    }

    this.hours = parseInt(hours);
    this.minutes = parseInt(minutes);
  }
}

customElements.define('new-intervention-time', NewInterventionTime);
