'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import '../../node_modules/@polymer/paper-input/paper-input.js';
import '../../node_modules/@polymer/paper-input/paper-textarea.js';
import '../../node_modules/@polymer/paper-button/paper-button.js';
import './address-search.js';
import './user-input.js';
import './google-map.js';
import { SharedStyles } from '../shared-styles.js';
import { DialogStyles, overlayStyleMap, dialogStyleMap } from '../utils/dialog.js';
import * as Client from '../api/api-clients.js';

class ClientEdit extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      DialogStyles,
      css`
        :host {
          max-width: 100%;
          display: flex;
          justify-content: center;
        }

        paper-input {
          display: block;
        }

        #map {
          display: block;
          width: 100%;
          height: 300px;
        }

        @media only screen 
        and (min-device-width : 650px) {
          #dialog {
            width: 650px;
          }
        }

        #buttons {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 1em;
          padding-top: 20px;
          margin: 1.5em 0;
        }

        #buttons > paper-button {
          margin: 0;
        }

        #cancel {
          border: 1px solid #ccc;
        }

        #submit {
          background-color: #3A6FDF;
          color: white;
        }

        #submit[disabled] {
          background-color: #91a8da;
        }

        #type {
          display: flex;
          flex-direction: row;
        }

        #type > paper-button {
          flex-grow: 1;
          flex-basis: 0;
          border: 1px solid #999;
          border-left: 0;
          margin: 0;
          color: #666;
          border-radius: 0;
        }

        #type > paper-button:first-child {
          border-left: 1px solid #999;
          border-radius: 3px 0 0 3px;
        }

        #type > paper-button:last-child {
          border-radius: 0 3px 3px 0;
        }

        #type > paper-button.iron-selected {
          color: #2b78e4;
        }

        .spaced {
          margin: 1em 0 !important;
        }

        .notes {
          margin-bottom: 0 !important;
        }

        #delete {
          color: #c62828;
          background-color: white;
          font-size: 15px;
          margin: 0 !important;
          padding: 5px;
          float: right;
          font-weight: 500;
        }

        #error {
          color: #FE0006;
        }
      `
    ];
  }
  
  render() {
    return html`
      <div
        id="overlay"
        style="${overlayStyleMap(true)}"
        @click="${this.close}"
      ></div>

      <div
        id="dialog"
        slide-from-bottom
        style="${dialogStyleMap(true)}"
      >

        <h2 id="title">${this.client && this.client._id
          ? html`${this.client.name}
            <paper-button id="delete" @click="${this._delete}">
              supprimer
            </paper-button>` 
          : 'Nouveau client'
        }</h2>

        <div id="content">
        
          ${!!this.sales.filter(s => s.active).length && this.user.type === 'admin' ? html`
            <h2 class="spaced">Commercial</h2>
            <user-input
              .users="${this.sales}"
              userId="${this.client && this.client._sales ? this.client._sales : ''}"
              @user-changed="${this._salesUpdated}"
            ></user-input>
          ` : ''}

          <h2 class="spaced">Coordonnées</h2>

          <div id="type">
            <paper-button
              type="individual"
              class="${this._client.type === 'individual' ? 'iron-selected' : ''}"
              @click="${this._typeChanged}"
            >Particulier</paper-button>
            <paper-button
              type="company"
              class="${this._client.type === 'company' ? 'iron-selected' : ''}"
              @click="${this._typeChanged}"
            >Société</paper-button>
            <paper-button
              type="sci"
              class="${this._client.type === 'sci' ? 'iron-selected' : ''}"
              @click="${this._typeChanged}"
            >SCI</paper-button>
          </div>

          <paper-input
            label="Nom"
            type="text"
            .value="${this.client && this.client.name ? this.client.name : ''}"
            @value-changed="${this._nameChanged}"
            required
          ></paper-input>
          <paper-input
            label="Téléphone 1"
            type="text"
            .value="${this.client && this.client.phone ? this.client.phone : ''}"
            @value-changed="${this._phoneChanged}"
            required
          ></paper-input>
          <paper-input
            label="Téléphone 2"
            type="text"
            .value="${this.client && this.client.phone2 ? this.client.phone2 : ''}"
            @value-changed="${this._phone2Changed}"
          ></paper-input>
          <paper-input
            label="Téléphone 3"
            type="text"
            .value="${this.client && this.client.phone3 ? this.client.phone3 : ''}"
            @value-changed="${this._phone3Changed}"
          ></paper-input>
          <paper-input
            label="Adresse mail"
            type="text"
            .value="${this.client && this.client.mail ? this.client.mail : ''}"
            @value-changed="${this._mailChanged}"
          ></paper-input>
          
          ${this.client && this.client.address ? html`
            Adresse<br>
            ${this.client.address.street}<br>
            ${this.client.address.postalCode} ${this.client.address.city}
          ` : ''}

          <address-search
            .address="${this.client && this.client.address ? this.client.address : ''}"
            @address-changed="${this._addressUpdated}"
          ></address-search>

          <google-map
            id="map"
            latitude="48.8557182"
            longitude="2.3597"
            zoom="11"
            disable-default-ui="true"
            delay-start="1000"
            markers=${this.client && this.client.address ? this._updateMarker() : "[]"}
          ></google-map>
          
          <h2 class="spaced notes">Notes</h2>

          <paper-textarea
            no-label-float
            rows="3"
            .value="${this.client && this.client.notes ? this.client.notes : ''}"
            @value-changed="${this._notesChanged}"
          ></paper-textarea>

          <div id="error">${this._error}</div>

        
          <div id="buttons">
            <paper-button id="cancel" @click="${this.close}">annuler</paper-button>
            <paper-button id="submit" ?disabled="${!this._complete}" @click="${this._submit}">
              ${(this.client || {})._id ? 'modifier' : 'créer'}
            </paper-button>
          </div>
        </div>
        
      </div>
    `;
  }

  static get properties() {
    return {
      _error: { type: String },
      client: { type: Object },
      sales: { type: Array },
      _client: { type: Object },
      _complete: { type: Boolean },
      user: { type: Object }
    }
  }

  constructor() {
    super();
    this.user = {};
    this.sales = [];
    this.client = {};
    this._resetClient();
  }

  updated(props) {
    if(props.has('client'))
      this._init();
    if(props.has('_client'))
      this._complete = Client.isValid(this._client);
  }

  _resetClient() {
    this._client = {
      name: null,
      phone: null,
      phone2: null,
      phone3: null,
      type: 'individual',
      mail: null,
      address: {
        street: null,
        city: null,
        postalCode: null,
        lat: null,
        long: null
      },
      notes: null
    };

    this._error = '';
  }

  _init() { 
    let client = (this.client || {
      type: 'individual',
    });
    
    client._sales = (this.client && this.client._sales) ? this.client._sales._id : null;

    this._client = {...client};
    this._updateMarker();
  }

  async _delete() {
    Client.remove(this._client._id)
    .then(() => { this._submitSuccess() })
    .catch(e => { this._displayError(e) })
  }

  _submit() {
    if(this.client && this.client._id)
      Client.update(this.client._id, this._client)
      .then(res => { this._submitSuccess(res) })
      .catch(e => { this._displayError(e) });
    else
      Client.create(this._client)
      .then(res => { this._submitSuccess(res) })
      .catch(e => { this._displayError(e) });
  }

  close() {
    this._resetClient();
    this.dispatchEvent(new CustomEvent('client-edit-close', {bubbles: true, composed: true}));
  }

  _displayError(e) {
    this._error = e;
  }

  _submitSuccess(res = {}) {
    this._resetClient();
    this.dispatchEvent(new CustomEvent('client-edit-success', {bubbles: true, composed: true, 
      detail: res._id
    }));
  }

  _updateMarker() {
    const markers = (this._client.address.lat && this._client.address.long) ? 
      (
        [{
          lat: this._client.address.lat,
          lng: this._client.address.long,
          icon: {
            url: '../../../images/blue-dot.png',
            size: [27, 43],
            scaledSize: [27, 43]
          }
        }]
      ) : [];

    return JSON.stringify(markers);
  }

  _salesUpdated(e) {
    this._client = {...this._client, _sales: e.detail};
  }
  _addressUpdated(e) {
    if (!e.detail || !e.detail.lat || !e.detail.long) return;
    this._client = {...this._client, address: e.detail};
    this.shadowRoot.getElementById('map').setAttribute('markers', this._updateMarker());
  }
  _typeChanged(e) { this._client = {...this._client, type: e.currentTarget.getAttribute('type') }}
  _nameChanged(e) { this._client = {...this._client, name: e.detail.value }}
  _phoneChanged(e) { this._client = {...this._client, phone: e.detail.value }}
  _phone2Changed(e) { this._client = {...this._client, phone2: e.detail.value }}
  _phone3Changed(e) { this._client = {...this._client, phone3: e.detail.value }}
  _mailChanged(e) { this._client = {...this._client, mail: e.detail.value }}
  _notesChanged(e) { this._client = {...this._client, notes: e.detail.value }}
}

customElements.define('client-edit', ClientEdit);
