'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import './google-place-autocomplete.js';

class AddressSearch extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
      }

      google-place-autocomplete {
        width: 100%;
      }
    `;
  }

  render() {
    return html`
      <google-place-autocomplete 
        id="autocomplete"
        label="Adresse" 
        search-type="address"
        search-country-code="fr"
        hide-icon
        minimize-api
        @place-changed="${this._placeSelected}"
      ></google-place-autocomplete>
`;
  }

  static get properties() {
    return {
      val: { type: String },
      place: { type: Object },
      address: { type: Object }
    }
  }

  constructor() {
    super();
    this.address = {
      street: null,
      city: null,
      postalCode: null,
      lat: null,
      long: null
    };
  }

  updated(props) {
    if(props.has('address') && (!this.address || !this.address.street))
      this._clearInput()
  }

  _clearInput() {
    if(!this.shadowRoot)
      return;

    this.shadowRoot.getElementById('autocomplete').clearLocation();
  }

  _placeChanged(address) {
    this.dispatchEvent(new CustomEvent('address-changed', {
      bubbles: true, composed: true, detail: address
    }))
  }

  _placeSelected(e) {
    if(e.type.includes('place'))
      this.place = e.detail;

    if(!this.place) return;

    if(!this.place || 
      !this.place.basic || 
      (!this.place.basic.address && !this.place.basic.name) ||
      !this.place.basic.postalCode || 
      !this.place.basic.city ||
      !['FR', 'BE'].includes(this.place.basic.countryCode)){
      this.address = {};
      this._placeChanged(this.address);
      return;
    }
    
    this.address = {
      street: this.place.basic.address || this.place.basic.name,
      postalCode: this.place.basic.postalCode,
      city: this.place.basic.city,
      lat: this.place.latLng.lat,
      long: this.place.latLng.lng,
      country: this.place.basic.countryCode
    };
    this._placeChanged(this.address);
  }
}

customElements.define('address-search', AddressSearch);
