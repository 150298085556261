'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import '../../node_modules/@polymer/paper-button/paper-button.js';
import '../../node_modules/@polymer/paper-ripple/paper-ripple.js';

class ButtonGroup extends LitElement {
  static get styles() {
    return css`
      :host {
        display: inline-block;
      }

      #group {
        display: table;
        border-collapse: collapse;
      }

      .type {
        cursor: pointer;
        border: 1px solid #565656;
        display: inline;
        padding: 5px;
        margin: 0;
        text-transform: uppercase;
        font-size: 15px;
        color: #666;
        width: 120px;
        text-align: center;
        display: table-cell;
        position: relative;
      }

      .type:not(:first-child) {
        border-left: 0;
      }

      .iron-selected {
        color: #2b78e4;
      }

      paper-button {
        border-radius: 0;
      }
    `
  }

  render() {
    return html`
      <div id="group">
        ${(this.options || []).map(option => html`
          <paper-button
            id="${option.key}"
            class="type ink ${this.selected === option.key ? 'iron-selected' : ''}"
            @click="${this._select}"
          >
            <paper-ripple></paper-ripple>
            ${option.name}
          </paper-button>
        `)}
      </div>
    `;
  }

  static get properties() {
    return {
      options: { type: Array },
      selected: { type: String },
    }
  }

  constructor() {
    super();
    this.options = [];
  }

  _select(e) {
    this.selected = e.currentTarget.id;
    this.dispatchEvent(new CustomEvent('selected-changed', {
      bubbles: true, composed: true, detail: { value: e.currentTarget.id }
    }))
  }
}

customElements.define('button-group', ButtonGroup);
