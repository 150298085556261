'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-ripple/paper-ripple.js';
import { SharedStyles, FabStyles } from '../../shared-styles.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';

class SettingsView extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      FabStyles,
      css`
        :host {
          display: block;
          max-width: 1100px;
          margin: auto;
          padding-top: 50px;
          overflow-y: scroll;
        }

        @media only screen
        and (min-device-width : 600px) {
          #container {
            display: flex;
            flex-direction: row;
            gap: 50px;
          }
        }

        .strike {
          text-decoration: line-through;
        }
      `
    ];
  }

  render() {
    return html`
      <div id="container">
        <section>
          <h1>Administrateurs</h1>
          ${this.users
            .filter(user => user.type === 'admin')
            .map(user => html`
              <span class="${user.active ? '' : 'strike'}">${user.name}</span>&nbsp;
              <button user-id="${user._id}" @click="${this._edit}">Modifier</button>
              <br>
          `)}
        </section>

        <section>
          <h1>Commerciaux</h1>
          ${this.users
            .filter(user => user.type === 'sales')
            .map(user => html`
              <span class="${user.active ? '' : 'strike'}">${user.name}</span>&nbsp;
              <button user-id="${user._id}" @click="${this._edit}">Modifier</button>
              <br>
          `)}
        </section>

        <section>
          <h1>Techniciens</h1>
          ${this.users
            .filter(user => user.type === 'tech')
            .map(user => html`
              <span class="${user.active ? '' : 'strike'}">${user.name}</span>&nbsp;
              <button user-id="${user._id}" @click="${this._edit}">Modifier</button>
              <br>
          `)}
        </section>
      </div>

      <button class="fab" @click="${this._openNewUser}" aria-label="new">
        ${ALCIcons['add']}
        <paper-ripple></paper-ripple>
      </button>
  `;
  }

  static get properties() {
    return {
      users: { type: Array },
      _newUser: { type: Boolean }
    }
  }

  constructor() {
    super();
    this.users = [];
  }

  _reload() {
    window.location.reload();
  }

  _edit(e) {
    this.dispatchEvent(new CustomEvent('show-user-edit', {bubbles: true, composed: true, 
      detail: this.users.filter(user => user._id === e.target.getAttribute('user-id'))[0]
    }));
  }

  _disabled(active) {
    return active ? '' : 'strike';
  }

  _openNewUser() {
    this.dispatchEvent(new CustomEvent('show-user-edit', {bubbles: true, composed: true}));
  }
}

customElements.define('settings-view', SettingsView);
