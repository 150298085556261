'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import './google-map.js';

class InterventionMap extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
        height: 100%;
        width: 100%;
      }

      google-map {
        display: block;
        height: 100%;
        width: 100%;
      }
    `;
  }

  render() {
    return html`
      <google-map
        id="map"
        latitude="48.8557182"
        longitude="2.3597"
        zoom="11"
        disable-default-ui="true"
        delay-start="700"
      ></google-map>
    `;
  }

  static get properties() {
    return {
      map: { type: Object },
      lat: { type: Number },
      long: { type: Number },
      _markers: { type: String },
      interventions: { type: Array },
      interventionId: { type: String }
    }
  }

  constructor() {
    super();
  }

  updated(props) {
    if(props.has('lat') || props.has('long') || props.has('interventions'))
      this._setMarkers();
  }

  _setMarkers() {
    let markers = [];

    if(this.interventions)
      this.interventions
      .filter(i => i._id !== this.interventionId)
      .map(i => markers.push({lat: i.lat, lng: i.long}));
    
    if(this.lat && this.long)
      markers.push({
        lat: this.lat,
        lng: this.long,
        icon: {
          url: '../../../images/blue-dot.png',
          size: [27,43],
          scaledSize: [27,43]
        }
      });

    this.shadowRoot.getElementById('map').setAttribute('markers', JSON.stringify(markers));
  }
}

customElements.define('intervention-map', InterventionMap);
