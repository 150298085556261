'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import '../../node_modules/@polymer/paper-ripple/paper-ripple.js';
import '../admin/elements/agenda-users.js';
import '../admin/elements/interventions-calendar-detail.js';
import { webSocket } from '../utils/utils.js';
import { ALCIcons } from './alc-icons.js';
import { SharedStyles, FabStyles } from '../shared-styles.js';
import * as Intervention from '../api/api-interventions.js';
import * as Utils from '../utils/utils.js';

class WeekView extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      FabStyles,
      css`
        :host {
          display: block;
          height: 100% !important;
        }

        @media only screen
        and (max-device-width : 600px) {
          :host {
            padding: 0 !important;
          }
        }

        #intervention-calendar {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          height: 100%;
        }

        header {
          display: grid;
          grid-template-columns: auto auto auto;
          justify-content: space-between;
          border-radius: 5px;
        }

        #calendar {
          width: 100%;
          background-color: white;
          border: 1px solid #aaa;
          border-radius: 5px;
          margin-bottom: 10px;
          box-sizing: border-box;
        }

        h1 {
          font-size: 1.4em;
          font-weight: 300;
          text-transform: uppercase;
        }

        .date {
          text-align: center;
          padding: 7px;
          color: #999;
          width: 20px;
          float: left;
          flex-basis: 25%;
          border-right: 1px solid #eee;
          line-height: initial;
          height: 100%;
          box-sizing: border-box;
        }

        .iron-selected .date {
          color: white !important;
          background-color: #2b78e4;
        }

        .date-content {
          flex-basis: 75%;
          margin: 5px;
          padding: 5px;
          padding-left: 0;
          margin-top: 0;
          line-height: initial;
        }

        .now > .date {
          font-weight: 700;
          color: #8e352e;
        }

        #calendar-wrap {
          display: grid;
          grid-template-rows: auto min-content;
          width: 100%;
          flex-basis: 100%;
        }

        #days, #weekdays {
          display: grid;
          grid-template-columns: 200px 1fr 1fr 1fr 1fr 1fr 1fr;
        }

        #days {
          height: calc(100% - 95px);
        }

        #weekdays {
          height: 30px;
          text-align: center;
          background-color: #eee;
          border-top: 1px solid #aaa;
          color: #333;
          text-transform: uppercase;
        }

        #days > .calendar-item {
          box-shadow: inset 1px 1px 0 #aaa;
          display: initial;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          transition: background-color 0.5s ease;
          cursor: pointer;
          position: relative;
        }

        #days > .no-border {
          box-shadow: inset 0 1px 0 #aaa;
        }

        #weekdays > .calendar-item {
          border-left: 1px solid #aaa;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }

        #weekdays > .calendar-item:first-child {
          border-left: 0;
        }

        .calendar-item {
          margin: 0;
          padding: 0;
        }

        .previous > .date {
          color: #999 !important;
        }

        .calendar-nav {
          color: #2b78e4;
          margin: 0 10px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          cursor: pointer;
        }


        .circled-number {
          width: 20px;
          height: 20px;
          text-align: center;
          margin: 2px;
          padding: 3px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 5px;
        }

        .tech {
          color: white;
        }

        .sales {
          color: #333;
        }

        .hidden {
          display: none !important;
        }

         #detail-wrap {
          flex-basis: 30%;
          border: 1px solid #aaa;
          border-radius: 5px;
          background-color: white;
          padding: 0;
          box-sizing: border-box;
        }

        agenda-users {
          background-color: white;
          border: 1px solid #aaa;
          border-radius: 5px;
          padding: 10px;
          box-sizing: border-box;
        }

        @media only screen 
        and (max-device-width : 600px) {
          .hide-mobile {
            display: none !important;
          }

          header {
            border-bottom: 1px solid #ddd;
            border-radius: 0;
          }

          h1 {
            font-weight: 500;
          }

          #days {
            display: initial;
            height: 100%;
            overflow-y: scroll;
            scroll-behavior: smooth;
          }

          #calendar-wrap {
            grid-template-rows: 100%;
            flex-basis: 100%;
            margin: 0;
          }

          #calendar {
            display: grid;
            grid-template-rows: 65px calc(100% - 65px);
            margin-bottom: 0;
            border: 0;
          }

          .calendar-nav {
            padding: 0 20px;
            margin: 0;
            font-size: 1.3em;
          }

          interventions-calendar-detail {
            height: auto;
          }

          interventions-calendar-detail:last-of-type {
            padding-bottom: 30px;
          }
        }

        @media only screen 
        and (min-device-width : 600px) {
          .show-mobile {
            display: none !important;
          }
        }

        .paper-card {
          display: block;
        }

        .name {
          padding: 3px 20px;
          margin: 3px;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 500;
        }

        .summary-item, .user {
          padding: 2px;
          margin: 5px;
          display: block;
        }

        .summary-item.finished {
          border-left: 5px solid #579A22;
        }

        .summary-item.waiting {
          border-left: 5px solid #2b78e4;
        }
      `
    ];
  }
  render() {
    return html`
      <div id="intervention-calendar">
        <div id="calendar-wrap">
          <div id="calendar">
            <header>
              <div @click="${this._previousWeek}" class="calendar-nav" aria-label="previous">
                <span class="hide-mobile">précédent</span>
                <span class="show-mobile">${ALCIcons['previous']}</span>
              </div>
              
              <h1>${this._getWeek(this.week)}</h1>
              
              <div @click="${this._nextWeek}" class="calendar-nav" aria-label="next">
                <span class="hide-mobile">suivant</span>
                <span class="show-mobile">${ALCIcons['next']}</span>
              </div>
            </header>
            
            ${this._isMobile ? '' : html`
              <div id="weekdays">
                  <div class="calendar-item">Technicien</div>
                  <div class="calendar-item">Dimanche</div>
                  <div class="calendar-item">Lundi</div>
                  <div class="calendar-item">Mardi</div>
                  <div class="calendar-item">Mercredi</div>
                  <div class="calendar-item">Jeudi</div>
                  <div class="calendar-item">Vendredi</div>
                  <!-- <div class="calendar-item">Samedi</div> -->
              </div>
            `}

            <div id="days">
              ${this._filteredTechs.map(user => html`
                <div class="calendar-item"><div class="user">${user.name}</div></div>
                ${this._getCalendarPerTech(this.calendar, user._id).map(day => html`
                  <div class="calendar-item">
                    <div class="summary">
                      ${day.map(intervention => html`
                        <a 
                          class="summary-item ${intervention.finished ? 'finished' : 'waiting'}" 
                          intervention-id="${intervention._id}"
                          finished="${intervention.finished ? true : false}"
                          @click="${this._viewIntervention}"
                          title="${intervention._client.address.street}, ${intervention._client.address.postalCode} ${intervention._client.address.city} 
${intervention._client.phone}${intervention._client.phone2 ? '   ' + intervention._client.phone2 : ''}${intervention._client.phone3 ? '   ' + intervention._client.phone3 : ''}"
                        >
                            <b>${Utils.formatTime(intervention.date)}</b>
                            ${intervention._client.name}<br>
                            <span class="small-label">${Utils.interventionTypes[intervention.type]}</span><br>

                            <small>
                              ${intervention._client.address.postalCode} ${intervention._client.address.city}
                            </small>
                            ${intervention.notes ? html`
                              <div style ="background-color: #eee; border-radius: 5px; padding: 5px"><small>${intervention.notes}</small></div>
                            ` : ''}
                        </a>
                      `)}
                    </div>
                  </div>
                `)}
              `)}
            </div>
          </div>
        </div>
      </div>

      <button class="fab" @click="${this._openNewIntervention}" aria-label="new">
        ${ALCIcons['add']}
        <paper-ripple></paper-ripple>
      </button>
    `;
  }

  static get properties() {
    return {
      sales: { type: Array },
      techs: { type: Array },
      showWeekEnd: { type: Boolean },
      calendar: { type: Array },
      users: { type: Array },
      admins: { type: Array },
      _filteredTechs: { type: Array },
      date: { type: Date },
      week: { type: Date },
      _isMobile: { type: Boolean }
    }
  }

  constructor() {
    super();
    this._isMobile = window.outerWidth <= 600;
    this.showWeekEnd = false;
    this.calendar = [];
    this.techs = [];
    this.admins = [];
    this.sales = [];
    this.users = [];
    this._filteredTechs = [];
    this.date = new Date(new Date().setHours(0,0,0,0));
    this.week = new Date();
    window.onresize = () => this._isMobile = window.outerWidth <= 600;
    webSocket({ url: window.API_WEB_SOCKET, onMessage: this._socketMessage.bind(this) });
  }

  updated(props) {
    if(props.has('week'))
      this._updateCalendar();
    if(props.has('techs') || props.has('calendar'))
      this._filteredTechs = [...this.techs].filter(t => this._hasInterventions(this.calendar, t._id));
    if(props.has('_isMobile'))
      this._scrollToNow();
  }

  _hasInterventions(calendar, id) {
    for(let day of calendar)
      for(let intervention of day.interventions)
        if(intervention._tech === id)
          return true;

    return false;
  }

  async _updateCalendar() {
    const week = this.week;
    const { from, to } = Utils.dateRange(week, 'week', true);
    const res = await Intervention.get(null, { from, to });
    this.calendar = Utils.interventionsToCalendar(res, from, to, true);
  }

  _socketMessage({ data }) {
    if(data === 'refresh-interventions')
      this._updateCalendar();
  }

  _viewIntervention(e) {
    const target = e.target.closest('.summary-item');
    const id = target.getAttribute('intervention-id');
    const finished = target.getAttribute('finished');

    if(finished === 'true') {

      this.dispatchEvent(new CustomEvent('show-report', {bubbles: true, composed: true, 
        detail: { id: target.getAttribute('intervention-id') }
      }));
    }
    else {
      for(let day of this.calendar)
        for(let intervention of day.interventions)
          if(intervention._id === id)
            this.dispatchEvent(new CustomEvent('show-new-intervention', {bubbles: true, composed: true, 
              detail: {intervention: {...intervention, _client: {_id: intervention._client._id}}}
            }));
    }
  }

  _getTechsData(day) {
    let techs = {};
    for(let i of day.interventions) {
      if(!techs[i._tech])
        techs[i._tech] = { _id: i._tech, regions: new Set(), total: 0 };

      techs[i._tech].regions.add(i._client.address.region);
      techs[i._tech].total++;
      techs[i._tech].text = [...techs[i._tech].regions].sort().join(', ');
    }
    return Object.values(techs);
  }

  _getCalendarPerTech(calendar, id) {
    return calendar.map(day => 
      day.interventions.filter(intervention => intervention._tech === id)
    );
  }

  _setDate(e) {
    this.date = e.currentTarget.getAttribute('date');
  }

  _filterTechs(techs, calendar) {
    var filteredIndex = [];

    for(var day of calendar)
      for(let i of day.interventions)
        if(!filteredIndex.includes(i._tech))
          filteredIndex.push(i._tech);

    return techs.filter(tech => filteredIndex.includes(tech._id));
  }

  _getWeek(m) {
    const { from, to } = Utils.dateRange(m, 'week', true);
    const fromText = from.toLocaleString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const toText = to.toLocaleString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });


    return `${fromText} - ${toText}`;
  }

  _scrollToNow() {
    if(!this._isMobile) return;
    const now = this.shadowRoot.getElementById('now');
    // wait a little bit until the rendering is done
    if(now)
      setTimeout(() => now.scrollIntoView(), 100);
    else
      setTimeout(() => this._scrollToNow(), 100);
  }

  _day(date) {
    return new Date(date).getDate();
  }

  _getDayClass(day) {
    var c = 'calendar-item';

    if(day.now) c += ' now';
    if(day.previous || day.next) c += ' others';
    if(!this.showWeekEnd && day.date.getDay() === 0) c += ' no-border';
    // if(this.showWeekEnd && day.date.getDay() === 0) c += ' no-border';
    if(day.date.toString() === this.date) c += ' iron-selected';
    return c;
  }

  _editIntervention(e) {
    for(let day of this.calendar)
      for(let intervention of day.interventions)
        if(intervention._id === e.detail)
          return this.dispatchEvent(new CustomEvent('show-new-intervention', { 
            bubbles: true, composed: true, detail: { intervention, date: intervention.date }
          }));
    }

  _previousWeek() {
    const d = new Date(this.week);
    this.week = new Date(d.setDate(d.getDate() - 7));
  }

  _nextWeek() {
    const d = new Date(this.week);
    this.week = new Date(d.setDate(d.getDate() + 7));
  }

  _dayKey(d){   
    return new Date(d).toString();
  }

  _getColor(user, users, classes) {
    for (var i = 0; i < users.length; i++) {
      if(users[i]._id === user._id) 
        return classes + ' ' + users[i].color;
    }

    return 'hidden';
  }

  _computeCurrent(calendar, date) {
    if(!Array.isArray(calendar)) return [];
    let day = calendar.filter(d => new Date(d.date).toDateString() === new Date(date).toDateString())[0];
    return day ? day.interventions : [];
  }

  _filterDays(day) {
    if(window.outerWidth > 600)
      return (this.showWeekEnd || ![6].includes(day.date.getDay()));

    return (!!day.interventions.length && day.date.getMonth() === new Date(this.week).getMonth());
  }

  _openNewIntervention() {
    this.dispatchEvent(new CustomEvent('show-new-intervention', {bubbles: true, composed: true}));
  }
}

customElements.define('week-view', WeekView);
