'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import { ifDefined } from '../../node_modules/lit-html/directives/if-defined';
import '../admin/elements/grade-stars.js';

class InterventionReportObs extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
      }

      #signatures {
        display: flex;
        justify-content: space-between;
      }

      .sign {
        width: 45%;
        margin-right: 20px;
      }

      img {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
      }

      #grades {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .grade {
        text-align: center;
      }

      h2 {
        color: white;
        padding: 10px;
        background-color: #333;
      }

      h3 {
        font-size: 20px;
      }
    `;
  }

  render() {
    return html`
      ${this.observations.techNotes ? html`
        <h3>Observations technicien</h3>
        ${this.observations.techNotes}
      ` : ''}

      ${this.observations.clientNotes ? html`
        <h3>Observations client</h3>
        ${this.observations.clientNotes}
      ` : ''}

      ${
        this.observations.grades && 
        // Checks that at least one of the grades is filled
        Object.keys(this.observations.grades)
          .filter(g => !!this.observations.grades[g]).length ? 
          html`
            <h3>Notes</h3>

            <div id="grades">
              <div class="grade">
                <span class="title">Qualité du travail</span><br>
                <grade-stars grade="${this.observations.grades.quality}"></grade-stars>
              </div>
              
              <div class="grade">
                <span class="title">Durée du travail</span><br>
                <grade-stars grade="${this.observations.grades.duration}"></grade-stars>
              </div>
              
              <div class="grade">
                <span class="title">Propreté du travail</span><br>
                <grade-stars grade="${this.observations.grades.cleanliness}"></grade-stars>
              </div>
              
              <div class="grade">
                <span class="title">Respect du client</span><br>
                <grade-stars grade="${this.observations.grades.respect}"></grade-stars>
              </div>
            </div>
      ` : ''}

      <h3>Signatures</h3>
      <div id="signatures">
        <div class="sign">
          <div>Client</div>
          <img src="${ifDefined(this._getImage(this.signatures.client))}" decoding="async">
        </div>
        <div class="sign">
          <div>Technicien</div>
          <img src="${ifDefined(this._getImage(this.signatures.technician))}" decoding="async">
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      intervention: { type: Object },
      observations: { type: Object },
      signatures: { type: Object }
    }
  }

  constructor() {
    super();
    this.intervention = {};
    this.observations = {};
    this.signatures = {};
  }

  updated(props) {
    if(props.has('intervention')) {
      this.observations = this.intervention.observations || {};
      this.signatures = this.intervention.signatures || {};
    }
  }

  _getImage(image) {
    if(image) return `${window.IMAGE_HOST}${image}`;
  }
}

customElements.define('intervention-report-obs', InterventionReportObs);
