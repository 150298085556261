'use strict';

import { LitElement, html, css } from '../node_modules/lit-element/lit-element.js';
import '../node_modules/@polymer/paper-input/paper-input.js';
import '../node_modules/@polymer/paper-button/paper-button.js';
import './shared-elements/offline-worker.js';
import './tech/tech-app.js';
import './admin/admin-app.js';
import * as User from './api/api-users.js';

// needed for the leftover polymer components
import { setPassiveTouchGestures } from '../node_modules/@polymer/polymer/lib/utils/settings.js';
setPassiveTouchGestures(true);

class ViewLogin extends LitElement {
  static get styles() {
    return css`
    :host {
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
      background-color: white;
    }

    form {
      max-width: 400px;
      width: 90%;
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    paper-button {
      margin: 0;
      width: 100%;
      color: white;
      background-color: #3A6FDF;
      margin-top: 15px;
    }

    h1 {
      text-align: center;
    }

    .error {
      color: #FE0006;
    }`;
  } 

  render () {
    return html`
      <offline-worker id="offline"></offline-worker>

      ${(this.user && this.user.type) ? 
          ['admin', 'sales'].includes(this.user.type) ?
            html`
              <admin-app name="admin" @logout="${this.logout}"></admin-app>
            ` :
            html`
              <tech-app name="tech" @logout="${this.logout}" @offline-network="${this._addOffline}"></tech-app>
            `
        :
        html`
          <form name="login">
            <h1>ALR Energy</h1>

            <paper-input label="Identifiant" id="username" name="username" autocomplete="username"></paper-input>
            <paper-input label="Mot de passe" type="password" id="password" name="password" autocomplete="password"></paper-input>

            ${this.loginError ? 
              html`<div class="error">Utilisateur ou mot de passe invalide</div>` : 
              html``
            }
            <paper-button raised @click="${this.submit}" type="submit">s'identifier</paper-button>
          </form>
        `
      }
    `;
  }

  static get properties() {
    return {
      loginError: { type: String },
      user: { type: Object }
    }
  }

  constructor() {
    super();

    document.body.onkeydown = e => {
      if(this.user) return;
      if(e.keyCode === 13) this.submit();
    };

    this.user = window.API_USER;
  }

  async submit() {
    try {
      const username = this.shadowRoot.getElementById('username').value;
      const password = this.shadowRoot.getElementById('password').value;
      window.API_TOKEN = await User.login({ username, password });

      window.localStorage.setItem('API_TOKEN', window.API_TOKEN);
      window.API_HEADERS = {
        Authorization: `Bearer ${window.API_TOKEN}`,
        'Content-Type': 'application/json'
      };

      this.user = await User.me();
      window.API_USER = this.user;
      window.localStorage.setItem('API_USER', JSON.stringify(this.user));

      console.log(window.API_TOKEN && window.API_USER && this.user && this.user.type)
    } catch(e) {
      this.loginError = e;
    }
  }

  logout() {
    delete window.API_TOKEN;
    delete window.API_HEADERS;
    delete window.API_USER;
    localStorage.removeItem('API_USER');
    localStorage.removeItem('API_TOKEN');
    this.username = '';
    this.password = '';
    this.user = {};
  }

  _addOffline(e) {
    this.shadowRoot.getElementById('offline').add(e.detail);
  }
}

customElements.define('view-login', ViewLogin);

