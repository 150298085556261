'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-ripple/paper-ripple.js';
import '../elements/intervention-info.js';
import '../elements/sav-part.js';
import '../elements/sav-form-bottom.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';
import { SharedStyles, MaterialTabs } from '../../shared-styles.js';
import SavValidator from '../elements/sav-validator.js';
import * as Intervention from '../../api/api-interventions.js';

class SavView extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      MaterialTabs,
      css`
        :host {
          display: grid;
          grid-template-rows: calc(100% - 64px) 64px;
        }

        .paper-font-body1 {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        #pages > * {
          position: inherit !important;
          box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.1);
          overflow-y: scroll;
          height: 100%;
          box-sizing: border-box;
        }

        #pages > *:not(.iron-selected) {
          display: none !important;
        }

        .tabs {
          height: 64px;
          font-size: 17px;
          border-top: 1px solid #ddd;
          --tab-background-color: white;
          --tab-active-color: #2b78e4;
          --tab-inactive-color: #757575;
          --tab-selector-height: 0;
        }
      `
    ];
  }

  render() {
    return html`
      <div id="pages">
        <intervention-info
          tab="info"
          class="type ${this.tab === 'info' ? 'iron-selected' : ''}"
          .name="${this.intervention._client.name}"
          .notes="${this.intervention.notes}"
          .address="${this.intervention._client.address}"
          .phone="${this.intervention._client.phone}"
          .time="${this.intervention.date}"
        ></intervention-info>

        <sav-part
          tab="hardware"
          class="type ${this.tab === 'hardware' ? 'iron-selected' : ''}"
          .workDone="${this.workDone}"
          .hardware="${this.hardware}"
          .photos="${this.photos}"
          @work-done-changed="${this._workDoneChanged}"
          @hardware-changed="${this._hardwareChanged}"
          @photos-changed="${this._photosChanged}"
        ></sav-part>

        <sav-form-bottom
          tab="observations"
          class="type ${this.tab === 'observations' ? 'iron-selected' : ''}"
          id="sav"
          ?showPrice="${!this.intervention.hidePrice}"
          .customPrice="${this.intervention.allowPriceEdit}"
          .prePaid="${this.prePaid}"
          .clientType="${this.intervention._client.type}"
          .errors="${this.errors}"
          .price="${this.intervention.price}"
          .observations="${this.observations}"
          .signatures="${this.signatures}"
          @price-changed="${this._priceChanged}"
          @observations-changed="${this._observationsChanged}"
          @signatures-changed="${this._signaturesChanged}"
          @finished-intervention="${this.finishIntervention}"
        ></sav-form-bottom>
      </div>

      <div class="tabs">
        
        <input type="radio" name="tabs" id="info-tab" value="info" class="tab"
          @click="${this._pageSelected}" checked
        >
        <label for="info-tab"><paper-ripple></paper-ripple>
          ${ALCIcons['info']}
        </label>
             
        <input type="radio" name="tabs" id="hardware-tab" value="hardware" class="tab"
          @click="${this._pageSelected}"
        >
        <label for="hardware-tab"><paper-ripple></paper-ripple>
          ${ALCIcons['build']}
        </label>
          
        <input type="radio" name="tabs" id="observations-tab" value="observations" class="tab"
          @click="${this._pageSelected}"
        >
        <label for="observations-tab"><paper-ripple></paper-ripple>
          ${ALCIcons['thumbs-up-down']}
        </label>
        <div class="tab-indicator"></div>
        
      </div>
    `;
  }

  static get properties() {
    return {
      photos: { type: Array },
      workDone: { type: String },
      prePaid: { type: Number },
      price: { type: Number },
      intervention: { type: Object },
      tab: { type: String },
      client: { type: Object },
      reclamation: { type: String },
      hardware: { type: Array },
      observations: { type: Object },
      signatures: { type: Object },
      time: { type: Date },
      started: { type: Date },
      finished: { type: Date },
      errors: { type: Array }
    }
  }

  constructor() {
    super();
    this.photos = [];
    this.intervention = {};
    this.tab = 'info';
    this.client = {};
    this.hardware = [];
    this.observations = {};
    this.signatures = {};
    this.errors = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.started = this.started || new Date().toISOString();
    this.validate();
  }

  updated(props) {
    if(props.has('intervention') || props.has('observations') || props.has('signatures') ||
    props.has('workDone') || props.has('hardware') || props.has('started') || props.has('price'))
      this.validate()
  }

  _pageSelected(e) {
    this.tab = this.shadowRoot.querySelector('input[name="tabs"]:checked').value;
  }
  
  validate() {
    if(!this.intervention) return;

    const intervention = {
      _client: this.intervention._client._id,
      _tech: this.intervention._tech,
      date: this.intervention.date,
      observations: this.observations, 
      workDone: this.workDone,
      signatures: this.signatures,
      hardware: this.hardware,
      price: this.price,
      started: this.started,
      photos: this.photos,
      type: this.intervention.type,
      finished: new Date()
    };

    this.errors = SavValidator(intervention);
  }

  finishIntervention() {
    Intervention.update(this.intervention._id, {
      _client: this.intervention._client._id,
      _tech: this.intervention._tech,
      date: this.intervention.date,
      observations: this.observations, 
      workDone: this.workDone,
      signatures: this.signatures,
      hardware: this.hardware,
      price: this.intervention.price,
      started: this.started,
      photos: this.photos,
      type: this.intervention.type,
      finished: new Date()
    }, true)
    .then(res => { this._editSuccess(res) })
    .catch(e => { this.shadowRoot.getElementById('sav').errors = e })
  }

  _editSuccess(res) {
    this.dispatchEvent(new CustomEvent('finished-intervention', {bubbles: true, composed: true, 
      detail: res
    }));
  }

  _workDoneChanged(e) { console.log(e.detail); this.workDone = e.detail }
  _hardwareChanged(e) { this.hardware = e.detail }
  _photosChanged(e) { this.photos = e.detail }
  _observationsChanged(e) { this.observations = e.detail }
  _signaturesChanged(e) { this.signatures = e.detail }
  _priceChanged(e) { this.intervention = {...this.intervention, price: e.detail} }
}

customElements.define('sav-view', SavView);
