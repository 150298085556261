'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import { SharedStyles } from '../shared-styles.js';

class InterventionReportClient extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
        }

        h2 {
          margin-top: 0;
          margin-bottom: 0;
        }
      `
    ];
  }

  render() {
    return html`
      <h2>${this.client.name}</h2>
      <span class="small-label">${this._clientType(this.client.type)}</span><br>
      ${this.client.address ? html`
        ${this.client.address.street}, ${this.client.address.postalCode} ${this.client.address.city}<br>
      ` : ''}
      ${this.client.phone}
    `;
  }

  static get properties() {
    return {
      client: { type: Object }
    }
  }

  constructor() {
    super();
    this.client = {};
  }

  _clientType(type) {
    switch(type){
      case 'individual':
        return 'Particulier';
      case 'company':
        return 'Société';
      case 'sci':
        return 'SCI';
    }
  }
}

customElements.define('intervention-report-client', InterventionReportClient);
