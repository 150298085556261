'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import '../../node_modules/@polymer/paper-ripple/paper-ripple.js';
import '../admin/elements/agenda-users.js';
import '../admin/elements/interventions-calendar-detail.js';
import { webSocket } from '../utils/utils.js';
import { ALCIcons } from './alc-icons.js';
import { SharedStyles, FabStyles } from '../shared-styles.js';
import * as Intervention from '../api/api-interventions.js';
import * as Utils from '../utils/utils.js';

class AgendaView extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      FabStyles,
      css`
        :host {
          display: block;
          height: 100% !important;
        }

        @media only screen
        and (max-device-width : 600px) {
          :host {
            padding: 0 !important;
          }
        }

        #intervention-calendar {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          height: 100%;
        }

        header {
          display: grid;
          grid-template-columns: auto auto auto;
          justify-content: space-between;
          border-radius: 5px;
        }

        #calendar {
          width: 100%;
          background-color: white;
          border: 1px solid #ccc;
          border-radius: 5px;
          margin-bottom: 10px;
          box-sizing: border-box;
        }

        h1 {
          font-size: 1.4em;
          font-weight: 300;
          text-transform: uppercase;
        }

        .date {
          text-align: center;
          padding: 7px;
          color: #999;
          width: 20px;
          float: left;
          flex-basis: 25%;
          border-right: 1px solid #eee;
          line-height: initial;
          height: 100%;
          box-sizing: border-box;
        }

        .iron-selected .date {
          color: white !important;
          background-color: #2b78e4;
        }

        .date-content {
          flex-basis: 75%;
          margin: 5px;
          padding: 5px;
          padding-left: 0;
          margin-top: 0;
          line-height: initial;
        }

        .now > .date {
          font-weight: 700;
          color: #8e352e;
        }

        #calendar-wrap {
          display: grid;
          grid-template-rows: auto min-content;
          width: 100%;
          flex-basis: 70%;
          margin-right: 10px;
          max-height: 100%;
          overflow-y: scroll;
        }

        #days, #weekdays {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }

        #days {
          height: calc(100% - 95px);
        }

        #weekdays {
          height: 30px;
          text-align: center;
          background-color: #eee;
          border-top: 1px solid #ccc;
          color: #333;
          text-transform: uppercase;
        }

        #days > .calendar-item {
          box-shadow: inset 1px 1px 0 #ccc;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          transition: background-color 0.5s ease;
          cursor: pointer;
          position: relative;
        }

        #days > .no-border {
          box-shadow: inset 0 1px 0 #ccc;
        }

        #weekdays > .calendar-item {
          border-left: 1px solid #ccc;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }

        #weekdays > .calendar-item:first-child {
          border-left: 0;
        }

        .calendar-item {
          margin: 0;
          padding: 0;
        }

        .previous > .date {
          color: #999 !important;
        }

        .calendar-nav {
          color: #2b78e4;
          margin: 0 10px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          cursor: pointer;
        }


        .circled-number {
          width: 20px;
          height: 20px;
          text-align: center;
          margin: 2px;
          padding: 3px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 5px;
        }

        .tech {
          color: white;
        }

        .sales {
          color: #333;
        }

        .hidden {
          display: none !important;
        }

         #detail-wrap {
          flex-basis: 30%;
          border: 1px solid #ccc;
          border-radius: 5px;
          background-color: white;
          padding: 0;
          box-sizing: border-box;
        }

        agenda-users {
          background-color: white;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 10px;
          box-sizing: border-box;
        }

        @media only screen 
        and (max-device-width : 600px) {
          .hide-mobile {
            display: none !important;
          }

          header {
            border-bottom: 1px solid #ddd;
            border-radius: 0;
          }

          h1 {
            font-weight: 500;
          }

          #days {
            display: initial;
            height: 100%;
            overflow-y: scroll;
            scroll-behavior: smooth;
          }

          #calendar-wrap {
            grid-template-rows: 100%;
            flex-basis: 100%;
            margin: 0;
          }

          #calendar {
            display: grid;
            grid-template-rows: 65px calc(100% - 65px);
            margin-bottom: 0;
            border: 0;
          }

          .calendar-nav {
            padding: 0 20px;
            margin: 0;
            font-size: 1.3em;
          }

          interventions-calendar-detail {
            height: auto;
          }

          interventions-calendar-detail:last-of-type {
            padding-bottom: 30px;
          }
        }

        @media only screen 
        and (min-device-width : 600px) {
          .show-mobile {
            display: none !important;
          }
        }
      `
    ];
  }
  render() {
    return html`
      <div id="intervention-calendar">
        <div id="calendar-wrap">
          <div id="calendar">
            <header>
              <div @click="${this._previousMonth}" class="calendar-nav" aria-label="previous">
                <span class="hide-mobile">précédent</span>
                <span class="show-mobile">${ALCIcons['previous']}</span>
              </div>
              
              <h1>${this._getMonth(this.month)}</h1>
              
              <div @click="${this._nextMonth}" class="calendar-nav" aria-label="next">
                <span class="hide-mobile">suivant</span>
                <span class="show-mobile">${ALCIcons['next']}</span>
              </div>
            </header>
            
            ${this._isMobile ? '' : html`
              <div id="weekdays">
                  <div class="calendar-item">Dimanche</div>
                  <div class="calendar-item">Lundi</div>
                  <div class="calendar-item">Mardi</div>
                  <div class="calendar-item">Mercredi</div>
                  <div class="calendar-item">Jeudi</div>
                  <div class="calendar-item">Vendredi</div>
                  <!-- <div class="calendar-item">Samedi</div> -->
              </div>
            `}

            <div id="days">
              ${this.calendar.filter(this._filterDays.bind(this)).map(day => html`
                ${this._isMobile ? html`
                    <interventions-calendar-detail 
                      id="${day.now ? 'now' : ''}"
                      class="show-mobile"
                      .interventions="${this._computeCurrent(this.calendar, day.date)}"
                      .techs="${this.techs}"
                      .sales="${this.sales}"
                      .date="${day.date}"
                      @edit-intervention="${this._editIntervention}"
                    ></interventions-calendar-detail>
                ` : html`
                  <div
                    class="${this._getDayClass(day)}"
                    date="${this._dayKey(day.date)}"
                    @click="${this._setDate}"
                  >
                    <paper-ripple></paper-ripple>

                    <div class="date">${this._day(day.date)}</div>
                    
                    <div class="date-content">

                      ${this._getTechsData(day).map(tech => html`
                        <div class="tech-line">
                          
                          <div class="${this._getColor(tech, this.techs, 'circled-number')}">
                            <span class="tech">${tech.total}</span>
                          </div>
                          <span>${tech.text}</span>
                        </div>
                      `)}

                    </div>
                  </div>
                `}
              `)}
            </div>
          </div>

          ${this._isMobile ? '' : html`
            <agenda-users .techs="${this._filteredTechs}"></agenda-users>
          `}
        </div>
        
        ${this._isMobile ? '' : html`
          <div id="detail-wrap">
            <interventions-calendar-detail 
              .interventions="${this._computeCurrent(this.calendar, this.date)}" 
              .techs="${this.techs}" 
              .sales="${this.sales}" 
              .date="${this.date}"
              @edit-intervention="${this._editIntervention}"
            ></interventions-calendar-detail>
          </div>
        `}
      </div>

      <button class="fab" @click="${this._openNewIntervention}" aria-label="new">
        ${ALCIcons['add']}
        <paper-ripple></paper-ripple>
      </button>
    `;
  }

  static get properties() {
    return {
      month: { type: Date },
      sales: { type: Array },
      techs: { type: Array },
      interventions: { type: Array },
      showWeekEnd: { type: Boolean },
      calendar: { type: Array },
      techs: { type: Array },
      users: { type: Array },
      sales: { type: Array },
      admins: { type: Array },
      _filteredTechs: { type: Array },
      date: { type: Date },
      month: { type: Date },
      _isMobile: { type: Boolean }
    }
  }

  constructor() {
    super();
    this._isMobile = window.outerWidth <= 600;
    this.interventions = [];
    this.showWeekEnd = false;
    this.calendar = [];
    this.techs = [];
    this.admins = [];
    this.sales = [];
    this.users = [];
    this._filteredTechs = [];
    this.date = new Date(new Date().setHours(0,0,0,0));
    this.month = new Date(new Date().setDate(5));
    window.onresize = () => this._isMobile = window.outerWidth <= 600;
    webSocket({ url: window.API_WEB_SOCKET, onMessage: this._socketMessage.bind(this) });
  }

  updated(props) {
    if(props.has('month'))
      this._updateCalendar();
    if(props.has('techs') || props.has('interventions'))
      this._filteredTechs = [...this.techs]
        .filter(t => t.active)
        .filter(t => 
          this.interventions.some(i => i._tech === t._id)
        );
    if(props.has('_isMobile'))
      this._scrollToNow();
  }

  async _updateCalendar() {
    const month = this.month;
    const { from, to } = Utils.dateRange(month, 'month', true);
    this.interventions = await Intervention.get(null, { from, to });
    this.calendar = Utils.interventionsToCalendar(this.interventions, from, to, true);
  }

  _socketMessage({ data }) {
    if(data === 'refresh-interventions')
      this._updateCalendar();
  }

  _getTechsData(day) {
    let techs = {};
    for(let i of day.interventions) {
      if(!techs[i._tech])
        techs[i._tech] = { _id: i._tech, regions: new Set(), total: 0 };

      techs[i._tech].regions.add(i._client.address.region);
      techs[i._tech].total++;
      techs[i._tech].text = [...techs[i._tech].regions].sort().join(', ');
    }
    return Object.values(techs);
  }

  _setDate(e) {
    this.date = e.currentTarget.getAttribute('date');
  }

  _filterTechs(techs, calendar) {
    var filteredIndex = [];

    for(var day of calendar)
      for(let i of day.interventions)
        if(!filteredIndex.includes(i._tech))
          filteredIndex.push(i._tech);

    return techs.filter(tech => filteredIndex.includes(tech._id));
  }

  _getMonth(m) {
    var d = new Date(m);
    var s = d.toLocaleString('fr-FR', { month: 'long' });
    return s.charAt(0).toUpperCase() + s.slice(1) + ' ' + d.getFullYear();
  }

  _scrollToNow() {
    if(!this._isMobile) return;
    const now = this.shadowRoot.getElementById('now');
    // wait a little bit until the rendering is done
    if(now)
      setTimeout(() => now.scrollIntoView(), 100);
    else
      setTimeout(() => this._scrollToNow(), 100);
  }

  _day(date) {
    return new Date(date).getDate();
  }

  _getDayClass(day) {
    var c = 'calendar-item';

    if(day.now) c += ' now';
    if(day.previous || day.next) c += ' others';
    if(!this.showWeekEnd && day.date.getDay() === 0) c += ' no-border';
    // if(this.showWeekEnd && day.date.getDay() === 0) c += ' no-border';
    if(day.date.toString() === this.date) c += ' iron-selected';
    return c;
  }

  _editIntervention(e) {
    for(let day of this.calendar)
      for(let intervention of day.interventions)
        if(intervention._id === e.detail)
          return this.dispatchEvent(new CustomEvent('show-new-intervention', { 
            bubbles: true, composed: true, detail: { intervention, date: intervention.date }
          }));
    }

  _previousMonth() {
    const d = new Date(this.month);
    this.month = new Date(d.setMonth(d.getMonth() - 1));
  }

  _nextMonth() {
    this.month = new Date(this.month.setMonth(this.month.getMonth() + 1));
  }

  _dayKey(d){   
    return new Date(d).toString();
  }

  _getColor(user, users, classes) {
    for (var i = 0; i < users.length; i++) {
      if(users[i]._id === user._id) 
        return classes + ' ' + users[i].color;
    }

    return 'hidden';
  }

  _computeCurrent(calendar, date) {
    if(!Array.isArray(calendar)) return [];
    let day = calendar.filter(d => new Date(d.date).toDateString() === new Date(date).toDateString())[0];
    return day ? day.interventions : [];
  }

  _filterDays(day) {
    if(window.outerWidth > 600)
      return (this.showWeekEnd || ![6].includes(day.date.getDay()));

    return (!!day.interventions.length && day.date.getMonth() === new Date(this.month).getMonth());
  }

  _openNewIntervention() {
    this.dispatchEvent(new CustomEvent('show-new-intervention', {bubbles: true, composed: true}));
  }
}

customElements.define('agenda-view', AgendaView);
