'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import { SharedStyles } from '../../shared-styles.js';

class AgendaUsers extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          overflow-x: auto;
          overflow-y: hidden;
          white-space: nowrap;
        }

        .name {
          padding: 3px 20px;
          margin: 3px;
          border-radius: 4px;
          display: inline-block;
          font-size: 16px;
          color: white;
          font-weight: 500;
        }

        .tech { color: white; }
        .sales { color: #333; }
      `
    ];
  }

  render() {
    return html`
      ${(this.techs || []).map(tech => html`
        <div class="name ${tech.color}"><span class="tech">${tech.name}</span></div>
      `)}

      <!--<br>-->
      
      ${(this.sales || []).map(sale => html`
        <div class="name ${sale.color}"><span class="sale">${sale.name}</span></div>
      `)}
`;
  }

  static get properties() {
    return {
      techs: { type: Array },
      sales: { type: Array }
    }
  }

  constructor() {
    super();
    this.techs = [];
    this.sales = [];
  }
}

customElements.define('agenda-users', AgendaUsers);
