'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import { cache } from '../../node_modules/lit-html/directives/cache.js';
import '../../node_modules/@polymer/paper-ripple/paper-ripple.js';
import '../shared-elements/intervention-report.js';
import '../shared-elements/agenda-view.js';
import '../shared-elements/client-edit.js';
import '../shared-elements/client-detail.js';
import '../shared-elements/week-view.js';
import './elements/new-intervention.js';
import './elements/user-edit.js';
import './views/settings-view.js';
import './views/users-view.js';
import './views/clients-view.js';
import { SharedStyles, MaterialTabs } from '../shared-styles.js';
import { ALCIcons } from '../shared-elements/alc-icons.js';
import { DialogStyles, dialogStyleMap } from '../utils/dialog.js';
import * as Client from '../api/api-clients.js';
import * as User from '../api/api-users.js';
import { webSocket } from '../utils/utils.js';

class AdminApp extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      MaterialTabs,
      DialogStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
          background-color: #f5f5f5;
        }

        @media only print {
          #grid {
            display: none !important;
          }
        }

        #header {
          grid-area: header;
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 60px;
          text-transform: uppercase;
          font-weight: bold;
          background-color: #4c78e2;
          color: white;
          padding: 0 20px;
        }

        h1 {
          font-size: 2em;
          color: white;
          padding: 0;
          margin: 0;
        }

        #logout {
          right: 25px;
          position: absolute;
          color: white;
          cursor: pointer;
          outline: 0;
          background-color: transparent;
          border: 0;
        }

        #reload {
          right: 70px;
          position: absolute;
          color: white;
          cursor: pointer;
          outline: 0;
          background-color: transparent;
          border: 0;
        }

        #content {
          grid-area: content;
          max-width: 100%;
        }

        #content > * {
          height: auto;
          max-height: 100%;
          width: 100%;
          max-width: 100%;
          overflow-y: auto;
          padding: 10px;
          margin: auto;
          box-sizing: border-box;
          -webkit-overflow-scrolling: touch;
          -webkit-tap-highlight-color: rgba(0,0,0,0);
        }

        #grid {
          display: grid;
          height: 100%;
          max-height: 100%;
          width: 100%;
          max-width: 100%;
          grid-template-rows: 60px 50px calc(100% - 110px);
          grid-template-areas: "header"
          "menu"
          "content";
        }

        @media only screen 
        and (max-device-width : 600px) {
          #dialog {
            bottom: 0;
            max-width: 600px;
            max-height: 100%;
            min-height: 100%;
          }

          #dialog #content {
            padding: 0;
          }

          #edit-button {
            position: fixed;
            bottom: 20px;
            right: 30px;
            left: initial;
            float: initial;
          }
        }

        @media only screen 
        and (min-device-width : 600px) {
          .dialog {
            display: none;
          }
        }
      `
    ];
  }

  render() {
    return html`
      <div id="grid">

        <div id="header">
          <h1>ALR Energy</h1>
          <button id="reload" @click="${this.reload}" aria-label="logout">
            ${ALCIcons['refresh']}
          </button>
          <button id="logout" @click="${this.logout}" aria-label="logout">
            ${ALCIcons['exit']}
          </button>
        </div>

        <div class="tabs">
          
          <input type="radio" name="tabs" id="agenda-tab" value="/agenda" class="tab"
            @click="${this._pageSelected}" ?checked="${this.page === '/agenda'}"
          >
          <label for="agenda-tab" value="/agenda">
            <paper-ripple></paper-ripple>
            <span class="hide-mobile">agenda</span>
            <span class="show-mobile">${ALCIcons['event']}</span>
          </label>

          <input type="radio" name="tabs" id="week-tab" value="/week" class="tab"
            @click="${this._pageSelected}" ?checked="${this.page === '/week'}"
          >
          <label for="week-tab" value="/week">
            <paper-ripple></paper-ripple>
            <span class="hide-mobile">semaine</span>
            <span class="show-mobile">${ALCIcons['event']}</span>
          </label>
               
          <input type="radio" name="tabs" id="users-tab" value="/users" class="tab"
            @click="${this._pageSelected}" ?checked="${this.page === '/users'}"
          >
          <label for="users-tab" value="/users">
            <paper-ripple></paper-ripple>
            <span class="hide-mobile">${this.user.type === 'admin' ? 'utilisateurs' : 'ventes'}</span>
            <span class="show-mobile">${ALCIcons['build']}</span>
          </label>
            
          <input type="radio" name="tabs" id="clients-tab" value="/clients" class="tab"
            @click="${this._pageSelected}" ?checked="${this.page === '/clients'}"
          >
          <label for="clients-tab" value="/clients">
            <paper-ripple></paper-ripple>
            <span class="hide-mobile">clients</span>
            <span class="show-mobile">${ALCIcons['people']}</span>
          </label>
          
          ${this.user.type === 'admin' ? html`
            <input type="radio" name="tabs" id="settings-tab" value="/settings" class="tab"
              @click="${this._pageSelected}" ?checked="${this.page === '/settings'}"
            >
            <label for="settings-tab" value="/settings">
              <paper-ripple></paper-ripple>
              <span class="hide-mobile">paramètres</span>
              <span class="show-mobile">${ALCIcons['settings']}</span>
            </label>
          ` : ''}  
          <div class="tab-indicator"></div>
          
        </div>



        <section id="content">
          ${cache(this.page === '/agenda' ? html`
            <agenda-view
              .sales="${this.sales}"
              .techs="${this.techs}"
              .users="${this.users}"
              .clients="${this.clients}"
              .admins="${this.admins}"
              @show-report="${this._showReport}"
              @show-new-intervention="${this._showNewIntervention}"
            ></agenda-view>
          ` : '')}

          ${cache(this.page === '/week' ? html`
            <week-view
              .sales="${this.sales}"
              .techs="${this.techs}"
              .users="${this.users}"
              .clients="${this.clients}"
              .admins="${this.admins}"
              @show-report="${this._showReport}"
              @show-new-intervention="${this._showNewIntervention}"
            ></week-view>
          ` : '')}

          ${cache(this.page === '/users' ? html`
            <users-view
              .users="${this.users}"
              @show-report="${this._showReport}"
              @show-new-intervention="${this._showNewIntervention}"
              @show-user-edit="${this._openNewUser}"
            ></users-view>
          ` : '')}

          ${cache(this.page === '/clients' ? html`
            <clients-view
              .sales="${this.sales}"
              .techs="${this.techs}"
              .users="${this.users}"
              .admins="${this.admins}"
              @show-report="${this._showReport}"
              @show-new-intervention="${this._showNewIntervention}"
              @show-client-edit="${this._openClientEdit}"
              @show-client-details="${this._openClientDetails}"
            ></clients-view>
          ` : '')}

          ${cache(this.page === '/settings' && this.user.type === 'admin' ? html`
            <settings-view
              .users="${this.users}"
              @show-user-edit="${this._openNewUser}"
            ></settings-view>
          ` : '')}

        </section>
      </div>

      ${cache(this._showClientDetails ? html`
        <div
          id="dialog"
          slide-from-bottom
          style="${dialogStyleMap(this._showClientDetails)}"
          @show-new-intervention="${this._showNewIntervention}"
          @show-report="${this._showReport}"
          @show-client-edit="${this._openClientEdit}"
        >
          <h2 id="title">
            <span>${this.client.name}</span>
            <span id="close" @click="${this._closeDetail}">${ALCIcons['close']}</span>
          </h2>
          <div id="content">
            <client-details
              .client="${this.client}"
              .techs="${this.techs}"
              .sales="${this.sales}"
            ></client-details>
          </div>
        </div>
      ` : '')}

      ${this._reportVisible ? html`
        <intervention-report
          interventionId="${this.interventionId}"
          @report-closed="${this._reportClosed}"
        ></intervention-report>
      ` : ''}

      ${this._showNewInterventionOpened ? html`
        <new-intervention
          id="new-intervention"
          .clients="${this.clients}"
          .techs="${this.techs}"
          .date="${this.date}"
          .intervention="${this.intervention}"
          @edit-success="${this._interventionClosed}"
          @show-client-edit="${this._openClientEdit}"
          @close-intervention-edit="${this._interventionClosed}"
        ></new-intervention>
      ` : ''}

      <!-- Has to be below <new-intervention> in order to be displayed on top -->
      ${this._showClientEdit ? html`
        <client-edit
          id="client-edit"
          .user="${this.user}"
          .sales="${this.sales}"
          .client="${this.client}"
          @client-edit-close="${this._closeClientEdit}"
          @client-edit-success="${this._closeClientEdit}"
        ></client-edit>
      ` : ''}

      ${this._editUserDialog ? html`
        <user-edit 
          @close-user-edit="${this._closeUserEdit}" 
          @user-added="${this._closeUserEdit}"
          .user="${this.editUser}"
        ></user-edit>
      ` : ''}
    `;
  }

  static get properties() {
    return {
      user: { type: Object },
      _reportVisible: { type: Boolean },
      _showClientEdit: { type: Boolean },
      _editUserDialog: { type: Boolean },
      _showClientDetails: { type: Boolean },
      _showNewInterventionOpened: { type: Boolean },
      intervention: { type: Object },
      interventionId: { type: String },
      client: { type: Object },
      date: { type: Date },
      page: { type: String },
      clients: { type: Array },
      techs: { type: Array },
      sales: { type: Array },
      users: { type: Array },
      admins: { type: Array },
      _isMobile: { type: Boolean }
    }
  }

  constructor() {
    super();
    this._isMobile = window.outerWidth <= 600;
    this._reportVisible = false;
    this._showClientEdit = false;
    this._editUserDialog = false;
    this._showNewInterventionOpened = false;
    this.clients = [];
    this.techs = [];
    this.sales = [];
    this.users = [];
    this.admins = [];
    this.client = {};
    window.onresize = () => this._isMobile = window.outerWidth <= 600;
  }


  connectedCallback() {
    super.connectedCallback();

    webSocket({ url: window.API_WEB_SOCKET, onMessage: this._socketMessage.bind(this) });
    this.user = window.API_USER;
    this._getClients();
    this._getUsers();

    this.page = window.location.pathname;
    if(!this.page || this.page === '/') {
      this.page = '/agenda';
      window.history.pushState({}, null, this.page);
    }

    window.onpopstate = () => {
      this.page = window.location.pathname;
    }
  }

  reload() {
    window.location.reload();
  }

  _pageSelected(e) {
    this.page = e.currentTarget.getAttribute('value');
    window.history.pushState({}, null, this.page);
  }

  logout() {
    this.dispatchEvent(new CustomEvent('logout', {bubbles: true, composed: true}));
  }

  _openClientEdit(e) {
    this.client = e.detail ? e.detail.client : null;
    this._showClientEdit = true;
  }

  _closeClientEdit(e) {
    if(e.detail && this.shadowRoot && this.shadowRoot.getElementById('new-intervention'))
      this.shadowRoot.getElementById('new-intervention').updateClient(e.detail);
    
    this._showClientEdit = false;
  }

  _openClientDetails(e) {
    if(e.detail && e.detail.client) {
      this.client = e.detail.client;
      this._showClientDetails = true;
    }
  }

  _closeDetail() {
    this._showClientDetails = false;
  }

  _showReport(e) {
    if(!e.detail) return;

    this.interventionId = e.detail.id;
    this._reportVisible = true;
  }

  _reportClosed() {
    this.interventionId = null;
    this._reportVisible = false;
  }

  _showNewIntervention(e) {
    if(e.detail) {
      this.intervention = e.detail.intervention;
      this.date = e.detail.date;
      if(e.detail.intervention._client)
        e.detail.intervention._client = this.clients.find(c => 
          c._id === e.detail.intervention._client._id
        )
      this.intervention = e.detail.intervention;
    } else {
      this.intervention = null;
      this.date = null;
    }
    this._showNewInterventionOpened = true;
    // this.shadowRoot.getElementById('new-intervention').open();
  }

  _interventionClosed() {
    this._showNewInterventionOpened = false;
    // this.shadowRoot.getElementById('new-intervention').close();
  }

  _openNewUser(e) {
    this._editUserDialog = true;
    this.editUser = e.detail || {};
  }

  _closeUserEdit() {
    this._editUserDialog = false;
  }

  _getClients() {
    Client.get()
    .then(clients => { this.clients = clients })
  }

  _getUsers() {
    User.get()
    .then(({users, admins, techs, sales}) => { 
      this.users = users;
      this.admins = admins;
      this.techs = techs;
      this.sales = sales;
    })
  }

  _socketMessage({ data }) {
    if(data === 'refresh-clients')
      this._getClients();
    if(data === 'refresh-users')
      this._getUsers();
  }
}

customElements.define('admin-app', AdminApp);
