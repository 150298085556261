'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';

class AddHardware extends LitElement {
  static get styles() {
    return css`
      :host {
        display: grid;
        height: 100%;
        grid-template-rows: 1fr 1fr 1fr;
        background-color: #f3f3f3;
        overflow-y: hidden;
        align-items: center;
        padding-top: 0 !important;
      }

      paper-button {
        background-color: white;
        color: #3A6FDF;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #3A6FDF;
        display: inline-flex;
        align-items: center;
        max-height: 200px;
        height: 90%;
        margin: auto;
        width: 95%;
        max-width: 500px;
      }
    `;
  }

  render() {
    return html`
      <paper-button @click="${this.fireAddHardware}" type="airAir">Air/air</paper-button>
      <paper-button @click="${this.fireAddHardware}" type="airWater">Air/eau</paper-button>
      <paper-button @click="${this.fireAddHardware}" type="waterTank">Ballon ECS</paper-button>
    `;
  }

  constructor() {
    super();
  }

  fireAddHardware(e) {
    this.dispatchEvent(new CustomEvent('add-hardware', {bubbles: true, composed: true, 
      detail: e.target.getAttribute('type')
    }));
  }
}

customElements.define('add-hardware', AddHardware);
