'use strict';

import { css } from '../../node_modules/lit-element/lit-element.js';
import { styleMap } from '../../node_modules/lit-html/directives/style-map.js';

export const DialogStyles = css`
  @keyframes keyFrameSlideFromBottom {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media not print {
    #dialog[slide-from-bottom] {
      opacity: 1;
      animation-name: keyFrameSlideFromBottom;
      animation-iteration-count: 1;
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      animation-duration: 500ms;
      animation-fill-mode: forwards;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
  }

  #dialog {
    bottom: 0;
    max-width: 850px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 100%;
    background-color: white;
    position: absolute;
  }

  #dialog #title {
    margin: 0;
    color: white;
    background-color: #4D79E2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px !important;
  }

  #dialog #content {
    overflow-y: auto;
    margin: 0;
    padding: 0 20px 10px;
    -webkit-overflow-scrolling: touch;
  }

  #overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    outline: none;
    background-color: black;
    transition: opacity 300ms, visibility 300ms;
  }
`;

export const dialogStyleMap = opened => {
  return styleMap({ display: opened ? 'flex' : 'none' });
}

export const overlayStyleMap = opened => {
  return opened ? styleMap({ opacity: 0.6, visibility: 'visible' })
    : styleMap({ opacity: 0, visibility: 'hidden' });
}