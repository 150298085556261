'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import { formatTime, interventionTypeName } from '../utils/utils.js';

class InterventionProgrammed extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
      }

      .program {
        padding: 1em;
        border-bottom: 1px solid #eee;
      }

      .hour {
        font-size: 20px;
        font-weight: 300;
        margin-bottom: 3px;
      }

      #empty {
        height: 100%;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 20px;
        font-weight: 300;
        color: #ccc;
        text-align: center;
      }
    `;
  }

  render() {
    return html`
      ${(!this.interventions || !this.interventions.length) ? html`
        <div id="empty">
          <span>Aucune intervention</span>
        </div>
      ` : ''}

      ${(this.interventions || []).map(item => html`
        <div class="program">
          <div class="hour">${formatTime(item.date)}</div>
          ${item._client.address.postalCode} ${item._client.address.city}<br>
          ${interventionTypeName(item.type)}
          

          ${(item.initialHardware && item.initialHardware.length) ? html`
            <br>
            ${item.initialHardware.map(hw => this._getName(hw))}
          ` : ''}

          ${item.notes}
        </div>
      `)}
    `;
  }

  static get properties() {
    return {
      interventions: { type: Array }
    }
  }

  constructor() {
    super();
    this.interventions = [];
  }

  _getName(name) {
    switch(name){
      case 'airAir':
        return 'Air/air';
      case 'airWater':
        return 'Air/eau';
      case 'waterTank':
        return 'Ballon ECS';
     }
   }

  _isEmpty(interventions) {
    return (!interventions || !interventions.length);
  }
}

customElements.define('intervention-programmed', InterventionProgrammed);
