'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-input/paper-textarea.js';
import './sav-works-part.js';
import './technician-observations.js';
import { SharedStyles } from '../../shared-styles.js';

class SavPart extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
          overflow-y: scroll;
          width: 100%;
          background-color: #f3f3f3;
        }

        #container {
          width: 95%;
          margin: auto;
          padding-top: 30px;
        }

        #container > * {
          margin-bottom: 30px;
        }

        paper-textarea {
          --paper-input-container-label-floating: {
            font-size: 26px;
          }
        }
      `
    ];
  }

  render() {
    return html`
      <div id="container">

        <div class="paper-card">
          <div class="card-header">Travaux et tests effectués</div>
          <div class="content">
            <paper-textarea
              id="work"
              label="Description"
              rows="5"
              .value="${this.workDone}"
              @keyup="${this._workDoneChanged}"
            ></paper-textarea>
          </div>
        </div>

        <div class="paper-card" id="works">
          <div class="card-header">Matériel</div>
          <div class="content">
            <sav-works-part
              .hardware="${this.hardware}"
              @hardware-changed="${this._hardwareChanged}"
            ></sav-works-part>
          </div>
        </div>

        <technician-observations
          .photos="${this.photos}"
          @photos-changed="${this._photosChanged}"
        ></technician-observations>
      </div>
    `;
  }

  static get properties() {
    return {
      photos: { type: Array },
      hardware: { type: Array },
      workDone: { type: String }
    }
  }

  constructor() {
    super();
    this.photos = [];
    this.hardware = [];
  }

  _workDoneChanged(e) { 
    this.workDone = e.target.value; 
    this.dispatchEvent(new CustomEvent('work-done-changed', {
      detail: e.target.value, composed: true, bubbles: true
    }))
  }
  _hardwareChanged(e) { console.log(e.detail); this.hardware = e.detail }
  _photosChanged(e) { console.log(e.detail); this.photos = e.detail }
}

customElements.define('sav-part', SavPart);
