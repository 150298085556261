'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';

class RemoveEquipment extends LitElement {
  static get styles() {
    return [
      css`
        #remove {
          background-color: #b71c1c;
          color: white;
          font-weight: 500;
        }
      `
    ];
  }
  
  render() {
    return html`
      <paper-button id="remove" @click="${this.clickedRemove}">
        ${ALCIcons['delete']}
      </paper-button>
    `;
  }

  static get properties() {
    return {
      pushed: { type: Boolean }
    }
  }

  constructor() {
    super();
    this.pushed = false;
  }

  clickedRemove(e) {
    if(this.pushed === true){
      return this.dispatchEvent(new CustomEvent('remove', {bubbles: true, composed: true}));
    }

    this.shadowRoot.getElementById('remove').style.backgroundColor = '#f44336';
    this.pushed = true;
    e.target.innerText = 'CONFIRMER';

    var that = this;
    setTimeout(function(){ 
      that.shadowRoot.getElementById('remove').style.backgroundColor = '#b71c1c';
      e.target.innerText = 'SUPPRIMER';
      that.pushed = false;
    }, 2000);
  }
}

customElements.define('remove-equipment', RemoveEquipment);
