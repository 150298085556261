'use strict';

export const interventionTypes = {
  contract: 'Contrat d\'entretien',
  sav: 'Dépannage',
  installation: 'Installation',
  preinstallation: 'Pré visite'
};

export function interventionTypeName(type){
  return interventionTypes[type];
}

const isValidDate = date => {
  let d = new Date(date);
  if(Object.prototype.toString.call(d) !== '[object Date]' || !date)
    return false;
  
  return isNaN(d.getTime()) ? false : true;
}

export function dateRange(date, type = 'month', includeSideDays = false, showWeekEnds = false) {
  if(!isValidDate(date)) return;
  date = new Date(date);
  
  if(type === 'day') {
    
    return {
      from: new Date(date.setHours(0,0,0,0)), 
      to: new Date(date.setHours(23, 59, 59, 999))
    };

  } else if(type === 'week') {

    const weekStart = date.getDay();
    const weekEnd = showWeekEnds ? 6 : 5;
    
    return {
      from: new Date(new Date(date.setDate(date.getDate() - weekStart)).setHours(0,0,0,0)),
      to: new Date(new Date(date.setDate(date.getDate() + weekEnd)).setHours(23, 59, 59, 999))
    };
  } else if(type === 'month') {
    
    var currMonth = date.getMonth();
    var currYear = date.getFullYear();
    var firstDayMonth = new Date(currYear, currMonth, 1).getDay();
    var lastDayMonth = new Date(currYear, currMonth + 1, 0).getDay();
    var from, to;

    if(includeSideDays){
      from = new Date(new Date(currYear, currMonth, 1).setDate(new Date(currYear, currMonth, 1).getDate() - firstDayMonth));
      to = new Date(new Date(currYear, currMonth + 1, 0).setDate(new Date(currYear, currMonth + 1, 0).getDate() + (6 - lastDayMonth)));
    } else {
      from = new Date(new Date(currYear, currMonth, 1));
      to = new Date(new Date(currYear, currMonth + 1, 0));
    }

    return {from, to};

  }
}

export function interventionsToCalendar(interventions, from, to) {
  var firstDate = new Date(from);
  var lastDate = new Date(to);

  // fixes a bug, trust me
  for (var i = 0; i < interventions.length; i++) {
    var intDate = new Date(interventions[i].date);
    if(intDate < firstDate || intDate > lastDate)
      return;
  }

  var calendar = [];
  var index = [];
  var now = new Date();

  for(var d = firstDate; d <= lastDate; d.setDate(d.getDate() + 1)){
    calendar.push({
      date: new Date(d),
      interventions: [],
      now: (now.toDateString() === d.toDateString()),
      previous: (new Date(d).setHours(23,59,59,0) < new Date(now).setHours(0,0,0,0)),
      next: (new Date(d).setHours(0,0,0,0) > new Date(now).setHours(23,59,59,0))
    });

    index.push(d.toString().substr(0, 15));
  }

  for (var i = 0; i < interventions.length; i++) {
    interventions[i]._client.address.region = getRegion(
      interventions[i]._client.address.country, 
      interventions[i]._client.address.postalCode
    );
    var pos = index.indexOf(new Date(interventions[i].date).toString().substr(0, 15)); 
    calendar[pos].interventions.push(interventions[i]);
  }

  return calendar;
}

export function getRegion(country, postalCode) {
  return country === 'FR' ? postalCode.substr(0, 2) : 'BE';
}

export function webSocket({
  url, autoReconnect = true, onMessage = () => {}, 
  onError = () => {}, onClose = () => {}, onOpen = () => {}
}) {
  const args = arguments;
  let ws = new WebSocket(url);

  ws.onopen = onOpen;
  ws.onmessage = onMessage;
  ws.onerror = onError
  ws.onclose = (e) => {
    if(autoReconnect && e.code !== 1000) 
      setTimeout(() => webSocket(args[0]), 100);

    return onClose(e);
  };
}

export function formatTime(date) {
  return new Date(date).toLocaleString('fr-FR', { 
    hour: 'numeric', 
    minute: 'numeric', 
  })
}

export const debounce = (self, callback, wait) => {
  let timeout = null;
  console.log(self);
  return (...args) => {
    const next = () => callback(...args).bind(self)
    clearTimeout(timeout)
    timeout = setTimeout(next, wait)
  }
}
