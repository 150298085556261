'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import '../../node_modules/@polymer/paper-ripple/paper-ripple.js';
import '../../node_modules/@polymer/paper-button/paper-button.js';
import { SharedStyles, FabStyles } from '../shared-styles.js';
import { ALCIcons } from './alc-icons.js';
import { formatTime, interventionTypeName } from '../utils/utils.js';
import * as Intervention from '../api/api-interventions.js';

class ClientDetails extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      FabStyles,
      css`
        #empty {
          height: 100%;
          min-height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          font-size: 26px;
          font-weight: 300;
          color: #ccc;
          text-align: center;
        }

        .sale {
          color: black;
          display: inline;
        }

        #client-name {
          text-align: center;
          margin: 0;
          padding: 0;
          padding-top: 20px;
        }

        .client-intervention {
          width: 90%;
          margin: 15px 5%;
          box-sizing: border-box;
        }

        .intervention-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .card-actions > paper-button {
          color: #4285f4;
          flex-grow: 1;
          margin: 0;
        }

        .card-actions {
          border-top: 1px solid #e8e8e8;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding: 0 !important;
          width: 100%;
        }

        #client-detail {
          padding: 0 20px;
        }

        #client-detail-content {
          background-color: #f5f5f5;
          font-size: 14px;
          padding: 10px;
          border-radius: 3px;
        }

        .paper-card {
          display: block;
          margin: auto;
          margin-bottom: 20px;
          width: 100%;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 1em;
          position: relative;
        }

        .card-content {
          padding: 15px;
        }

        .card-actions > a {
          color: #4285f4;
          text-transform: uppercase;
          flex-grow: 1;
          text-align: center;
          padding: 10px;
          cursor: pointer;
          text-decoration: none;
          position: relative;
        }

        .card-actions {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding: 0 !important;
          border-top: 1px solid #ccc;
        }

        #intervention-list {
          box-sizing: border-box;
          padding: 20px 20px;
        }

        .date {
          font-size: 1.3em;
          font-weight: 300;
        }

        .name-label {
          display: block;
          text-align: center;
          margin: 0 0 10px 0;
        }

        .name-label.sale {
          display: inline;
        }


        #edit-button {
          color: #666;
          background-color: #eee;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          outline: 0;
          cursor: pointer;
          border: 0;
          margin-left: 15px;
          display: flex;
          justify-content: center;
          position: relative;
          align-items: center;
        }

        #address {
          margin-top: 10px;
          display: block;
        }

        h2 {
          margin-top: 0;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
        }

        h2 span {
          margin-left: 27px;
        }

        @media only screen 
        and (max-device-width : 600px) {
          h2#client-name {
            display: none;
          }

          #edit-button {
            position: absolute !important;
            bottom: 20px;
            right: 30px;
            left: initial;
            float: initial;
            z-index: 100;
          }

          h2 span {
            display: none;
          }

          #client-name, #client-name header, #client-name b {
            padding: 0;
            margin: 0;
          }

          #edit-button {
            color: #666;
            background-color: #eee;
            width: 56px;
            height: 56px;
            position: absolute;
            bottom: 20px;
            right: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            outline: 0;
            cursor: pointer;
            border: 0;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 
              0 1px 5px 0 rgba(0, 0, 0, 0.12), 
              0 3px 1px -2px rgba(0, 0, 0, 0.2);
          }
        }
      `
    ]
  }

  render() {
    return html`
      ${(!this.client || !this.client._id) ? html`
        <div id="empty">
          <span>Aucun client sélectionné</span>
        </div>
      ` : 

      html`


        <header id="client-name">
          <h2>
            <span>${this.client.name}</span>
            <button
              id="edit-button"
              icon="create"
              aria-label="new"
              client-id="${this.client._id}"
              @click="${this._modifyClient}">
              ${ALCIcons['create']}
              <paper-ripple></paper-ripple>
            </button>
          </h2>
          ${this.client._sales && this.client._sales.name ? html`
            <span class="name-label sale ${this._getClass(this.client._sales._id, this.sales)}">
              ${this.client._sales.name}
            </span>
            <br>
          ` : ''}
          <b id="address">${this.client.address.street}<br>
          ${this.client.address.postalCode} ${this.client.address.city}</b>
        </header>

        <div id="client-detail">
          <p id="client-detail-content">
            ${this.client.phone} <br>
            ${this.client.phone2 ? html`${this.client.phone2}<br>` : ''}
            ${this.client.phone3 ? html`${this.client.phone3}<br>` : ''}
            ${this.client.mail ? html`${this.client.mail}<br>` : ''}
            ${this.client.notes ? html`<span>${this.client.notes}</span>` : ''}
          </p>
        </div>

        ${!this.client.interventions.length ? html`
          <div id="empty">
            <span>Aucune intervention</span>
          </div>
        ` : html`
          <div id="intervention-list">
          ${this.client.interventions.map(item =>
            html`
              <div class="paper-card">
                <div class="card-content">
                  <span class="name-label ${this._getClass(item._tech, this.techs)}">
                    ${this._getTechName(item)}
                  </span>
                  <div class="date">${this._getDate(item.date)}</div>
                  <div class="hour">${formatTime(item.date)}</div>

                  <b>${interventionTypeName(item.type)} </b><br>
                </div>

                ${!(item.otherSales || item.finished) ? html`
                  <div class="card-actions">
                    <a
                      class="action-button"
                      intervention-id="${item._id}"
                      @click="${this._editIntervention}"
                    ><paper-ripple></paper-ripple>modifier</a>
                 </div>
                ` : ''}
                
                ${(!item.otherSales && item.finished) ? html`
                  <div class="card-actions">
                    <a
                      class="action-button"
                      intervention-id="${item._id}"
                      type="${item.type}"
                      @click="${this._viewReport}"
                    ><paper-ripple></paper-ripple>rapport</a>
                  </div>
                ` : ''}
              </div>
            `
          )}
          </div>`
        }
      `}


      
    `;
  }

  static get properties() {
    return {
      client: { type: Object },
      techs: { type: Array },
      sales: { type: Array }
    }
  }

  constructor() {
    super();
    this.client = {};
    this.techs = [];
    this.sales = [];
  }

  _getTechName(client) {
    for (var i = 0; i < this.techs.length; i++)
      if(this.techs[i]._id === client._tech)
        return this.techs[i].name;

    return null;
  }

  _editIntervention(e) {
    var target = e.target;
    var id = target.getAttribute('intervention-id');
    var intervention;
    for (var i = 0; i < this.client.interventions.length; i++) {
      if(this.client.interventions[i]._id === id){
        intervention = this.client.interventions[i];
        break;
      }
    }

    var int = intervention;
    int._client = {
      _id: int._client
    }

    this.dispatchEvent(new CustomEvent('show-new-intervention', {bubbles: true, composed: true, 
      detail: {intervention: intervention}
    }));
  }

  _viewReport(e) {
    var target = e.target;

    this.dispatchEvent(new CustomEvent('show-report', {bubbles: true, composed: true, 
      detail: { id: target.getAttribute('intervention-id') }
    }));
  }

  _getDate(date) {
    if(!date) return;

    date = new Date(date);

    date = date.toLocaleString('fr-FR', { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });

    return date.charAt(0).toUpperCase() + date.slice(1);
  }

  _getClass(userSales, sales) {
    if(!userSales || !sales || !sales.length) return;
    for (var i = 0; i < sales.length; i++)
      if(sales[i]._id === userSales)
        return sales[i].color;
  }

  _modifyClient(e) {
    if(!this.client || !this.client._id) return;

    this.dispatchEvent(new CustomEvent('show-client-edit', {bubbles: true, composed: true, 
      detail: {client: this.client, clientId: e.target.getAttribute('client-id')}
    }));
  }

  _removeIntervention(e) {
    var target = e.target;
    var id = target.getAttribute('intervention-id');
    Intervention.remove(id);
  }
}

customElements.define('client-details', ClientDetails);