'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import './air-air-part.js';
import './air-water-part.js';
import './water-tank-part.js';
import './add-hardware.js';

class InterventionHardwarePart extends LitElement {
  static get styles() {
    return css`
      :host {
        display: grid;
        grid-template-rows: 50px calc(100% - 50px);
        width: 100%;
        background-color: #f3f3f3;
        overflow-y: hidden !important;
      }

      #selector {
        height: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: stretch;
      }

      .type:first-child {
        border-left: 0;
      }

      #selector .iron-selected {
        color: #2b78e4;
      }

      paper-button {
        text-align: center;
        border-left: 0;
        flex-grow: 1;
        width: 100%;
        padding: 0;
        border-radius: 0;
        margin: 0;
        --paper-button-ink-color: #2b78e4;
      }

      #pages > * {
        position: inherit !important;
        box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.1);
        overflow-y: scroll;
        height: 100%;
        padding-top: 30px;
        box-sizing: border-box;
      }

      #pages > *:not(.iron-selected) {
        display: none !important;
      }

      #container {
        border-bottom: 1px solid #ddd;
        background-color: white;
      }
    `;
  }

  renderButtons(items, type, name) {
    return items.map((item, index) => html`
      <paper-button
        class="type ${this.selected === `${type}_${index}` ? 'iron-selected' : ''}"
        key="${type}_${index}"
        @click="${this._selected}"
      >${name}</paper-button>
    `);
  }

  render() {
    return html`
      <div id="container">
        <div id="selector">
          ${this.renderButtons(this.airAir, 'airAir', 'Air/Air')}
          ${this.renderButtons(this.airWater, 'airWater', 'Air/Eau')}
          ${this.renderButtons(this.waterTank, 'waterTank', 'Ballon ECS')}
          <paper-button
            class="type ${this.selected === 'plus' ? 'iron-selected' : ''}"
            key="plus"
            @click="${this._selected}"
          >+</paper-button>
        </div>
      </div>

      <div id="pages">
        ${this.airAir.map((item, index) => html`
          <air-air-part
            class="type ${this.selected === `airAir_${index}` ? 'iron-selected' : ''}"
            type="airAir"
            .hardware="${item}"
            .key="airAir_${index}"
            .index="${index}"
            @hardware-changed="${this._hardwareChanged}"
            @remove-part="${this._removePart}">
          </air-air-part>
        `)}

        ${this.airWater.map((item, index) => html`
          <air-water-part
            class="type ${this.selected === `airWater_${index}` ? 'iron-selected' : ''}"
            type="airWater"
            .hardware="${item}"
            .key="airWater_${index}"
            .index="${index}"
            @hardware-changed="${this._hardwareChanged}"
            @remove-part="${this._removePart}">
          </air-water-part>
        `)}

        ${this.waterTank.map((item, index) => html`
          <water-tank-part
            class="type ${this.selected === `waterTank_${index}` ? 'iron-selected' : ''}"
            type="waterTank"
            .hardware="${item}"
            .key="waterTank_${index}"
            .index="${index}"
            @hardware-changed="${this._hardwareChanged}"
            @remove-part="${this._removePart}">
          </water-tank-part>
        `)}

        <add-hardware
          key="plus"
          class="type ${this.selected === 'plus' ? 'iron-selected' : ''}"
          @add-hardware="${this._newHardwareTrigger}"
        ></add-hardware>
      </div>
    `;
  }

  static get properties() {
    return {
      hardware: { type: Object },
      airAir: { type: Array },
      airWater: { type: Array },
      waterTank: { type: Array },
      initialHardware: { type: Array },
      selected: { type: String }
    }
  }

  constructor() {
    super();
    this.airAir = [];
    this.airWater = [];
    this.waterTank = [];
    this.initialHardware = [];
  }
  
  updated(props) {
    console.log(props)
    
    if(props.has('airAir'))
      this._fireHardwareEvent('airAir');
    if(props.has('airWater'))
      this._fireHardwareEvent('airWater');
    if(props.has('waterTank'))
      this._fireHardwareEvent('waterTank');
    if(props.has('initialHardware'))
      this._init();
  }

  _fireHardwareEvent(type) {
    this.dispatchEvent(new CustomEvent('hardware-changed', {
      bubbles: true, composed: true, detail: { type, value: this[type] }
    }));
  }

  _init() {
    this.initialHardware = this.initialHardware || [];    
    
    this.airAir = this.initialHardware
      .filter(type => type === 'airAir')
      .map(type => this.getNewHardware(type)) || [];

    console.log(this.airAir);
    
    this.airWater = this.initialHardware
      .filter(type => type === 'airWater')
      .map(type => this.getNewHardware(type)) || [];
    
    this.waterTank = this.initialHardware
      .filter(type => type === 'waterTank')
      .map(type => this.getNewHardware(type)) || [];

    this.selected = `${this.initialHardware[0]}_0` || 'plus';
  }

  _removePart(e) {
    const type = e.currentTarget.key.split('_')[0];
    const idx = parseInt(e.currentTarget.key.split('_')[1]);
    
    // Lists all current parts
    const parts = [
      ...this.airAir.map((_, index) => `airAir_${index}`),
      ...this.airWater.map((_, index) => `airWater_${index}`),
      ...this.waterTank.map((_, index) => `waterTank_${index}`),
      'plus'
    ];

    // Selects the next part
    this.selected = parts[parts.indexOf(`${type}_${idx}`) + 1];
    this[type] = this[type].filter((_, index) => idx !== index);
  }

  _newHardwareTrigger(e) {
    if(!e.detail) return;
    this[e.detail].push(this.getNewHardware(e.detail));
    this.selected = `${e.detail}_${this[e.detail].length - 1}`;
  }

  _getName(id) {
    switch(id){
      case 'airAir':
        return 'Air/air';
      case 'airWater':
        return 'Air/eau';
      case 'waterTank':
        return 'Ballon ECS';
    }
  }

  getNewHardware(type) {
    let res = { outGroups: [], type, checks: {}, observations: {} }

    switch(type){
      case 'airAir':
        return Object.assign(res, { indUnits: [], hotWaterTanks: [] });

      case 'airWater':
        return Object.assign(res, { hydrolicModules: [], hotWaterTanks: [] });

      case 'waterTank':
        return Object.assign(res, { tanks: [] });
    }
  }

  _selected(e) { this.selected = e.currentTarget.getAttribute('key') }

  _hardwareChanged(e) {
    const type = e.currentTarget.getAttribute('type');
    this[type][e.currentTarget.index] = e.detail;
    this[type] = [...this[type]];
  }
}

customElements.define('intervention-hardware-part', InterventionHardwarePart);
