'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';
var signaturePad;

class SignaturePadElement extends LitElement {
  static get styles() {
    return [
      css`
        :host {
          display: block;
        }

        #signature {
          min-width: 10px;
          width: 100%;
          height: 300px;
        }

        #signature-container {
          border: 1px solid #ddd;
        }

        paper-button {
          width: 100%;
          padding: 0;
          margin: 0;
          background-color: #ccc;
          color: #666;
          height: 60px;
          border-radius: 0;
        }
      `
    ];
  }

  render() {
    return html`
      <div id="signature-container">
        <canvas id="signature"></canvas>
      </div>

      <paper-button @click="${this.reset}">
        ${ALCIcons['refresh']}
      </paper-button>
    `;
  }

  static get properties() {
    return {
      signature: { type: String },
      signaturePad: { type: Object },
      resized: { type: Boolean }
    }
  }

  constructor() {
    super();
    this.signaturePad = {};
    this.resized = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this._init();
  }

  _init() {
    if(!this.shadowRoot.getElementById('signature'))
      return setTimeout(() => this._init(), 100);

    var canvas = this.shadowRoot.getElementById('signature');

    this.signaturePad = new SignaturePad(canvas);
    document.addEventListener('resize', this._resizeCanvas(canvas));

    setInterval(() => {
      this._resizeCanvas(canvas);
    }, 1000);

    this._resizeCanvas(canvas);

    this.signaturePad.onEnd = e => {
      this.signature = this.signaturePad.toDataURL('image/svg+xml');
      this.dispatchEvent(new CustomEvent('signature-changed', {
        bubbles: true, composed: true, detail: this.signature
      }))
    };
  }

  _resizeCanvas(canvas) {
    if(!canvas.offsetHeight || !canvas.offsetWidth || this.resized)
      return;

    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    var orgHeight = canvas.height;
    var orgWidth = canvas.width;

    if(((canvas.offsetWidth * ratio) === orgWidth) && ((canvas.offsetHeight * ratio) === orgHeight))
      return;

    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    this.resized = true;
  }

  reset() {
    this.signaturePad.clear();
    this.signature = null;
    this.dispatchEvent(new CustomEvent('signature-changed', {
      bubbles: true, composed: true
    }))
  }
}

customElements.define('signature-pad-element', SignaturePadElement);
