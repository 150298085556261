'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-input/paper-textarea.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import { SharedStyles } from '../../shared-styles.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';

class ClientObservations extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        paper-textarea {
          width: 100%;
          display: inline-block !important;
          --paper-input-container-label-floating: {
            font-size: 26px;
          }
        }

        #grades {
          display: block !important;
        }

        .selector {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          margin-bottom: 15px;
          margin-top: 5px;
        }

        .selector > paper-button:first-child {
          border-left: 1px solid #999;
          border-radius: 3px 0 0 3px;
        }

        .selector > paper-button:last-child {
          border-radius: 0 3px 3px 0;
        }

        .selector > paper-button.bad {
          --paper-button-ink-color: var(--paper-red-500);
        }

        .selector > paper-button.meh {
          --paper-button-ink-color: var(--paper-orange-800);
        }

        .selector > paper-button.amazing {
          --paper-button-ink-color: var(--paper-green-600);
        }

        .selector > paper-button.iron-selected.bad {
          color: var(--paper-red-500);
        }

        .selector > paper-button.iron-selected.meh {
          color: var(--paper-orange-800);
        }

        .selector > paper-button.iron-selected.amazing {
          color: var(--paper-green-600);
        }

        .selector > paper-button {
          flex-grow: 1;
          flex-basis: 0;
          border: 1px solid #999;
          border-left: 0;
          margin: 0;
          color: #666;
          border-radius: 0;
        }

        .grade-item {
          font-size: 20px;
        }
      `
    ];
  }

  renderButton(prop, value, icon, color) {
    return html`
      <paper-button
        class="${color} ${this.grades[prop] === value ? 'iron-selected' : ''}"
        .value="${value}"
        .type="${prop}"
        @click="${this._gradeChanged}"
      >
        ${ALCIcons[icon]}
      </paper-button>
    `
  }

  renderSelect(prop, title) {
    return html`
      <div class="grade-item">${title}</div>
      <div class="selector">
        ${this.renderButton(prop, 0, 'sentiment-dissatisfied', 'bad')}
        ${this.renderButton(prop, 1, 'sentiment-neutral', 'meh')}
        ${this.renderButton(prop, 2, 'sentiment-very-satisfied', 'amazing')}
      </div>
    `
  }

  render() {
    return html`
      <div class="paper-card" id="works">
        <div class="card-header">Observations du client</div>
        <div class="content">
          <div id="grades">
            ${this.renderSelect('quality', 'Qualité du travail')}
            ${this.renderSelect('duration', 'Durée du travail')}
            ${this.renderSelect('cleanliness', 'Propreté du travail')}
            ${this.renderSelect('respect', 'Respect du client')}
          </div>

          <paper-textarea
            label="Notes"
            always-float-label
            rows="5"
            .value="${this.notes}"
            @value-changed="${this._notesChanged}"
          ></paper-textarea>
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      notes: { type: String },
      grades: { type: Object }
    }
  }

  constructor() {
    super();
    this.grades = {};
  }

  connectedCallback() {
    super.connectedCallback();
    this.grades = {
      quality: null,
      duration: null,
      cleanliness: null,
      respect: null
    }
  }

  updated(props) {
    if(props.has('grades'))
      this.dispatchEvent(new CustomEvent('grades-changed', {
        bubbles: true, composed: true, detail: this.grades
      }))
    if(props.has('notes'))
      this.dispatchEvent(new CustomEvent('notes-changed', {
        bubbles: true, composed: true, detail: this.notes
      }))
  }

  _gradeChanged(e) {
    this.grades[e.currentTarget.type] = e.currentTarget.value;
    this.grades = {...this.grades};
  }

  _notesChanged(e) {
    this.notes = e.detail.value;
  }
}

customElements.define('client-observations', ClientObservations);
