'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import { ifDefined } from '../../node_modules/lit-html/directives/if-defined';
import './intervention-report-client.js';
import './intervention-report-contract.js';
import './intervention-report-sav.js';
import './intervention-report-obs.js';
import { formatTime, interventionTypeName } from '../utils/utils.js';
import { ALCIcons } from './alc-icons.js';
import { SharedStyles, LightboxStyles } from '../shared-styles.js';
import { DialogStyles, overlayStyleMap, dialogStyleMap } from '../utils/dialog.js';
import * as Intervention from '../api/api-interventions.js';

class InterventionReport extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      LightboxStyles,
      DialogStyles,
      css`
        :host {
          display: flex;
          height: 100%;
          justify-content: center;
        }

        @media print {
          #animated {
            position: static !important;
            box-shadow: none !important;
            max-width: 100% !important;
          }

          #close {
            display: none !important;
          }
        }

        #close {
          cursor: pointer;
        }

        .date {
          width: 30px;
          margin-right: 10px;
        }

        #header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        #header > * {
          width: 45%;
          background-color: white;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 10px;
          margin: 20px 0;
        }

        .info-line {
          display: flex;
          align-items: center;
          margin: 3px 0;
        }

        .info-line svg {
          margin-right: 10px;
          color: #aaa;
        }

        #dates {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        @media only screen 
        and (max-device-width : 600px) {
          #dialog {
            min-height: 100% !important;
          }

          #content {
            min-height: 100% !important;
          }
        }
      `
    ];
  }
  
  render() {
    return html`
      <input id="lightbox" class="lightbox" type="checkbox">
      <label for="lightbox">
        <img id="photo-overlay" src="${ifDefined(this.photo)}">
      </label>

      <div
        id="overlay"
        style="${overlayStyleMap(this.opened)}"
        @click="${this.close}"
      ></div>

      <div
        id="dialog"
        slide-from-bottom
        style="${dialogStyleMap(this.opened)}"
      >
      
        <h2 id="title">
          <span>${interventionTypeName(this.intervention.type)}</span>
          <span id="close" @click="${this.close}">${ALCIcons['close']}</span>
        </h2>

        <div id="content">

          <div id="header">
            <intervention-report-client .client="${this.intervention._client || {}}"></intervention-report-client>
            <div id="dates">
              <div class="info-line">
                ${ALCIcons['add']}
                <div>${this._getDate(this.intervention.createdAt)} par ${this.intervention._sales ? this.intervention._sales.name : ''}</div>
              </div>
              <div class="info-line">
                ${ALCIcons['build']}
                <div>${this._getDate(this.intervention.date)} par ${this.intervention._tech ? this.intervention._tech.name : ''}</div>
              </div>
              <div class="info-line">
                ${ALCIcons['access-time']}
                <div>de ${formatTime(this.intervention.started)} à ${formatTime(this.intervention.finished)}</div>
              </div>
              ${this.intervention.price ? html`
                <div class="info-line">
                  ${ALCIcons['euro-symbol']}
                  <div>${this.intervention.price}€ HT</div>
                </div>
              ` : ''}
            </div>
          </div>

          ${this.intervention.template === 'sav' ? html`
            <intervention-report-sav .intervention="${this.intervention}" @show-photo="${this._showPhoto}"></intervention-report-sav>
          ` : ''}

          ${this.intervention.template === 'contract' ? html`
            <intervention-report-contract .intervention="${this.intervention}" @show-photo="${this._showPhoto}"></intervention-report-contract>
          ` : ''}

          <intervention-report-obs .intervention="${this.intervention}"></intervention-report-obs>
        </div>
      </div>
    `;
  }

  // todo doesn't open the second time
  static get properties() {
    return {
      photo: { type: String },
      interventionId: { type: String },
      intervention: { type: Object },
      opened: { type: Boolean }
    }
  }

  constructor() {
    super();
    this.intervention = {};
  }

  updated(props) {
    if(props.has('interventionId')) {
      this._init();

      if(this.interventionId)
        Intervention.get(this.interventionId)
        .then(res => { this.intervention = res })
    }
  }

  _init() {
    this.opened = true;
  }

  close() {
    return this.dispatchEvent(new CustomEvent('report-closed', {bubbles: true, composed: true}));
  }

  _getDate(date) {
    date = new Date(date).toLocaleString('fr-FR', { 
      weekday: 'short', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });

    return date.charAt(0).toUpperCase() + date.slice(1);
  }

  _showPhoto(e) {
    this.photo = e.detail;
    this.shadowRoot.getElementById('lightbox').checked = true;
  }
}

customElements.define('intervention-report', InterventionReport);
