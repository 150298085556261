'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import '../../node_modules/@polymer/paper-button/paper-button.js';

class UserInput extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
        margin-left: -5px;
      }

      .iron-selected {
        border: 1px solid #2b78e4;
        color: #2b78e4;
      }

      paper-button {
        border-radius: 2px;
        color: #666;
        border: 1px solid #999;
        margin: 5px;
        line-height: 1;
        padding: 8px;
        min-width: 0;
      }

      #container {
        display: flex;
        flex-wrap: wrap;
      }
    `;
  }

  render() {
    return html`
      <div id="container">
        ${this.users.map(user => html`
          <paper-button
            class="${this.userId === user._id ? 'iron-selected' : ''}"
            user-id="${user._id}"
            ?hidden="${!user.active}"
            @click="${this._select}">${user.name}</paper-button>
        `)}
      </div>
    `;
  }

  static get properties() {
    return {
      users: { type: Array },
      userId: { type: String }
    }
  }

  constructor() {
    super();
    this.users = [];
  }

  _select(e) {
    this.userId = e.target.getAttribute('user-id');
    this.dispatchEvent(new CustomEvent('user-changed', { 
      bubbles: true, composed: true, detail: this.userId
    }))
  }
}

customElements.define('user-input', UserInput);
