'use strict';

import { PolymerElement } from '../../node_modules/@polymer/polymer/polymer-element.js';
import { html } from '../../node_modules/@polymer/polymer/lib/utils/html-tag.js';

let subscriber;

function notify() {
  if(subscriber) subscriber._networkChanged();
}

window.addEventListener('online', notify);
window.addEventListener('offline', notify);

class OfflineWorker extends PolymerElement {
  static get template() {
    return html`
    <style>
      :host {
        display: none;
      }
    </style>
`;
  }

  static get is() { 
    return 'offline-worker'; 
  }

  static get properties() {
    return {
      failedRequests: {
        type: Array,
        value: []
      }
    }
  }

  constructor() {
    super();
    subscriber = this;
    let failed = localStorage.getItem('FAILED_REQUESTS');
    this.failedRequests = failed ? JSON.parse(failed) : [];
  }

  connectedCallback() {
    super.connectedCallback();

    if(this.failedRequests && this.failedRequests.length)
      this._networkChanged();
  }

  _updateLocalStorage() {
    localStorage.setItem('FAILED_REQUESTS', JSON.stringify(this.failedRequests));
  }

  _networkChanged() {
    if(!navigator.onLine || !this.failedRequests || !this.failedRequests.length) return;

    var failedAgain = [];
    var i = 0;
    var that = this;

    this.failedRequests.map(async(req, i) => {
      if(!navigator.onLine)
        return failedAgain.push(req);

      var res = await fetch(req.url, {
        body: JSON.stringify(req.body),
        headers: req.headers,
        method: req.method
      });

      var response = await res.json();

      if(res.status < 400)
        return this.dispatchEvent(new CustomEvent(req.onSuccess, {bubbles: true, composed: true, 
          detail: response
        }));

      if(navigator.onLine)
        return this.dispatchEvent(new CustomEvent(req.onError, {bubbles: true, composed: true, 
          detail: {
            body: req.body,
            message: response.message
          }
        }));

      return failedAgain.push(req);
    });

    this.set('failedRequests', failedAgain);
    this._updateLocalStorage();
  }

  add(query) {
    this.push('failedRequests', query);
    this._updateLocalStorage();
  }
}

customElements.define(OfflineWorker.is, OfflineWorker);
