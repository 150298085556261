'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import '../../../node_modules/@polymer/paper-input/paper-input.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';

class SavWorksPart extends LitElement {
  static get styles() {
    return [
      css`
        #add {
          background-color: #3A6FDF;
          color: white;
          margin: auto;
          font-weight: 700;
          font-size: 20px;
          display: block;
          text-align: center;
        }

        .hardware-line {
          display: flex;
          flex-direction: row;
        }

        paper-input {
          display: inline-block !important;
          flex-grow: 1;
          padding: 15px;
        }

        .delete {
          background-color: #b71c1c;
          color: white;
          font-weight: 500;
          width: 30px;
          height: 30px;
          align-self: center;
        }

        @media only screen 
        and (min-device-width : 320px) 
        and (max-device-width : 1000px) {
          .hardware-line {
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-radius: 5px;
            border: 1px solid #ddd;
            margin-bottom: 15px;
          }

          paper-input {
            padding: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
          }

          .delete {
            width: 90%;
            margin: auto;
          }

          .content {
            padding: 1rem;
          }
        }
      `
    ];
  }

  renderInput(prop, index, label) {
    return html`
      <paper-input
        label="${label}"
        ?required
        .index="${index}"
        .prop="${prop}"
        .value="${this.hardware[index][prop]}"
        @value-changed="${this._inputValueChanged}"
      ></paper-input>
    `
  }

  render() {
    return html`
      <div id="parts">
        ${this.hardware.map((piece, index) => html`
          <div class="hardware-line" index="[[index]]">
            ${this.renderInput('brand', index, 'Marque')}
            ${this.renderInput('model', index, 'Modèle')}
            ${this.renderInput('serialNumber', index, 'N˚ de série')}
            ${this.renderInput('piecesToOrder', index, 'Pièces à commander')}
            ${this.renderInput('hotlineCode', index, 'Code Hotline')}
            <paper-button class="delete" .index="${index}" @click="${this._remove}">
              ${ALCIcons['delete']}
            </paper-button>
          </div>
        `)}
      </div>

      <paper-button id="add" @click="${this._addHardware}">+ Ajouter</paper-button>
    `;
  }

  static get properties() {
    return {
      hardware: { type: Array }
    }
  }

  constructor() {
    super();
    this.hardware = [this._getEmptyHardware()];
  }

  updated(props) {
    if(props.has('hardware')) {
      this.dispatchEvent(new CustomEvent('hardware-changed', {
        bubbles: true, composed: true, detail: this.hardware
      }));
    }
  }

  _getEmptyHardware() {
    return {
      brand: null,
      piecesToOrder: null,
      model: null,
      serialNumber: null,
      hotlineCode: null
    };
  }

  _addHardware() {
    this.hardware = [...this.hardware, this._getEmptyHardware()];
  }

  _remove(e) {
    this.hardware = this.hardware.filter((i, idx) => e.currentTarget.index !== idx);
  }

  _inputValueChanged(e) {
    this.hardware[e.currentTarget.index][e.currentTarget.prop] = e.detail.value;
    this.hardware = [...this.hardware];
  }
}

customElements.define('sav-works-part', SavWorksPart);
