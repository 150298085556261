'use strict';

export async function create(user) {
  for(var k in user)
    if(!user[k])
      delete user[k];

  if(!user.name ||
    !user.username ||
    !user.password ||
    !user.type)
    throw 'Champs manquants';

  let res = await fetch(`${window.API_HOST}users`, {
    method: 'POST',
    headers: new Headers(window.API_HEADERS),
    body: JSON.stringify(user)
  });

  let json = await res.json();

  if(res.ok) return json;
  throw json.message;
}

export async function update(id, user) {
  for(var k in user)
    if(typeof user[k] === 'undefined' || user[k] === null)
      delete user[k];

  if(!user.name ||
    !user.username)
    throw 'Champs manquants';

  let res = await fetch(`${window.API_HOST}users/${id}`, {
    method: 'PUT',
    headers: new Headers(window.API_HEADERS),
    body: JSON.stringify(user)
  });

  let json = await res.json();

  if(res.ok) return json;
  throw json.message;
}

export async function get() {
  let url = new URL(`${window.API_HOST}users`);

  let res = await fetch(url, { headers: new Headers(window.API_HEADERS) });
  let json = await res.json();

  if(!res.ok) throw json.message;

  var admins = [];
  var techs = [];
  var sales = [];

  var colors = ['red', 'purple', 'indigo', 'light-blue', 'teal', 'light-green', 'yellow', 'orange', 'brown', 'blue-grey', 'pink', 'deep-purple', 'blue', 'cyan', 'green', 'lime', 'amber', 'deep-orange'];

  for (var i = 0; i < json.length; i++) {
    switch(json[i].type){
      case 'admin':
        json[i].color = 'black-900';
        admins.push(json[i]);
        break;
      case 'sales':
        json[i].color = colors[colors.length - 1 - sales.length] + '-200';
        sales.push(json[i]);
        break;
      case 'tech':
        json[i].color = colors[techs.length] + '-800';
        techs.push(json[i]);
        break;
    }
  }

  return {users: json, admins, techs, sales};  
}

export async function login(body) {
  let res = await fetch(`${window.API_HOST}users/login`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({'content-type': 'application/json'}),
  });

  let json = await res.json();

  if(res.ok) return json;
  throw json.message; 
}

export async function me() {
  let res = await fetch(`${window.API_HOST}users/me`, {
    method: 'GET',
    headers: new Headers(window.API_HEADERS),
  });

  let json = await res.json();

  if(res.ok) return json;
  throw json.message; 
}