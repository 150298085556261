'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import { ALCIcons } from './alc-icons.js';
import './intervention-report-ref.js';
import './intervention-report-out-group.js';

class InterventionReportContract extends LitElement {
  static get styles() {
    return [
      css`
        :host {
          display: block;
        }

        .hardware {
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        
        .part {
          padding: 13px;
        }

        .part:not(:last-child){
          border-bottom: 1px solid #ccc;
        }

        img {
          max-width: 300px;
          box-sizing: border-box;
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-width: 1px;
          border-style: solid;
          border-color: rgb(204, 204, 204);
          border-image: initial;
          border-radius: 5px;
          padding: 5px;
        }      

        .voltages {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding-top: 0;
        }

        .measures {
          padding-top: 0;
        }

        .voltage-part {
          flex-grow: 1;
        }

        .voltage {
          color: #444;
          font-weight: 300;
          margin-right: 10px;
        }

        .small-title {
          padding: 13px;
          padding-bottom: 5px;
          display: inline-block;
          text-transform: uppercase;
          font-weight: 500;
        }

        .third {
          width: 33%;
          display: inline-block;
        }

        .padded {
          /*margin-left: 10px;*/
        }

        .refs {
          padding: 0 !important;
        }

        .hardware {
          margin-bottom: 10px;
        }

        h3 {
          font-size: 20px;
        }

        h2 {
          padding: 10px;
          background-color: #333;
          color: white;
        }

        .check {
          display: flex;
        }

        .check svg {
          margin-right: 10px;
          color: #aaa;
        }

        #photos {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          row-gap: 10px;
          column-gap: 10px;
        }

        @media only screen and (max-device-width : 600px) {
          #photos {
            grid-gap: 15px;
            grid-template-columns: initial;
          }
        }
      `
    ];
  }

  displayPhotos(photos) {
    return photos && photos.length ? html`
      <h3>Photos</h3>
      <div id="photos">
        ${photos.map(item => html`
          <img @click="${this.showPhoto}" src="${this._getImage(item)}" decoding="async"></img>
        `)}
      </div>
    ` : '';
  }

  render() {
    return html`
    ${this.intervention.airAir.length ? html`
      <h2>Air / air</h2>

      <div class="padded">

        <intervention-report-out-group
          .outGroups="${this.intervention.airAir[0].outGroups}"
        ></intervention-report-out-group>

        <h3>Unités intérieures</h3>
        ${this.intervention.airAir[0].indUnits.map(item => html`
          <div class="hardware">
            <div class="refs part">
              <intervention-report-ref .hardware="${item}"></intervention-report-ref>
            </div>
            
            <b class="small-title">Températures</b>
            <div class="measures part">
              <span class="voltage">Sortie</span> ${item.outTemp} °C
            </div>
          </div>
        `)}


        <h3>Ballons ECS</h3>
        ${this.intervention.airAir[0].hotWaterTanks.map(item => html`
          <div class="hardware">
            <div class="refs part">
              <intervention-report-ref .hardware="${item}"></intervention-report-ref>
            </div>
          </div>
        `)}

        <h3>Vérifications</h3>

        <div class="check">
          ${ALCIcons[this._getIcon(this.intervention.airAir[0].checks.electirConn)]}
          <div>Connexions électriques</div>
        </div>
        <div class="check">
          ${ALCIcons[this._getIcon(this.intervention.airAir[0].checks.tightnessContr)]}
          <div>Contrôle d'étanchéité du circuit frigorique</div>
        </div>
        <div class="check">
          ${ALCIcons[this._getIcon(this.intervention.airAir[0].checks.liftPump)]}
          <div>Vérification des pompes de relevage</div>
        </div>
        <div class="check">
          ${ALCIcons[this._getIcon(this.intervention.airAir[0].checks.cleanFilters)]}
          <div>Nettoyage des filtres</div>
        </div>
        <div class="check">
          ${ALCIcons[this._getIcon(this.intervention.airAir[0].checks.filterReplace)]}
          <div>Remplacement des filtres et des piles (optionnel)</div>
        </div>
        <div class="check">
          ${ALCIcons[this._getIconGaz(this.intervention.airAir[0].checks)]}
          
          <div>Recharge de gaz (optionel)</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          ${this.intervention.airAir[0].checks.gazReloadType ? html`
            <div>Type: ${this.intervention.airAir[0].checks.gazReloadType}</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          ` : ''}

          ${this.intervention.airAir[0].checks.gazReloadQuantity ? html`
            <div>Type: ${this.intervention.airAir[0].checks.gazReloadQuantity} kgs</div>
          ` : ''}
        </div>

        
        ${this.intervention.airAir[0].observations.notes ? html`
          <h3>Notes</h3>
          ${this.intervention.airAir[0].observations.notes}
        ` : ''}

        ${this.displayPhotos(this.intervention.airAir[0].observations.photos)}
      </div>
    ` : ''}

    ${this.intervention.airWater.length ? html`
      <h2>Air / eau</h2>

      <div class="padded">
        
        <intervention-report-out-group .outGroups="${this.intervention.airWater[0].outGroups}"></intervention-report-out-group>

        <h3>Modules hydroliques</h3>
        ${this.intervention.airWater[0].hydrolicModules.map(item => html`
          <div class="hardware">
            <div class="refs part">
              <intervention-report-ref .hardware="${item}"></intervention-report-ref>
            </div>
            
            <b class="small-title">Températures</b>
            <div class="measures part">
              <span class="voltage">Retour</span> ${item.tempReturn} °C
            </div>
          </div>
        `)}

        <h3>Ballon ECS</h3>
        ${this.intervention.airWater[0].hotWaterTanks.map(item => html`
          <div class="hardware">
            <div class="refs part">
              <intervention-report-ref .hardware="${item}"></intervention-report-ref>
            </div>
          </div>
        `)}

        <h3>Vérifications</h3>

        <div class="check">
          ${ALCIcons[this._getIcon(this.intervention.airWater[0].checks.electirConn)]}
          <div>Connexions électriques</div>
        </div>
        <div class="check">
          ${ALCIcons[this._getIcon(this.intervention.airWater[0].checks.tightnessContr)]}
          <div>Contrôle d'étanchéité du circuit frigorique</div>
        </div>
        <div class="check">
          ${ALCIcons[this._getIcon(this.intervention.airWater[0].checks.circCheck)]}
          <div>Vérification du circulateur</div>
        </div>
        <div class="check">
          ${ALCIcons[this._getIconGaz(this.intervention.airWater[0].checks)]}
          
          <div>Recharge de gaz (optionel)</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          ${this.intervention.airWater[0].checks.gazReloadType ? html`
            <div>Type: ${this.intervention.airWater[0].checks.gazReloadType}</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          ` : ''}

          ${this.intervention.airWater[0].checks.gazReloadQuantity ? html`
            <div>Quantité: ${this.intervention.airWater[0].checks.gazReloadQuantity} kgs</div>
          ` : ''}
        </div>

        ${this.intervention.airWater[0].observations.notes ? html`
          <h3>Notes</h3>
          ${this.intervention.airWater[0].observations.notes}
        ` : ''}

        ${this.displayPhotos(this.intervention.airWater[0].observations.photos)}
      </div>
    ` : ''}

    ${this.intervention.waterTank.length ? html`
      <h2>Ballons ECS</h2>

      <div class="padded">
        
        <intervention-report-out-group .outGroups="${this.intervention.waterTank[0].outGroups}"></intervention-report-out-group>

        <h3>Cuves</h3>
        ${this.intervention.waterTank[0].tanks.map(item => html`
          <div class="hardware">
            <div class="refs part">
              <intervention-report-ref .hardware="${item}"></intervention-report-ref>
            </div>
            
            <b class="small-title">Températures</b>
            <div class="measures part">
              <span class="voltage">ECS</span> ${item.tempReturn} °C
            </div>
          </div>
        `)}
        
        <h3>Vérifications</h3>

        <div class="check">
          ${ALCIcons[this._getIcon(this.intervention.waterTank[0].checks.electirConn)]}
          <div>Connexions électriques</div>
        </div>
        <div class="check">
          ${ALCIcons[this._getIcon(this.intervention.waterTank[0].checks.tightnessContr)]}
          <div>Contrôle d'étanchéité du circuit frigorique</div>
        </div>
        <div class="check">
          ${ALCIcons[this._getIconGaz(this.intervention.waterTank[0].checks)]}
          
          <div>Recharge de gaz (optionel)</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          ${this.intervention.waterTank[0].checks.gazReloadType ? html`
            <div>Type: ${this.intervention.waterTank[0].checks.gazReloadType}</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          ` : ''}

          ${this.intervention.waterTank[0].checks.gazReloadQuantity ? html`
            <div>Quantité: ${this.intervention.waterTank[0].checks.gazReloadQuantity} kgs</div>
          ` : ''}
        </div>

        ${this.intervention.waterTank[0].observations.notes ? html`
          <h3>Notes</h3>
          ${this.intervention.waterTank[0].observations.notes}
        ` : ''}

        ${this.displayPhotos(this.intervention.waterTank[0].observations.photos)}
      </div>
    ` : ''}
    `;
  }

  static get properties() {
    return {
      intervention: { type: Object }
    }
  }

  constructor() {
    super();
  }

  _getIcon(check) {
    return check ? 'done' : 'clear';
  }

  _getImage(image) {
    if(image) return `${window.IMAGE_HOST}${image}`;
  }

  _getIconGaz(obs) {
    if(!obs) return;
    return (obs.gazReloadType || obs.gazReloadQuantity)  ? 'done' : 'clear';
  }

  showPhoto(e) {
    this.dispatchEvent(new CustomEvent('show-photo', {bubbles: true, composed: true, 
      detail: e.target.getAttribute('src')
    }));
  }
}

customElements.define('intervention-report-contract', InterventionReportContract);
