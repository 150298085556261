'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import '../../node_modules/@polymer/paper-button/paper-button.js';

class ContractInitialHardware extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
      }

      #buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
      }

      paper-button {
        flex-grow: 1;
        flex-basis: 0;
        border: 1px solid #999;
        border-left: 0;
        margin: 0;
        color: #666;
        border-radius: 0;
      }

      #buttons > paper-button:first-child {
        border-left: 1px solid #999;
        border-radius: 3px 0 0 3px;
      }

      #buttons > paper-button:last-child {
        border-radius: 0 3px 3px 0;
      }

      .active {
        background-color: #2b78e4;
        color: white;
      }
    `;
  }

  render() {
    return html`
      <div id="buttons">
        <paper-button
          id="airAir"
          key="airAir"
          @click="${this._toggle}"
          class="${this.initialHardware.includes('airAir') ? 'active' : ''}"
        >Air / air</paper-button>
        
        <paper-button
          id="airWater"
          key="airWater"
          @click="${this._toggle}"
          class="${this.initialHardware.includes('airWater') ? 'active' : ''}"
        >Air / eau</paper-button>
        
        <paper-button
          id="waterTank"
          key="waterTank"
          @click="${this._toggle}"
          class="${this.initialHardware.includes('waterTank') ? 'active' : ''}"
        >Ballon ECS</paper-button>
      </div>
    `;
  }

  static get properties() {
    return {
      initialHardware: { type: Array },
    }
  }

  constructor() {
    super();
    this.initialHardware = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.initialHardware = this.initialHardware || [];
  }

  _toggle(e) {
    var target = e.target;
    var type = target.getAttribute('key');
    var hw = this.initialHardware || [];

    if(hw.indexOf(type) > -1){
      hw.splice(hw.indexOf(type), 1);
      this.shadowRoot.getElementById(type).className = '';
    } else {
      hw.push(type);
      this.shadowRoot.getElementById(type).className = 'active';
    }

    this.initialHardware = hw;
    this.dispatchEvent(new CustomEvent('initial-hardware-changed', {
      bubbles: true, composed: true, detail: hw
    }));
  }
}

customElements.define('contract-initial-hardware', ContractInitialHardware);
