'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-input/paper-input.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import { DialogStyles, overlayStyleMap, dialogStyleMap } from '../../utils/dialog.js';
import { SharedStyles } from '../../shared-styles.js';
import * as User from '../../api/api-users.js';

class UserEdit extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      DialogStyles,
      css`
        :host {
          display: flex;
          height: 100%;
          justify-content: center;
          min-width: 100%;
        }

        paper-input {
          display: block;
        }

        #type, #buttons {
          display: flex;
          flex-direction: row;
        }

        #type > paper-button {
          flex-grow: 1;
          flex-basis: 0;
          border: 1px solid #999;
          border-left: 0;
          margin: 0;
          color: #666;
          border-radius: 0;
        }

        #type > paper-button:first-child {
          border-left: 1px solid #999;
          border-radius: 3px 0 0 3px;
        }

        #type > paper-button:last-child {
          border-radius: 0 3px 3px 0;
        }

        #type > paper-button.iron-selected {
          color: #2b78e4;
        }

        .spaced {
          margin: 1em 0 !important;
        }

        h2 {
          padding-top: 24px !important;
        }

        #error-message {
          color: #FE0006;
        }

        @media only screen 
        and (max-device-width : 600px) {
          #mobile-space {
            flex-grow: 1;
          }
        }

        .switch {
          position: relative;
          display: inline-block;
          width: 60px;
          height: 34px;
        }

        .switch input { 
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: .4s;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: .4s;
        }

        input:checked + .slider {
          background-color: #2196F3;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #2196F3;
        }

        input:checked + .slider:before {
          transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider.round {
          border-radius: 34px;
          pointer-events: none;
        }

        .slider.round:before {
          border-radius: 50%;
        }

        #enabled {
          font-size: 20px;
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
        }

        #buttons {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 1em;
          padding-top: 20px;
          margin: 1.5em 0;
        }

        #buttons > paper-button {
          margin: 0;
        }

        #cancel {
          border: 1px solid #ccc;
        }

        #submit {
          background-color: #3A6FDF;
          color: white;
        }
      `
    ];
  }

  render() {
    return html`
      <div
        id="overlay"
        style="${overlayStyleMap(this.opened)}"
        @click="${this.close}"
      ></div>

      <div
        id="dialog"
        slide-from-bottom
        style="${dialogStyleMap(this.opened)}"
      >
        <h2 id="title">${(this.user && this.user.name) ? this.user.name : 'Nouvel Utilisateur'}</h2>

        <div id="content">

          <paper-input
            label="Nom"
            type="text"
            .value="${this._user.name}"
            @value-changed="${this._nameChanged}"
            required
          ></paper-input>
          
          <paper-input
            label="Adresse e-mail"
            type="email"
            .value="${this._user.mail}"
            @value-changed="${this._mailChanged}"
          ></paper-input>
          
          <paper-input
            label="Nom d'utilisateur"
            type="text"
            .value="${this._user.username}"
            @value-changed="${this._usernameChanged}"
            required
          ></paper-input>
          
          <paper-input
            label="Mot de passe"
            minlength="6"
            .value="${this._user.password}"
            @value-changed="${this._passwordChanged}"
            type="password"
            required
          ></paper-input>

          ${!this.user._id ? html`
            <h3 class="spaced">Type d'utilisateur</h3>
            <div id="type">
              <paper-button
                type="admin"
                @click="${this._typeChanged}"
                class="${this._user.type === 'admin' ? 'iron-selected' : ''}"
              >Administrateur</paper-button>
              <paper-button
                type="sales"
                @click="${this._typeChanged}"
                class="${this._user.type === 'sales' ? 'iron-selected' : ''}"
              >Commercial</paper-button>
              <paper-button
                type="tech"
                @click="${this._typeChanged}"
                class="${this._user.type === 'tech' ? 'iron-selected' : ''}"
              >Technicien</paper-button>
            </div>
          ` : ''}

          <div id="enabled">
            <span>Actif</span>
            <label class="switch">
              <input id="native-active" type="checkbox" @change="${this._updateActive}" ?checked="${this._user.active}">
              <span class="slider round"></span>
            </label>
          </div>

          <div id="mobile-space"></div>

          <div id="error-message">${this._error}</div>

          <div id="buttons">
            <paper-button id="cancel" @click="${this.close}">annuler</paper-button>
            <paper-button id="submit" @click="${this._submit}">${(this.user && this.user.name) ? 'modifier' : 'créer'}</paper-button>
          </div>

        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      _error: { type: String },
      _user: { type: Object },
      _passwordPlaceholder: { type: String },
      user: { type: Object },
      _user: { type: Object },
      opened: { type: Boolean }
    }
  }

  constructor() {
    super();
    this._user = {
      name: null,
      username: null,
      password: null,
      mail: null,
      type: null,
      active: true
    };

    // makes the user understand that there is 
    // already a password on update
    this._passwordPlaceholder = 'placeholder';
  }


  connectedCallback() {
    super.connectedCallback();
    this.opened = true;
  }

  updated(props) {
    if(props.has('user')) {
      if(typeof this.user === 'object')
        this._user = Object.assign(this.user, {password: this.placeholder});
      else
        this._user = {};  
    }

    if(props.has('opened')) {
      this._error = '';
    }
  }

  _nameChanged(e){
    this._user = {...this._user, name: e.detail.value }
  }
  _mailChanged(e){
    this._user = {...this._user, mail: e.detail.value}
  }
  _usernameChanged(e){
    this._user = {...this._user, username: e.detail.value}
  }
  _passwordChanged(e){
    this._user = {...this._user, password: e.detail.value}
  }
  _typeChanged(e) {
    this._user = {...this._user, type: e.currentTarget.getAttribute('type')}
  }
  _updateActive(e) {
    this._user = {...this._user, active: e.currentTarget.value}
  }

  _submit() {
    if(!this.user || !this.user._id){
      User.create(this._user)
      .then(user => { this._postSuccess(user) })
      .catch(e => { this._displayError(e) })
    } else {
      let user = this._user;

      if(user.password === this._passwordPlaceholder)
        delete user.password;

      User.update(this.user._id, user)
      .then(user => { this._postSuccess(user) })
      .catch(e => { this._displayError(e) })
    }
  }

  _updateActive(e) {
    this._user.active = e.target.checked;
  }

  _displayError(e) {
    this._error = e;
  }

  _postSuccess() {
    this.dispatchEvent(new CustomEvent('user-added', {bubbles: true, composed: true}));
    this.opened = false;
  }

  close() {
    this.dispatchEvent(new CustomEvent('close-user-edit', {bubbles: true, composed: true}));
    this.opened = false;
  }
}

customElements.define('user-edit', UserEdit);
