'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import '../../shared-elements/appointment-card.js';
import { SharedStyles } from '../../shared-styles.js';
import { webSocket } from '../../utils/utils.js';
import * as Intervention from '../../api/api-interventions.js';

let subscriber;

function notify() {
  if(subscriber) subscriber._getInterventions();
}

window.addEventListener('online', notify);
window.addEventListener('offline', notify);

class CalendarView extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
          height: 100%;
          max-height: 100%;
          overflow-y: auto;
        }

        paper-button {
          width: 100%;
          text-transform: uppercase;
          text-align: center;
          margin: 0;
          font-size: 1.5rem;
          font-weight: 500;
          border-radius: 0;
          color: white;
          background-color: #3A6FDF;
          border-radius: 0 0 5px 5px;
        }

        appointment-card {
          padding: 20px;
          max-width: 100%;
          flex-grow: 1;
          box-sizing: border-box;
          flex-basis: 0;
          width: 100%;
          min-width: 100%;
        }

        #empty {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          font-size: 26px;
          font-weight: 300;
          color: #ccc;
          text-align: center;
        }
      `
    ]
  }

  render() {
    return html`
      ${this.interventions.length ?
        this.interventions.map(intervention => html`
          <appointment-card
            id="${intervention._id}"
            .name="${intervention._client.name}"
            .address="${intervention._client.address}"
            .phone="${intervention._client.phone}"
            .date="${intervention.date}"
            .price="${intervention.price}"
            .notes="${intervention.notes}"
            .started="${intervention.started}"
            .finished="${intervention.finished}"
            .type="${intervention.type}"
            .offline=${intervention.offline}
          >
            ${!intervention.finished && !intervention.offline ? html`
              <paper-button @click="${this.startIntervention}">commencer</paper-button>
            ` : ''}
          </appointment-card>
        `)
      : html`
        <div id="empty">
          Aucune intervention
        </div>
      `}
    `;
  }

  static get properties() {
    return {
      interventions: { type: Array }
    }
  }

  constructor() {
    super();
    this.interventions = [];
    webSocket({ url: window.API_WEB_SOCKET, onMessage: this._socketMessage.bind(this) });
  }

  connectedCallback() {
    super.connectedCallback();
    this._getInterventions();
    subscriber = this;
  }

  _socketMessage({ data }) {
    if(data === 'refresh-interventions')
      this._getInterventions();
  }

  async _getInterventions() {
    if(!navigator.onLine){
      const offline = localStorage.getItem('OFFLINE_REQUESTS');

      this.interventions = (JSON.parse(
        localStorage.getItem('OFFLINE_INTERVENTIONS')
      ) || []).map(intervention => 
        offline.includes(intervention._id) 
          ? {...intervention, offline: true} 
          : intervention
      );
    } else {
      try {
        this.interventions = await Intervention.get(null, {
          from: new Date(new Date().setHours(0,0,0,0)),
          to: new Date(new Date().setHours(23,59,59,0))
        });

        localStorage.setItem(
          'OFFLINE_INTERVENTIONS', 
          JSON.stringify(this.interventions)
        );
      } catch(e) {
        const offline = localStorage.getItem('OFFLINE_REQUESTS');

        this.interventions = (JSON.parse(
          localStorage.getItem('OFFLINE_INTERVENTIONS')
        ) || []).map(intervention => 
          offline.includes(intervention._id) 
            ? {...intervention, offline: true} 
            : intervention
        );

        window.setTimeout(() => this._getInterventions(), 5 * 1000);
      }
    }
  }

  startIntervention(e) {
    var id = e.target.parentNode.id;
    var intervention;

    for (var i = 0; i < this.interventions.length; i++) {
      if(this.interventions[i]._id === id){
        intervention = this.interventions[i];
      }
    }

    this.dispatchEvent(new CustomEvent('intervention-selected', {
      bubbles: true, composed: true, detail: intervention
    }))
  }

  updateIntervention(intervention) {
    this.interventions = this.interventions.map(i => {
      if(i._id === intervention._id)
        return intervention;
      else return i;
    });
  }
}

customElements.define('calendar-view', CalendarView);
