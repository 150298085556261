'use strict';

{
  customElements.define('google-map', class extends HTMLElement {
    static get observedAttributes() {
      return ['api-key', 'zoom', 'latitude', 'longitude', 'map-options', 'disable-default-ui', 'markers', 'delay-start'];
    }

    attributeChangedCallback(name, oldVal, val) {
      switch (name) {
        case 'api-key':
          this.apiKey = val;
          break;
        case 'zoom':
        case 'latitude':
        case 'longitude':
          if(parseFloat(val)) this[name] = parseFloat(val);
          break;
        case 'markers':
          this.markers = JSON.parse(val);
          this.setMarkers(this.markers);
          break;
        case 'disable-default-ui':
          this.disableDefaultUI = Boolean(val);
          break;
        case 'programmatic-init':
          this.programmaticInit = Boolean(val);
          break;
        case 'map-options':
          this.mapOptions = JSON.parse(val);
          break
        case 'delay-start':
          this.delayStart = parseFloat(val) || 0;
          break;
      }
    }

    constructor() {
      super();

      this.map = null;
      this.apiKey = null;
      this.zoom = null;
      this.latitude = null;
      this.longitude = null;
      this.disableDefaultUI = null;
      this.markers = [];
      this.mapOptions = {};
      this._markersAdded = [];
      this.delayStart = 0;
      this.programmaticInit = false;
    }

    connectedCallback() {
      if(!this.programmaticInit) this.init();
    }

    init(){
      if(typeof window.google === 'undefined')
        return setTimeout(() => this.init(), 100);
      
      setTimeout(() => {
        if (!this.mapOptions.zoom) {
          this.mapOptions.zoom = this.zoom || 0;
          this.mapOptions.disableDefaultUI = this.disableDefaultUI || false;
        }
        if (!this.mapOptions.center) {
          this.mapOptions.center = {
            lat: this.latitude || 0,
            lng: this.longitude || 0
          };
        }

        if(!this.mapOptions.gestureHandling)
          this.mapOptions.gestureHandling = 'cooperative';

        this.map = new google.maps.Map(this, this.mapOptions);
        this.setMarkers();

        this.dispatchEvent(new CustomEvent('google-map-ready', { detail: this.map }));
      }, this.delayStart);
    }

    disconnectedCallback() {
      this.clearMarkers();
      this.map = null;
    }

    setMarkers() {
      if(!this.map) return;

      this.clearMarkers();

      let bounds = new google.maps.LatLngBounds();

      for(let marker of this.markers) {
        if(marker.icon){
          marker.icon.size = marker.icon.size ? 
            new google.maps.Size(marker.icon.size[0], marker.icon.size[1]) : undefined ;
          
          marker.icon.scaledSize = marker.icon.scaledSize ? 
            new google.maps.Size(marker.icon.scaledSize[0], marker.icon.scaledSize[1]) : undefined;
        }

        this._markersAdded.push(new google.maps.Marker({
          position: {
            lat: marker.lat,
            lng: marker.lng
          },
          map: this.map,
          icon: marker.icon
        }));

        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
      };

      this.map.fitBounds(bounds);
      this.map.panToBounds(bounds);

      var listener = google.maps.event.addListener(this.map, 'idle', function() { 
        if(!this.map) return;

        let currentZoom = this.map.getZoom();
        let currentCenter = this.map.getCenter();
        
        if (currentZoom > this.zoom || !currentZoom) 
          this.map.setZoom(this.zoom); 

        if (!currentCenter.lat() && currentCenter.lng() === 180) 
          this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude)); 

        google.maps.event.removeListener(listener); 
      }.bind(this, this.map));
    }

    clearMarkers() {
      for (var i = 0; i < this._markersAdded.length; i++) {
        this._markersAdded[i].setMap(null);
      }
      this._markersAdded = [];
    }
  });
}
