'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import { SharedStyles } from '../../shared-styles.js';

class PriceDetail extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
          padding-top: 30px;
        }

        paper-card {
          width: 100%;
        }

        .card-content {
          padding: 0;
        }

        .header {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-content: flex-start;
          align-items: center;
          padding: 1.5rem;
          border-bottom: 1px solid #ddd;
          height: 3rem;
        }

        .content {
          padding: 1.5rem;
        }

        .card-subtitle, .item-detail, #total {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .card-subtitle > span {
          font-size: 1.3rem;
          font-weight: 500;
          margin-bottom: 0;
          margin-top: 3px;
        }

        span {
          font-size: 1.2rem;
          font-weight: 300;
        }

        #total span {
          font-size: 1.6rem;
          font-weight: 500;
        }

        #total{
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid #aaa;
        }

        .price {
          text-align: right;
        }

        .item-detail > .detail-name {
          padding-left: 30px;
        }
      `
    ];
  }
  
  render() {
    return html`
      <div class="paper-card">
        <div class="card-header">Contrat d'entretien</div>
        <div class="content">
          ${(this.refreshPrice() || []).map(item => html`
            <div class="card-subtitle">
              <span>${item.name}</span>
              <span class="price">${item.price} €</span>
            </div>

            ${(item.items || []).map(item => html`
              <div class="item-detail">
                <span class="detail-name">${item.name}</span>
                <span class="price">${item.price} €</span>
              </div>
            `)}
          `)}

          <div id="total">
            <span>Total</span>
            <span class="price">${this.price} €</span>
          </div>
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      airAir: { type: Array },
      airWater: { type: Array },
      waterTank: { type: Array },
      price: { type: Number }
    }
  }

  constructor() {
    super();
    this.price = 0;
    this.airAir = [];
    this.airWater = [];
    this.waterTank = [];
  }

  refreshPrice() {
    if(!this.airAir || !this.airWater || !this.waterTank)
      return;

    var price = 0;
    var detail = [];

    for (var i = 0; i < this.airAir.length; i++) {
      var item = {
        name: 'Système air / air',
        price: (this.airAir[i].indUnits.length * 45) + (this.airAir[i].hotWaterTanks.length * 90),
        items: []
      };

      item.items.push({
        name: 'Unité intérieure x ' + this.airAir[i].indUnits.length,
        price: (this.airAir[i].indUnits.length * 45)
      });

      item.items.push({
        name: 'Ballon ECS x ' + this.airAir[i].hotWaterTanks.length,
        price: (this.airAir[i].hotWaterTanks.length * 90)
      });

      detail.push(item);

      price += (this.airAir[i].indUnits.length * 45);
      price += (this.airAir[i].hotWaterTanks.length * 90);
    }

    for (var i = 0; i < this.airWater.length; i++) {
      detail.push({
        name: 'Système air / eau',
        price: (this.airWater.length * 250),
        items: []
      });
    }
    
    for (var i = 0; i < this.waterTank.length; i++) {
      detail.push({
        name: 'Ballon ECS',
        price: (this.waterTank.length * 120),
        items: []
      });
    }

    price += (this.airWater.length * 250);
    price += (this.waterTank.length * 120);

    this.price = price;
    return detail;
  }
}

customElements.define('price-detail', PriceDetail);
