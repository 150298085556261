'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';

class SimpleRemove extends LitElement {
  static get styles() {
    return [
      css`
        :host {
          display: block;
          cursor: pointer;
        }

        .pushed {
          color: #f44336;
        }
      `
    ];
  }

  render() {
    return html`
      <span id="remove" @click="${this.fireRemove}">${ALCIcons['close']}</span>
    `;
  }

  static get properties() {
    return {
      pushed: { type: Boolean }
    }
  }

  constructor() {
    super();
    this.pushed = false;
  }

  fireRemove() {
    if(this.pushed === true){
      return this.dispatchEvent(new CustomEvent('remove', {bubbles: true, composed: true}));
    }

    var icon = this.shadowRoot.getElementById('remove');

    icon.classList.add('pushed');
    this.pushed = true;

    var that = this;

    setTimeout(function(){ 
      icon.classList.remove('pushed');
      that.pushed = false;
    }, 2000);
  }
}

customElements.define('simple-remove', SimpleRemove);
