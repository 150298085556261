'use strict';

import { html } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-input/paper-input.js';
import '../../shared-elements/button-group.js';
import './simple-remove.js';

export function renderDefaultHardware(self, part, index, title, addOn) {
  return html`
    <div>
      <div class="paper-card">
        <div class="header">
          <div class="card-header">${title}</div>
          <simple-remove
            index="${index}"
            hardware="${part}"
            @remove="${self.fireRemove}"
          ></simple-remove>
        </div>
        <div
          class="content"
          .index="${index}"
          .hardware="${part}"
        >
          <div class="card-subtitle">Référence</div>
          
          ${renderHardwareInput(self, self.hardware[part][index], 'brand', 'Marque')}
          ${renderHardwareInput(self, self.hardware[part][index], 'model', 'Modèle')}
          ${renderHardwareInput(self, self.hardware[part][index], 'serialNumber', 'Numéro de série')}

          ${addOn}
        </div>
      </div>
    </div>
  `;
}

export function renderHardwareInput(self, item, prop, label, options = {}) {
 return html`
    <paper-input
      label="${label}"
      required
      auto-validate
      type="${options.type || 'text'}"
      ?required="${options.required}"
      .prop="${prop}"
      .value="${item[prop]}"
      .subProperty="${options.subProperty ? options.subProperty : ''}"
      @value-changed="${self._updateInputValue}"
    >
      ${options.suffix ? html`
        <div slot="suffix">${options.suffix}</div>
      ` : ''}
    </paper-input>
  `
}

export function renderHydrolicModule(self, index) {
  return renderDefaultHardware(self, 'hydrolicModules', index, 'Module hydrolique', html`
    <div id="temp">
      <div>Température</div>
      ${renderHardwareInput(self, self.hardware.hydrolicModules[index], 'tempStart', 'Départ', {
        required: true, type: 'number', suffix: '°C'
      })}
      ${renderHardwareInput(self, self.hardware.hydrolicModules[index], 'tempReturn', 'Retour', {
        required: true, type: 'number', suffix: '°C'
      })}
    </div>
  `)
}

export function renderTank(self, index) {
  return renderDefaultHardware(self, 'tanks', index, 'Cuve', html`
    <div id="temp" slot="add-on">
      <div>Température ECS</div>
      ${renderHardwareInput(self, self.hardware.tanks[index], 'ecsTemp', 'Sortie', {
        required: true, type: 'number', suffix: '°C'
      })}
    </div>
  `)
}

export function renderIndoorUnit(self, index) {
  return renderDefaultHardware(self, 'indUnits', index, 'Unité intérieure', html`
    <div id="temp">
      <div>Température</div>
      ${renderHardwareInput(self, self.hardware.indUnits[index], 'outTemp', 'Sortie', {
        required: true, type: 'number', suffix: '°C'
      })}
    </div>
  `)
}

export function renderOutGroup(self, index) {
  return renderDefaultHardware(self, 'outGroups', index, 'Groupe Extérieur', html`
    <div class="card-subtitle">Tension entre les bornes</div>

    <button-group
      .options="${[
        { key: 'monophase', name: 'Monophase' },
        { key: 'triphase', name: 'Triphase' }
      ]}"
      prop="type"
      .selected="${self.hardware.outGroups[index].type}"
      @selected-changed="${self._updateInputValue}"
    ></button-group>

    <div id="out-group-measures">
      ${self.hardware.outGroups[index].type === 'monophase' ? html`
        ${renderHardwareInput(self, self.hardware.outGroups[index].measures, 'ln', 'L ↔︎ N', {
          required: true, type: 'number', suffix: 'volts', subProperty: 'measures'
        })}
      ` : html`
        ${renderHardwareInput(self, self.hardware.outGroups[index].measures, 'l1l2', 'L1 ↔︎ L2', {
          required: true, type: 'number', suffix: 'volts', subProperty: 'measures'
        })}
        ${renderHardwareInput(self, self.hardware.outGroups[index].measures, 'l1l3', 'L1 ↔︎ L3', {
          required: true, type: 'number', suffix: 'volts', subProperty: 'measures'
        })}
        ${renderHardwareInput(self, self.hardware.outGroups[index].measures, 'l2l3', 'L2 ↔︎ L3', {
          required: true, type: 'number', suffix: 'volts', subProperty: 'measures'
        })}
        ${renderHardwareInput(self, self.hardware.outGroups[index].measures, 'l1n', 'L1 ↔︎ N', {
          required: true, type: 'number', suffix: 'volts', subProperty: 'measures'
        })}
        ${renderHardwareInput(self, self.hardware.outGroups[index].measures, 'l2n', 'L2 ↔︎ N', {
          required: true, type: 'number', suffix: 'volts', subProperty: 'measures'
        })}
        ${renderHardwareInput(self, self.hardware.outGroups[index].measures,'l3n', 'L3 ↔︎ N', {
          required: true, type: 'number', suffix: 'volts', subProperty: 'measures'
        })}
      `}
    </div>

    <div class="card-subtitle">Pression des gaz</div>
    
    <div id="gaz">
      ${renderHardwareInput(self, self.hardware.outGroups[index], 'hotModeGaz', 'Mode chaud', {
        required: true, type: 'number', suffix: 'bars'
      })}

      ${renderHardwareInput(self, self.hardware.outGroups[index], 'coldModeGaz', 'Mode froid (optionnel)', {
        type: 'number', suffix: 'bars'
      })}
    </div>
  `)
}

