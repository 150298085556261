'use strict';

export async function remove(id) {
  if (!id) throw 'Champs manquants';

  let res = await fetch(`${window.API_HOST}interventions/${id}`, {
    method: 'DELETE',
    headers: new Headers(window.API_HEADERS)
  });

  if (res.ok) return;

  let err = await res.json();
  throw err.message;
}

export async function create(intervention) {
  if (!intervention._tech || !intervention._client || !intervention.date)
    throw 'Champs manquants';

  let res = await fetch(`${window.API_HOST}interventions`, {
    method: 'POST',
    headers: new Headers(window.API_HEADERS),
    body: JSON.stringify(intervention)
  });

  let json = await res.json();

  if (res.ok) return json;
  throw json.message;
}

async function offlineFetch(url, options) {
  localStorage.setItem(
    'OFFLINE_REQUESTS', JSON.stringify({
        ...JSON.parse(localStorage.getItem('OFFLINE_REQUESTS')),
        [url]: { url, options, headers: window.API_HEADERS }
      }
    )
  );
}

export async function update(id, intervention, retry = false) {
  if (!id || !intervention) throw 'Champs manquants';

  const url = `${window.API_HOST}interventions/${id}`;
  const options = {
    method: 'PUT',
    headers: new Headers(window.API_HEADERS),
    body: JSON.stringify(intervention)
  };

  if(retry === false) {
    let res = await fetch(url, options);
    let json = await res.json();

    if (res.ok) return json;
    throw json.message;
  }

  if(navigator.onLine === false) {
    return offlineFetch(url, options);
  }

  try {
    let res = await fetch(url, options);

    if(res.status >= 200 && res.status < 500) {
      let json = await res.json();
      if (res.ok) return json;
    } else {
      return offlineFetch(url, options);
    }
  } catch(e) {
    return offlineFetch(url, options);
  }
}

export async function get(id, { from, to, tech, sales, client } = {}) {
  if ((!from || !to) && !tech && !sales && !client && !id) return;

  let url = new URL(`${window.API_HOST}interventions`);
  if (id) url += `/${id}`;

  let params = { from, to, tech, sales, client };
  Object.keys(params)
    .filter(key => !!params[key])
    .forEach(key => url.searchParams.append(key, params[key]));

  let res = await fetch(url, { headers: new Headers(window.API_HEADERS) });
  let json = await res.json();

  if (res.ok) return json;
  throw json.message;
}
