'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-input/paper-input.js';
import '../../../node_modules/@polymer/paper-ripple/paper-ripple.js';
import '../elements/clients-table.js';
import '../../shared-elements/client-detail.js';
import { SharedStyles, FabStyles } from '../../shared-styles.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';
import * as Client from '../../api/api-clients.js';
import { webSocket } from '../../utils/utils.js';

class ClientsView extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      FabStyles,
      css`
        :host {
          display: block;
          width: 100%;
          margin: auto;
          overflow-y: hidden;
          height: 100% !important;
        }
        
        #container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          box-sizing: border-box;
          height: 100%
        }

        #left {
          flex-basis: 70%;
          margin-right: 10px;
          display: grid;
          grid-template-rows: 62px auto;
        }

        #right {
          flex-basis: 30%;
          border: 1px solid #ccc;
          border-radius: 5px;
          background-color: white;
          padding: 0;
          box-sizing: border-box;
          height: 100%;
          overflow-y: auto;
        }

        @media only screen 
        and (max-device-width : 600px) {
          #grid, client-details {
            display: none;
          }

          #left {
            flex-basis: 100%;
            margin-right: 0;
          }

          #right {
            flex-basis: 0;
            border: 0;
          }
        }

        clients-table {
          background-color: white;
          position: relative;
        }
      `
    ]
  }

  render() {
    return html`
      <div id="container">
        <div id="left">
          <paper-input
            id="search"
            label="Recherche (nom, adresse, commercial)"
            .value="${this.search}"
            @value-changed="${this._searchChanged}"
          ></paper-input>

          <clients-table
            .clients="${this.filtered}"
            .sales="${this.sales}"
            @client-selected="${this._clientSelected}"
          ></clients-table>

        </div>

        <client-details
          id="right"
          .client="${this.client}"
          .techs="${this.techs}"
          .sales="${this.sales}"
        ></client-details>

        <button class="fab" @click="${this._newClient}" aria-label="new">
          ${ALCIcons['add']}
          <paper-ripple></paper-ripple>
        </button>
      </div>
    `;
  }

  static get properties() {
    return {
      users: { type: Array },
      techs: { type: Array },
      sales: { type: Array },
      admins: { type: Array },
      clientId: { type: String },
      client: { type: Object },
      filtered: { type: Array },
      clients: { type: Array },
      search: { type: String }
    }
  }

  constructor() {
    super();
    this.users = [];
    this.admins = [];
    this.sales = [];
    this.techs = [];
    this.filtered = [];
    this.clients = [];
    this.rendered = [];
    this.search = '';
    webSocket({ url: window.API_WEB_SOCKET, onMessage: this._socketMessage.bind(this) });
  }

  connectedCallback() {
    super.connectedCallback();
    this._getClients();
  }

  updated(props) {
    if(props.has('techs'))
      this._techsRefreshed();
    if(props.has('clients'))
      this._clientsUpdated();
    if(props.has('search'))
      this._filter();
  }

  _getClients() {
    Client.get(null, true)
    .then(clients => { this.clients = clients });
  }

  _clientSelected(e) {
    for(let client of this.clients)
      if(client._id === e.detail)
        this.client = client;

    if(this.client && window.outerWidth <= 600)
      this.dispatchEvent(new CustomEvent('show-client-details', {bubbles: true, composed: true, 
        detail: {client: this.client}
      }));
  }

  _newClient() {
    this.dispatchEvent(new CustomEvent('show-client-edit', {bubbles: true, composed: true}));
  }

  _modifyClient(e) {
    if(!this.clients) return;

    this.dispatchEvent(new CustomEvent('show-client-edit', {bubbles: true, composed: true, 
      detail: {client: this.client, clientId: e.target.getAttribute('clientId')}
    }));
  }

  _clientsUpdated()  {
    if(!this.clients) return;

    this._filter();
    if(this.client && this.client._id)
      this.client = this.clients.find(c => c._id === this.client._id) || {};
  }

  _filter() {
    if(!this.clients) return;
    const clients = this.clients;

    for (var i = 0; i < clients.length; i++)
      if(!clients[i]._sales || !clients[i]._sales.name) 
        clients[i]._sales = {name: ''};

    if(!this.search) return this.filtered = clients;
    const filter = this.search.toLowerCase();

    this.filtered = clients.filter(c => 
      (c.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(filter) ||
      (c._sales && c._sales.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(filter)) ||
      c.address.city.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(filter) ||
      c.address.postalCode.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(filter))
    )
  }

  _techsRefreshed() {
    if(!this.tech && this.techs && this.techs.length)
      this.tech = this.techs[0]._id;
  }

  _techSelected(id) {
    if(!this.techs || !id) return;

    for (var i = 0; i < this.techs.length; i++)
      if(this.techs[i]._id === id)
        this.currentTech = this.techs[i];
  }

  _socketMessage({ data }) {
    if(data === 'refresh-interventions')
      this._getClients();
    if(data === 'refresh-clients')
      this._getClients();
  }

  _searchChanged(e) { this.search = e.detail.value }
}

customElements.define('clients-view', ClientsView);
