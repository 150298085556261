'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-input/paper-input.js';
import { SharedStyles } from '../../shared-styles.js';

class SavPrice extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        .card-subtitle, .item-detail, #total {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .card-subtitle span {
          font-size: 1.3rem;
          font-weight: 500;
          margin-bottom: 0;
          margin-top: 3px;
        }

        span {
          font-size: 1.2rem;
          font-weight: 300;
        }

        #total > span {
          font-size: 1.6rem;
          font-weight: 500;
        }

        #total {
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid #aaa;
        }

        .price {
          text-align: right;
        }

        .item-detail .detail-name {
          padding-left: 30px;
        }
      `
    ];
  }

  render() {
    return html` 
      <div class="paper-card">
        <div class="card-header">Prix de l'intervention</div>
        <div class="content">
          <paper-input
            label="Prix HT"
            type="number"
            ?disabled="${this.disabled}"
            .value="${this.price}"
            @value-changed="${this._priceChanged}"
          >
            <span suffix slot="suffix">€ HT</span>
          </paper-input>

          <div class="card-subtitle">
            <span>TVA</span>
            <span class="price">${this.getVATRate(this.clientType)} %</span>
          </div>

          <div id="total">
            <span>Total</span>
            <span class="price">${this.getVATIncluded(this.price, this.clientType)}€ TTC</span>
          </div>
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      clientType: { type: String },
      disabled: { type: Boolean },
      price: { type: Number }
    }
  }

  constructor() {
    super();
  }

  getVATRate(type) {
    switch(type){
      case 'individual':
        return 10;
      case 'company':
        return 20;
      case 'sci':
        return 20;
    }
  }

  getVATIncluded(price, type) {
    var total = price * (1 + (this.getVATRate(type) / 100));
    return Math.round(total * 100) / 100;
  }

  _priceChanged(e) {
    this.price = e.detail.value;
    this.dispatchEvent(new CustomEvent('price-changed', {
      bubbles: true, composed: true, detail: this.price
    }))
  }
}

customElements.define('sav-price', SavPrice);
