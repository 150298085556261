'use strict';

export function isValid(client) {
  return (client &&
    client.name &&
    client.phone &&
    client.address &&
    client.address.street &&
    client.address.city &&
    client.address.postalCode &&
    client.address.lat &&
    client.address.long);
}

export async function create(client) {
  if(!isValid(client))
    throw 'Champs manquants';

  client._sales = (client._sales && client._sales._id) ? client._sales._id : client._sales;

  let res = await fetch(`${window.API_HOST}clients`, {
    method: 'POST',
    headers: new Headers(window.API_HEADERS),
    body: JSON.stringify(client)
  });

  let json = await res.json();

  if(res.ok) return json;
  throw json.message;
}

export async function update(id, client) {
  if(!isValid(client))
    throw 'Champs manquants';

  let res = await fetch(`${window.API_HOST}clients/${id}`, {
    method: 'PUT',
    headers: new Headers(window.API_HEADERS),
    body: JSON.stringify(client)
  });

  let json = await res.json();

  if(res.ok) return json;
  throw json.message;
}

export async function get(id, getAppointments) {
  let url = new URL(`${window.API_HOST}clients`);
  if(id) url += `/${id}`;
  if(getAppointments) url.searchParams.append('appointments', true);

  let res = await fetch(url, { headers: new Headers(window.API_HEADERS) });
  let json = await res.json();

  if(res.ok) return json;
  throw json.message;
}

export async function remove(id) {
  if(!id)
    throw 'Champs manquants';

  let res = await fetch(`${window.API_HOST}clients/${id}`, {
    method: 'DELETE',
    headers: new Headers(window.API_HEADERS),
  })

  if(res.ok) return;

  let err = await res.json();

  if(err.message === 'A client cannot be deleted if it has any intervention')
    throw 'Un client ne peut pas être supprimé si il a des interventions'

  throw err.message;
}