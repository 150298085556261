'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import { formatTime, interventionTypeName } from '../utils/utils.js';
import { ALCIcons } from './alc-icons.js';
import { SharedStyles } from '../shared-styles.js';

class AppointmentCard extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        :host {
          display: block;
        }

        svg {
          width: 2rem;
          height: 2rem;
          color: #666;
          vertical-align: top;
          padding-right: 1rem;
        }

        .appointment-header {
          grid-template-columns: auto 1fr !important;
        }
        
        .part-content {
          display: inline-block;
          width: 80%;
        }

        .tech-time {
          color: white;
          font-weight: bold;
          padding: 20px;
          display: flex;
          flex-direction: row;
        }

        .times {
          font-size: 20px;
          font-weight: 500;
        }

        .time {
          font-weight: 900;
        }

        #started {
          background-color: #e68119;
        }

        #started img {
          animation: spin 4s linear infinite;
          animation-delay: 2s;
        }

        #finished {
          background-color: #579A22;
          padding: 10px;
        }

        #offline {
          background-color: #e27d00;
          padding: 1rem 1.5rem;
        }

        /* Smartphones (portrait and landscape) ----------- */
        @media only screen 
        and (min-device-width : 320px) 
        and (max-device-width : 1000px) {
          .part {
            flex-basis: 50%;
            padding-bottom: 1rem;
            font-size: 1.1rem;
          }
        }

        /* Smartphones (portrait and landscape) ----------- */
        @media only screen 
        and (min-device-width : 1000px) {
          .part {
            flex-basis: 33%;
            padding-bottom: 3rem;
            font-size: 1.2rem;
          }
        }

        @keyframes spin { 
          75% {
            transform: rotate(0deg); 
          }
          100% { 
            transform: rotate(-180deg); 
          } 
        }

        #time {
          color: #666;
          font-size: 1.5rem;
          font-weight: 500;
          padding-right: 1rem;
        }

        #started > svg, #finished > svg {
          color: white;
          margin-left: 15px;
        }
      `
    ];
  }
  
  render() {
    return html`    
      <div class="paper-card">
        <div class="header appointment-header">
          <span id="time">${this._computeTime(this.date)}</span>
          <div class="card-header">${this.name}</div>
        </div>

        ${(this.started && !this.finished) ? html`
          <div id="started" class="tech-time">
            ${ALCIcons['update']}
            <div class="times">
              Intervention en cours<br>
              Le technicien est arrivé à <span class="time">${this.started}</span><br>
            </div>
          </div>
        ` : ''}

        ${this.finished ? html`
          <div id="finished" class="tech-time">
            ${ALCIcons['done']}
            <div class="times">
              Arrivé à <span class="time">${formatTime(this.started)}</span><br>
              Terminé à <span class="time">${formatTime(this.finished)}</span>
            </div>
          </div>
        ` : ''}

        ${this.offline ? html`
          <div id="offline" class="tech-time">
            ${ALCIcons['offline']}
            <div class="times">
              Cet appareil n'est pas connecté à internet.<br>
              L'intervention sera envoyée dès qu'une connection sera disponible
            </div>
          </div>
        ` : ''}

        <div class="content">
          <div class="part">
            ${ALCIcons['location-on']}
            <div class="part-content">
              ${this.address.street}<br>${this.address.postalCode} ${this.address.city} 
            </div>
          </div>

          <div class="part">
            ${ALCIcons['build']}
            <div class="part-content">
              ${interventionTypeName(this.type)}
            </div>
          </div>

          <div class="part">
            ${ALCIcons['phone']}
            <div class="part-content">
              ${this.phone}
            </div>
          </div>

          ${this.notes ? html`
            <div class="part">
              ${ALCIcons['speaker-notes']}
              <div class="part-content">
                ${this.notes}
              </div>
            </div>
          ` : ''}

        </div>

        <slot></slot>

      </div>
    `;
  }

  static get properties() {
    return {
      type: { type: String },
      name: { type: String },
      date: { type: Date },
      address: { type: Object },
      notes: { type: String },
      phone: { type: String },
      started: { type: String },
      finished: { type: String },
      price: { type: Number },
      finished: { type: Date },
      offline: { type: Boolean }
    }
  }

  constructor() {
    super();
    this.address = {};
  }

  _computeTime(date) {
    if(!date) return;

    date = new Date(date);

    var hours = date.getHours().toString().length === 1 ? ('0' + date.getHours()) : date.getHours();
    var minutes = date.getMinutes().toString().length === 1 ? ('0' + date.getMinutes()) : date.getMinutes();

    return hours + ':' + minutes;
  }
}

customElements.define('appointment-card', AppointmentCard);
