'use strict';

import { LitElement, html, css } from '../../../node_modules/lit-element/lit-element.js';
import '../../../node_modules/@polymer/paper-input/paper-textarea.js';
import '../../../node_modules/@polymer/paper-button/paper-button.js';
import '../../../node_modules/@polymer/paper-ripple/paper-ripple.js';
import { SharedStyles } from '../../shared-styles.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';

class TechnicianObservations extends LitElement {
  static get styles() {
    return [
      SharedStyles,
      css`
        paper-textarea {
          --paper-input-container-label-floating: {
            font-size: 26px;
          }
        }

        .remove {
          width: 100%;
          padding: 0;
          margin: 0;
          background-color: #b71c1c;
          color: white;
          height: 40px;
          border-radius: 0;
          flex-grow: 1;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 160px;
          flex-grow: 4;
        }
        .photo {
          display: flex;
          flex-direction: column;
          padding: 0;
          height: 200px;
        }

        .add {
          background-color: white;
          color: #bbb;
          width: 100%;
          height: 100%;
          padding: 0;
          height: 200px;
        }

        .big {
          height: 64px;
          width: 64px;
        }

        .paper-card {
          margin-bottom: 25px;
        }

        iron-icon {
          position: relative;
          z-index: 50;
          margin: auto;
          width: 100%;
        }

        #icon-container {
          position: relative;
          z-index: 50;
          width: 100%;
          height: 100%;
          margin: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          text-align: center;
        }

        #animated {    
          max-height: 100%;
            max-width: 100%;
        }

        #preview {
          margin: 20px 0;
        }

        #grid {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
        }

        .photo, #camera {
          width: 200px;
          margin-right: 1rem;
        }

        /* Smartphones (portrait and landscape) ----------- */
        @media only screen 
        and (min-device-width : 320px) 
        and (max-device-width : 1000px) {
          .photo, #camera {
            width: 100%;
            margin-right: 0;
          }
        }

        #photo {
          height: 100%;
          width: 100%;
          position: relative;
          display: none
        }

        label {
          width: 100%;
          height: 100%;
          display: flex;
        }

        input {
          display: none;
        }



        .remove {
          width: 100%;
          padding: 0;
          margin: 0;
          background-color: #b71c1c;
          color: white;
          height: 40px;
          border-radius: 0;
          flex-grow: 1;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 160px;
          flex-grow: 4;
        }
        .photo {
          display: flex;
          flex-direction: column;
          padding: 0;
          height: 200px;
        }

        .add {
          background-color: white;
          color: #bbb;
          width: 100%;
          height: 100%;
          padding: 0;
          height: 200px;
        }

        .paper-card {
          margin-bottom: 25px;
        }

        iron-icon {
          position: relative;
          z-index: 50;
          margin: auto;
          width: 100%;
        }

        #icon-container {
          position: relative;
          z-index: 50;
          width: 100%;
          height: 100%;
          margin: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          text-align: center;
        }

        #animated {    
          max-height: 100%;
            max-width: 100%;
        }

        #preview {
          margin: 20px 0;
        }

        #grid {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
        }

        .photo, #camera {
          width: 200px;
          margin-right: 1rem;
        }

        /* Smartphones (portrait and landscape) ----------- */
        @media only screen 
        and (min-device-width : 320px) 
        and (max-device-width : 1000px) {
          .photo, #camera {
            width: 100%;
            margin-right: 0;
          }
        }

        #photo {
          height: 100%;
          width: 100%;
          position: relative;
          display: none
        }

        label {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        input {
          display: none;
        }

        #photo-icon svg {
          transform: scale(2);
        }
      `
    ]
  }

  render() {
    return html`
      <div class="paper-card">
        <div class="card-header">Observations du tehnicien</div>
        <div class="content">
          <div id="photos">
            <div>Photos</div><br>

            <div id="grid">
              <div id="photos">
                ${(this.photos || []).map((item, index) => html`
                  <div class="paper-card photo">
                    <img src="${item}" height="100px" preload @click="${this.preview}"></img>
                    <paper-button
                      class="remove"
                      .index="${index}"
                      @click="${this.removePhoto}"
                    >
                      ${ALCIcons['delete']}
                    </paper-button>
                  </div>
                `)}
              </div>

              <div id="camera" class="add paper-card">
                <div id="icon-container">
                  <paper-ripple></paper-ripple>
                  <label for="photo" id="photo-icon">
                    ${ALCIcons['camera-alt']}
                  </label>
                  <input id="photo" type="file" accept="image/*" @change="${this.toBase64}" capture>
                </div>
              </div>
            </div>


          </div>

          ${this.showNotes ? html`
            <paper-textarea
              id="notes"
              label="Notes"
              autosave
              rows="2"
              .value="${this.notes}"
              @value-changed="${this._notesChanged}"
            ></paper-textarea>
          ` : ''}
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      showNotes: { type: Boolean },
      notes: { type: String },
      photos: { type: Array }
    }
  }

  constructor() {
    super();
    this.photos = [];
  }

  connectedCallback() {
    super.connectedCallback();

    this.img = document.createElement('img');
    this.canvas = document.createElement('canvas');
    this.reader = new FileReader();
  }

  updated(props) {
    if(props.has('notes'))
      this.dispatchEvent(new CustomEvent('notes-changed', {
        bubbles: true, composed: true, detail: this.notes
      }));
  }

  extractOrientation(imgBlob) {
    // https://gist.github.com/wonnage/9d2e73d9228f5a0b300d75babe2c3796
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader);
      fileReader.readAsArrayBuffer(imgBlob);
    })
    .then((reader) => {
      const dataView = new DataView(reader.result);
      let cursor = 0;

      if (dataView.getUint16(cursor) !== 0xFFD8)
        throw new Error('not a valid jpeg');

      cursor += 2;

      while (cursor < dataView.byteLength - 1) {
        if (dataView.getUint16(cursor) === 0xFFE1) {
          cursor += 2;
          const exifSize = dataView.getUint16(cursor) - 2;

          cursor += 2;
          return new DataView(reader.result, cursor, exifSize);
        }
        cursor += 2;
      }
      throw new Error('could not find exif start tag');
    })
    .then((exifView) => {
      let cursor = 0;
      if (exifView.getUint32(cursor) !== 0x45786966) {
        throw new Error('could not find Exif header');
      }
      cursor += 4;
      const littleEndian = exifView.getUint16(cursor) === 0x4949;

      cursor += 8;
      while (cursor < exifView.byteLength - 1) {
        if (exifView.getUint16(cursor, littleEndian) === 0x0112) {
          cursor += 8;
          const orientation = exifView.getUint16(cursor, littleEndian);
          const rotate = orientation || 0;
          return rotate;
        }
        cursor += 2;
      }

      throw new Error('no orientation defined');
    })
    .catch(() => 0);
  }

  toBase64(e) {
    // https://stackoverflow.com/a/31194124
    // https://stackoverflow.com/a/27165977
    
    if (!e.target.files.length){
      return;
    }

    this.img.src = URL.createObjectURL(e.target.files[0]);

    this.img.onload = function(file) {
      var MAX_WIDTH = 1280;
      var MAX_HEIGHT = 720;
      var width = this.img.width;
      var height = this.img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      this.extractOrientation(file)
      .then(orientation => {
        // https://github.com/koba04/canvas-exif-orientation/blob/master/index.js

        var ctx = this.canvas.getContext('2d');
        this.canvas.width = width;
        this.canvas.height = height;

        ctx.save();
        
        switch (orientation) {
          case 1:
            break;

          case 2:
           ctx.translate(width, 0);
           ctx.scale(-1, 1);
           break;

          case 3:
            ctx.translate(width, height);
            ctx.rotate(Math.PI);
            break;

          case 4:
            ctx.translate(0, height);
            ctx.scale(1, -1);
            break;

          case 5:
            this.canvas.width = height;
            this.canvas.height = width;
            ctx.rotate(0.5 * Math.PI);
            ctx.scale(1, -1);
            break;

          case 6:
            this.canvas.width = height;
            this.canvas.height = width;
            ctx.rotate(0.5 * Math.PI);
            ctx.translate(0, -height);
            break;

          case 7:
            this.canvas.width = height;
            this.canvas.height = width;
            ctx.rotate(0.5 * Math.PI);
            ctx.translate(width, -height);
            ctx.scale(-1, 1);
            break;

          case 8:
            this.canvas.width = height;
            this.canvas.height = width;
            ctx.rotate(-0.5 * Math.PI);
            ctx.translate(-width, 0);
            break;
        }

        ctx.drawImage(this.img, 0, 0, width, height);
        ctx.restore();

        this.shadowRoot.getElementById('photo').value = '';
        this.photos = [...this.photos, this.canvas.toDataURL('image/jpeg', 0.5)];
        this.dispatchEvent(new CustomEvent('photos-changed', {
          bubbles: true, composed: true, detail: this.photos
        }));
      })
    }.bind(this, e.target.files[0])
  }

  removePhoto(e) {
    this.photos = this.photos.filter((i, idx) => e.currentTarget.index !== idx);
    this.dispatchEvent(new CustomEvent('photos-changed', {
      bubbles: true, composed: true, detail: this.photos
    }));
  }

  preview(e) {
    this.dispatchEvent(new CustomEvent('show-photo', {bubbles: true, composed: true, 
      detail: e.target.src
    }));
  }

  _notesChanged(e) { this.notes = e.detail.value }
}

customElements.define('technician-observations', TechnicianObservations);
