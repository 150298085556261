'use strict';

import { LitElement, html } from '../../../node_modules/lit-element/lit-element.js';
import { ALCIcons } from '../../shared-elements/alc-icons.js';

class GradeStars extends LitElement {
  render() {
    return html`
      ${this._full.map(() => ALCIcons['star'])}
      ${this._half.map(() => ALCIcons['star-half'])}
      ${this._empty.map(() => ALCIcons['star-border'])}
    `;
  }

  static get properties() {
    return {
      grade: { type: Number },
      _full: { type: Array },
      _half: { type: Array },
      _empty: { type: Array }
    }
  }

  constructor() {
    super();
    this._full = [];
    this._half = [];
    this._empty = [];
  }

  updated(props) {
    if(props.has('grade')) this._getStars();
  }

  _getStars() {
    const grade = parseInt(this.grade);
    if(isNaN(grade)) return;
    
    var full = 0;
    var half = 0;
    var empty = 0;

    full = parseInt(grade) + 1;

    if(grade % 1 >= 0.25 && grade % 1 < 0.75) half = 1;
    if(grade % 1 >= 0.75) full = full + 1;

    empty = 3 - full - half;

    this._full = [...new Array(full)].map(() => 1);
    this._half = [...new Array(half)].map(() => 1);
    this._empty = [...new Array(empty)].map(() => 1);
  }
}

customElements.define('grade-stars', GradeStars);
