'use strict';

import { LitElement, html, css } from '../../node_modules/lit-element/lit-element.js';
import '../../node_modules/@polymer/paper-input/paper-input.js';

class GooglePlaceAutocomplete extends LitElement {

  static get styles() {
    return css`
      :host {
        display: inline-block;
      }

      input {
        position: relative;
        /* to make a stacking context */
        outline: none;
        box-shadow: none;
        margin: 0;
        padding: 0;
        width: 100%;
        max-width: 100%;
        background: transparent;
        border: none;
        -webkit-appearance: none;
        text-align: inherit;
        vertical-align: bottom;

        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
      }

      label {
        pointer-events: none;
      }
    `
  }

  render() {
    return html`

    <paper-input-container id="container" ?invalid="${this.invalid}">

      <label ?hidden="${!this.label}" aria-hidden="true" slot="label">${this.label}</label>

      <iron-input
      .bind-value="${this._value}"
      slot="input"
      class="input-element"
      id="locationsearch">
        <input
          id="nativeInput"
          inputmode="text"
          .placeholder="${this.placeholder}"
          @change="${this._onChange}"
          .value="${this._value}"
          class="input-element"
        >
      </iron-input>

      ${this.errorMessage ? html`
        <paper-input-error aria-live="assertive" slot="add-on">${this.errorMessage}</paper-input-error>
      ` : ''}

    </paper-input-container>`;
  }

  static get properties() {
    return {
      hideError: { type: Boolean },
      hideIcon: { type: Boolean },
      disabled: { type: Boolean },
      searchCountryCode: { type: String },
      searchType: { type: String },
      errorMessage: { type: String },
      invalid: { type: Boolean },
      _invalid: { type: Boolean },
      label: { type: String },
      placeholder: { type: String },
      latLng: { type: Object },
      place: { type: Object },
      _places: { type: Object },
      required: { type: Boolean },
      language: { type: String },
      value: { type: Object },
      _value: { type: String }
    };
  }

  constructor() {
    super();
    this.hideError = false
    this.hideIcon = false
    this.disabled = false
    this.searchCountryCode = ''
    this.searchType = ''
    this.errorMessage = 'Invalid - please select a place';
    this.invalid = false
    this._invalid = false
    this.label = ''
    this.placeholder = ''
    this.latLng = { lat: 0, lng: 0 },
    this.required = false,
    this.language = '';
  }

  updated(props) {
    if(props.has('searchCountryCode') || props.has('searchType'))
      this._searchBiasChanged();
    
    // todo old new
    if(props.has('value'))
      this._valueChanged();

    if(props.has('_value'))
      this._svalChanged();

    if(props.has('place')) 
      this._notifyPlaceChange();
  }

  connectedCallback() {
    super.connectedCallback();
    this._init();
  }

  _init() {
    if(typeof window.google === 'undefined' || !this.shadowRoot || !this.shadowRoot.getElementById('locationsearch') 
      || !this.shadowRoot.getElementById('nativeInput'))
      return setTimeout(() => this._init(), 100);

    if (!this._places && this.shadowRoot.getElementById('locationsearch') 
      && this.shadowRoot.getElementById('nativeInput')) {
      this._places = new google.maps.places.Autocomplete(this.shadowRoot.getElementById('nativeInput'), {});
      google.maps.event.addListener(this._places, 'place_changed', this._onChangePlace.bind(this));
      this._searchBiasChanged();
    }
  }

  _notifyPlaceChange() {
    this.dispatchEvent(new CustomEvent('place-changed', { bubbles: true, composed: true, detail: this.place }));
  }

  _searchBiasChanged() {
    if (this.apiLoaded) {
      this._places.setBounds();

      if (this.searchCountryCode && this.searchCountryCode.length == 2) {
        this._places.setComponentRestrictions({
          country: this.searchCountryCode.toString()
        });
      } else {
        this._places.setComponentRestrictions();
      }
      if (this.searchType && ['address', 'geocode', 'establishment', '(regions)', '(cities)'].includes(this.searchType)) {
        this._places.setTypes([this.searchType.toString()]);
      } else {
        this._places.setTypes([]);
      }
    }
  }

  _valueChanged() {
    // update the search term and the invalid flag if the value is being set for the first time,
    // or if the value has changed and is not the same as the search term
    this._value = this.value && this.value.search ? this.value.search : '';
    this._invalid = !this.value || !(this.value.place_id && this.value.latLng && this.value.latLng.lat && this.value.latLng.lng);
    if (!this.hideError) {
      this.invalid = (this.required ? this._invalid : this._invalid && (this.value && this.value.search));
    }

    this.dispatchEvent(new CustomEvent('value-changed', { bubbles: true, composed: true, detail: this.value }));
  }

  _svalChanged() {
    // reset the invalid property if the user has typed in the input field

    // if the newValue matches the selected place, which could happen if
    // the user types after selecting a place, then deletes the typing
    if (this._value && this.place && this.place.search && this._value == this.place.search) {
      this.value = {
        place_id: this.place.place_id,
        search: this._value,
        latLng: {
          lat: this.place.latLng.lat,
          lng: this.place.latLng.lng
        }
      };
      this._invalid = false;
      this.invalid = false;
      return;
    }
    // if blank and not a required input
    if (!this._value && !this.required) {
      this.value = {
        place_id: '',
        search: '',
        latLng: {
          lat: 0,
          lng: 0
        }
      };
      this.place = {};
      this._invalid = true;
      if (!this.hideError) {
        this.invalid = false;
      }
      return;
    }
    // if the new _value matches the value.search, which could happen if
    // the value is changed externally (possibly through data binding) which
    // causes _value to be changed triggering this function _svalChanged()
    if (this._value && this.value && this.value.search && this._value == this.value.search) {
      // nothing has really changed, just return
      return;
    }
    // if the existing value is blank, and the new value is not
    if ((!this.value || !this.value.search) && this._value) {
      this.value = {
        place_id: '',
        search: this._value,
        latLng: {
          lat: 0,
          lng: 0
        }
      };
      this.place = {};
      this._invalid = true;
      if (!this.hideError) {
        this.invalid = true;
      }
      return;
    }
    // otherwise, the value is invalid
    this.value = {
      place_id: '',
      search: this._value,
      latLng: {
        lat: 0,
        lng: 0
      }
    };
    this.place = {};
    this._invalid = true;
    if (!this.hideError) {
      this.invalid = true;
    }
    return;

  }

  clearLocation() {
    this._value = '';
  }

  _onChangePlace(e) {
    var pl = this._places.getPlace();
    if (pl.geometry) {
      var p = this._extractPlaceInfo(pl, this.shadowRoot.getElementById('nativeInput').value);
      this.place = p;
      this._invalid = false;
      this.invalid = false;
      this.latLng = {
        lat: p.latLng.lat,
        lng: p.latLng.lng
      };
      this._value = this.shadowRoot.getElementById('nativeInput').value;
      this.value = {
        search: this.shadowRoot.getElementById('nativeInput').value,
        place_id: p.place_id,
        latLng: {
          lat: p.latLng.lat,
          lng: p.latLng.lng
        }
      };
    }
  }

  _extractPlaceInfo(pl, searchTerm) {
    var p = {
      place_id: pl.place_id,
      formatted_address: pl.formatted_address,
      search: searchTerm ? searchTerm : pl.formatted_address,
      latLng: {
        lat: pl.geometry.location.lat(),
        lng: pl.geometry.location.lng()
      },
      basic: {
        name: pl.name || '',
        address: '',
        city: '',
        state: '',
        stateCode: '',
        postalCode: '',
        country: '',
        countryCode: '',
        phone: pl.formatted_phone_number || ''
      },
      placeDetails: {
        address_components: [],
        icon: pl.icon,
        international_phone_number: pl.international_phone_number || '',
        permanently_closed: pl.permanently_closed || false,
        types: pl.types ? pl.types : [],
        website: pl.website || '',
        url: pl.url || ''
      }
    };
    // extract address components
    var address = {
      street_number: '',
      route: ''
    };
    for (let component of pl.address_components) {
      p.placeDetails.address_components.push(component);
      switch (component.types[0]) {
        case 'locality':
          p.basic.city = component.long_name;
          break;
        case 'administrative_area_level_1':
          p.basic.stateCode = component.short_name;
          p.basic.state = component.long_name;
          break;
        case 'country':
          p.basic.country = component.long_name;
          p.basic.countryCode = component.short_name;
          break;
        case 'postal_code':
          p.basic.postalCode = component.long_name;
          break;
        case 'street_number':
          address.street_number = component.short_name;
          p.basic.address = address.street_number + ' ' + address.route;
          p.basic.streetNumber = address.street_number;
          break;
        case 'route':
          address.route = component.long_name;
          p.basic.address = address.street_number + ' ' + address.route;
          p.basic.route = address.route;
          break;
        default:
          address[component.types[0]] = component.long_name;
      }
    }

    return p;

  }

  focus() {
    this.shadowRoot.getElementById('nativeInput').focus();
  }

  _onChange(event) {
    // In the Shadow DOM, the `change` event is not leaked into the
    // ancestor tree, so we must do this manually.
    // See https://w3c.github.io/webcomponents/spec/shadow/#events-that-are-not-leaked-into-ancestor-trees.
    if (this.shadowRoot) {
      this.dispatchEvent(new CustomEvent('input-change', {
        bubbles: true,
        cancelable: event.cancelable,
        detail: {
          text: this.shadowRoot.getElementById('nativeInput').value
        }
      }));
    }
  }
}

customElements.define('google-place-autocomplete', GooglePlaceAutocomplete);